import config from 'conf';

export const onFieldChange = (state, setStateFn, event) => {
  const { id, value, checked, type } = event.target;
  if (id.indexOf('.') > -1) {
    // if id has a '.', we have a parent object and child property in the state
    const [parent, child] = id.split('.');
    setStateFn({
      ...state,
      [parent]: {
        ...state[parent],
        [child]: type === 'checkbox' ? checked : value,
      },
    });
  } else {
    setStateFn({
      ...state,
      [id]: type === 'checkbox' ? checked : value,
    });
  }
};

export const onDateChange = (state, setStateFn, id, { date }) => {
  if (date === undefined) return;
  const value = date ? date.format(config.formats.momentDateSystem) : null;
  setStateFn({
    ...state,
    [id]: value,
  });
};
