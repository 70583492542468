import {
  EMPTY_STRING,
  CHECKED_IN,
  CHECKED_OUT,
  TYPE_STAFF,
  TYPE_STUDENT,
  TYPE_ADULT,
  TIMESHEET_LABEL_PAYROLL,
  TIMESHEET_LABEL_ATTENDANCE,
  REPORT_TYPE_PAYROLL,
  REPORT_TYPE_ATTENDANCE,
} from 'constants/index';
import { sortByString } from './common';

export const getMemberOptions = (type, studentMembers, staffMembers) => {
  switch (type) {
    case TYPE_STUDENT: {
      return [
        {
          label: '',
          value: '',
        },
      ].concat(
        studentMembers
          .map((it) => ({
            label: `${it.lastName}, ${it.firstName}`,
            value: it.id,
          }))
          .sort((a, b) => sortByString(a.label, b.label))
      );
    }
    case TYPE_STAFF: {
      return [
        {
          label: '',
          value: '',
        },
      ].concat(
        staffMembers
          .map((it) => ({
            label: `${it.lastName}, ${it.firstName}`,
            value: it.id,
          }))
          .sort((a, b) => sortByString(a.label, b.label))
      );
    }
    default: {
      return [];
    }
  }
};

export const attendanceActionOptions = [
  {
    label: CHECKED_IN,
    value: CHECKED_IN,
  },
  {
    label: CHECKED_OUT,
    value: CHECKED_OUT,
  },
];

export const reportTypeOptions = [
  {
    label: EMPTY_STRING,
    value: EMPTY_STRING,
  },
  {
    label: TIMESHEET_LABEL_ATTENDANCE,
    value: REPORT_TYPE_ATTENDANCE,
  },
  {
    label: TIMESHEET_LABEL_PAYROLL,
    value: REPORT_TYPE_PAYROLL,
  },
];

export const getMemberDetails = (partyId, type, adultList, studentList) => {
  switch (type) {
    case TYPE_STUDENT: {
      return studentList[partyId];
    }
    case TYPE_ADULT:
    case TYPE_STAFF: {
      return adultList[partyId];
    }
    default: {
      return {};
    }
  }
};
