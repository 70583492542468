import { DateTime } from 'luxon';
import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTenantContext } from 'context/Tenant/TenantContext';
import { Button, ActingSubmitButton } from 'components/buttons';
import { InputSelect, InputTime, InputText } from 'components/formElements';
import { CenterSelect } from 'components/formElements/CenterSelect';
import { Form, Error } from 'components/layout';
import { isEmpty, noop, timesheetActionLabel } from 'utils/index';
import { OPTIONS_TS_ACTIONS, TimesheetAction } from 'constants/index';
import config from 'conf/index';

function UpdateTimesheetEntryForm(props) {
  const { doSubmit, isLoading, isError, error, doCancel, data, fullName } = props;
  const { orgHasMultipleCenters } = useTenantContext();
  const [values, setValues] = useState({
    ...data,
    dt: DateTime.fromISO(data.dateTime).setZone(config.timeZone),
  });
  const [isDirty, setIsDirty] = useState(false);

  const { action, actionDetails, manualEntryReason, tenantId, billTo } = values;

  const splitDateAndTime = useMemo(() => {
    const { dt } = values;
    return {
      date: dt.toFormat(config.formats.date),
      time: dt.toFormat(config.formats.timeSystem),
    };
  }, [values]);

  const onSubmit = useCallback(async () => {
    await doSubmit(values);
  }, [doSubmit, values]);

  const onFieldChange = useCallback(
    (e) => {
      const { id, value } = e.target;
      if (values[id] !== value) setIsDirty(true);

      const newValues = {
        ...values,
        [id]: value,
      };

      setValues(newValues);
    },
    [values]
  );

  const onTimeChange = useCallback(
    (e) => {
      const { value } = e.target;
      const [hour, minute] = value.split(':');

      setIsDirty(true);

      const newDt = values.dt.set({
        hour,
        minute,
      });
      const newValues = {
        ...values,
        dt: newDt,
      };
      setValues(newValues);
    },
    [values]
  );

  const reset = useCallback(() => {
    setValues({ ...data });
  }, [data]);

  const submitProps = useMemo(
    () => ({
      btnClassName: 'button',
      actionBtnClassName: 'button button--icon--loading',
      btnText: 'Update Entry',
      actionText: 'Saving...',
      isActing: isLoading,
      isDisabled: !isDirty,
    }),
    [isDirty, isLoading]
  );

  return (
    <Form onSubmit={onSubmit} className="form--edit-entry">
      {isError && <Error error={error} className="form-error-msg" />}
      <fieldset className="fieldset" disabled={isLoading}>
        <InputText id="partyId" labelText="Name" value={fullName} onChange={noop} isDisabled />
        <div className="wrapper--flex">
          <InputText id="date" labelText="Date" value={splitDateAndTime.date} onChange={noop} isDisabled />
          <InputTime id="time" labelText="Time" onChange={onTimeChange} value={splitDateAndTime.time} isRequired />
        </div>
        {/* Allow action to be changed if there are no details */}
        {isEmpty(actionDetails) && (
          <InputSelect
            id="action"
            labelText="Action"
            options={OPTIONS_TS_ACTIONS.filter((it) => it.value !== TimesheetAction.OTHER.value)}
            value={action}
            onChange={onFieldChange}
            isRequired
          />
        )}
        {/* Action cannot be changed if there are details */}
        {!isEmpty(actionDetails) && (
          <InputText id="action" labelText="Action" value={timesheetActionLabel(action)} onChange={noop} isDisabled />
        )}

        {/* Let action details be changed if there are any */}
        {!isEmpty(actionDetails) && (
          <InputText
            id="actionDetails"
            labelText="Action Details"
            value={actionDetails}
            onChange={onFieldChange}
            isRequired
          />
        )}
        <InputText
          id="manualEntryReason"
          labelText="Reason for Manual Edit"
          value={manualEntryReason || ''}
          onChange={onFieldChange}
        />
        {orgHasMultipleCenters && (
          <>
            <CenterSelect
              id="tenantId"
              labelText="At which center was this entry recorded?"
              value={tenantId}
              onChange={onFieldChange}
              isRequired
            />
            <CenterSelect
              id="billTo"
              labelText="To which center should this entry be billed?"
              value={billTo}
              onChange={onFieldChange}
              isRequired
            />
          </>
        )}
      </fieldset>
      <div className="form-actions">
        <Button
          onClick={() => {
            reset();
            doCancel();
          }}
          className="button--text--cancel"
        >
          Cancel
        </Button>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
}

UpdateTimesheetEntryForm.propTypes = {
  doCancel: PropTypes.func.isRequired,
  doSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  fullName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.string,
};

UpdateTimesheetEntryForm.defaultProps = {
  isLoading: false,
  isError: false,
  error: null,
};

export default UpdateTimesheetEntryForm;
