import React, { useRef, useState } from 'react';
import moment from 'moment';
import { isInclusivelyAfterDay } from 'react-dates';
import { ActingSubmitButton } from 'components/buttons';
import { InputSelect, Toggle, AdultSelect, StudentSelect } from 'components/formElements';
import { Form } from 'components/layout';
import { DateRangePickerWrapper } from 'components/dates';
import { reportTypeOptions } from 'utils/index';
import { REPORT_TYPE_PAYROLL, REPORT_TYPE_ATTENDANCE } from 'constants/index';
import { useTimesheetContext } from 'context/Timesheet/TimesheetContext';

function TimesheetSearchForm() {
  const dateRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState('');
  const [partyId, setMemberId] = useState('');
  const [hasSubsidy, setHasSubsidy] = useState(false);
  const { isLoading, doSearch } = useTimesheetContext();

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Get Timesheets',
    actionText: 'Loading...',
    isActing: isLoading,
    isDisabled: false,
  };
  const isMemberDisabled = !type;
  return (
    <Form
      onSubmit={() => doSearch(startDate, endDate, type, partyId, hasSubsidy)}
      className="form--generate-results"
      autoComplete={false}
    >
      {/* {isError && !!error && <Error error={error} className="form-error-msg" />} */}
      <DateRangePickerWrapper
        labelText="Date Range"
        onClose={({ startDate: sd, endDate: ed }) => {
          setStartDate(sd);
          setEndDate(ed);
        }}
        initialStartDate={startDate}
        initialEndDate={endDate}
        isOutsideRange={(day) => isInclusivelyAfterDay(day, moment().add(1, 'day'))}
        minimumNights={0}
        displayFormat="MMM D Y"
        isRequired
        appendToBody={false}
        ref={dateRef}
      />
      <InputSelect
        id="type"
        labelText="Timesheet Type"
        options={reportTypeOptions}
        value={type}
        onChange={(e) => {
          setType(e.target.value);
        }}
        isRequired
      />
      {type === REPORT_TYPE_PAYROLL ? (
        <AdultSelect
          id="person"
          labelText="Name"
          value={partyId}
          onChange={(e) => setMemberId(e.target.value)}
          isDisabled={isMemberDisabled}
          filterStaffMembers
        />
      ) : (
        <StudentSelect
          id="person"
          labelText="Name"
          value={partyId}
          onChange={(e) => setMemberId(e.target.value)}
          isDisabled={isMemberDisabled}
        />
      )}
      <Toggle
        id="isSubsidized"
        labelText="Subsidized"
        isChecked={hasSubsidy}
        isDisabled={type !== REPORT_TYPE_ATTENDANCE}
        onChange={(e) => setHasSubsidy(e.target.checked)}
      />
      <div className="form-actions">
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
}

export default TimesheetSearchForm;
