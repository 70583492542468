import { useState, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useTimesheetMutateContext } from 'context/Timesheet/TimesheetMutateContext';
import { Button, ActingSubmitButton } from 'components/buttons';
import { Modal } from 'components/modal';
import { Error, Form } from 'components/layout';
import { InputText } from 'components/formElements';
import { REPORT_TYPE_ATTENDANCE, StringBoolean } from 'constants/index';

function IgnoreTimesheetEntry(props) {
  const { hide, isShowing, data } = props;
  const { doUpdate, payrollUpdate, attendanceUpdate } = useTimesheetMutateContext();
  const isLoading = payrollUpdate.isLoading || attendanceUpdate.isLoading;
  const isError = payrollUpdate.isError || attendanceUpdate.isError;

  const [reason, updateReason] = useState('');

  const submitProps = useMemo(
    () => ({
      btnClassName: 'button--action--neg',
      actionBtnClassName: 'button--action--neg button--icon--loading',
      btnText: 'Ignore Entry',
      actionText: 'Saving...',
      isActing: isLoading,
      isDisabled: isLoading,
    }),
    [isLoading]
  );

  const doIgnoreTimesheet = useCallback(async (newData, ignoreReason, updateFn) => {
    const {
      studentId,
      adultId,
      isIgnored,
      type,
      isIgnoredReason,
      student,
      adult,
      fullName,
      totalHours,
      m,
      isBlank,
      actionLabel,
      dateFmt,
      timeFmt,
      tags,
      isRoaming,
      ...rest
    } = newData;
    const submitData = {
      ...rest,
      ...(type === REPORT_TYPE_ATTENDANCE ? { studentId } : { adultId }),
      isIgnored: StringBoolean.TRUE.value,
      isIgnoredReason: ignoreReason,
    };
    await updateFn(type, submitData);
  }, []);

  const errorMsg = useMemo(() => {
    const error = payrollUpdate.error || attendanceUpdate.error;
    return error?.errors.reduce((acc, nx) => `${acc}\n${nx.message}`, '');
  }, [attendanceUpdate.error, payrollUpdate.error]);

  if (!isShowing) {
    document.body.classList.remove('has-modal');
  }

  return isShowing
    ? ReactDOM.createPortal(
        <Modal isShowing={isShowing} modalTitle="Ignore Timesheet Entry">
          {isError && <Error error={errorMsg} />}
          <Form
            onSubmit={async () => {
              await doIgnoreTimesheet(data, reason, doUpdate);
              if (!isError) hide();
            }}
          >
            <InputText
              id="reason"
              value={reason}
              labelText="Reason this entry should be ignored"
              isRequired
              onChange={(e) => updateReason(e.target.value)}
            />
            <div className="modal-actions">
              <Button
                className="button--text--cancel"
                onClick={() => {
                  hide();
                }}
              >
                Cancel
              </Button>
              <ActingSubmitButton {...submitProps} btnType="submit">
                Save
              </ActingSubmitButton>
            </div>
          </Form>
        </Modal>,
        document.body
      )
    : null;
}

IgnoreTimesheetEntry.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
};

export default IgnoreTimesheetEntry;
