import { createContext, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { createStudent, updateStudent } from 'graphql/customMutations';
import { cacheKeys } from 'conf';
import { StringBoolean } from 'constants/index';
import { useAuthContext } from '../AuthContext';

const initialState = {
  doCreate: () => {},
  doUpdate: () => {},
  doArchive: () => {},
  create: {
    id: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null,
  },
  update: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: false,
  },
};

const StudentMutateContext = createContext(initialState);

export function StudentMutateProvider({ children }) {
  const { tenantId, orgId } = useAuthContext();
  const queryClient = useQueryClient();

  const {
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
    isError: isCreateError,
    error: createError,
    data: createResult,
    mutateAsync: create,
    // reset: createReset,
  } = useMutation((data) => API.graphql(graphqlOperation(createStudent, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getStudents, orgId]),
  });

  const doStudentCreate = useCallback(
    async (data) => {
      await create({
        ...data,
        tenantId,
        orgId,
      });
    },
    [create, orgId, tenantId]
  );

  const {
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
    data: updateResult,
    mutateAsync: update,
    // reset: updateReset,
  } = useMutation((data) => API.graphql(graphqlOperation(updateStudent, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getStudents, orgId]),
  });

  const doStudentUpdate = useCallback(
    async (data) => {
      const { fullName, ...rest } = data;
      await update(rest);
    },
    [update]
  );

  const doStudentArchive = useCallback(
    async (data) => {
      const { fullName, ...rest } = data;
      await update({
        ...rest,
        isArchived: StringBoolean.TRUE.value,
      });
    },
    [update]
  );

  const newId = useMemo(() => {
    if (isCreateSuccess) return createResult.data.createStudent.id;
    return null;
  }, [createResult, isCreateSuccess]);

  const value = useMemo(
    () => ({
      doCreate: doStudentCreate,
      doUpdate: doStudentUpdate,
      doArchive: doStudentArchive,
      create: {
        newId,
        result: createResult,
        isSuccess: isCreateSuccess,
        isLoading: isCreateLoading,
        isError: isCreateError,
        error: createError,
      },
      update: {
        result: updateResult,
        isSuccess: isUpdateSuccess,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
      },
    }),
    [
      createError,
      createResult,
      doStudentArchive,
      doStudentCreate,
      doStudentUpdate,
      isCreateError,
      isCreateLoading,
      isCreateSuccess,
      isUpdateError,
      isUpdateLoading,
      isUpdateSuccess,
      newId,
      updateError,
      updateResult,
    ]
  );

  return <StudentMutateContext.Provider value={value}>{children}</StudentMutateContext.Provider>;
}

StudentMutateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useStudentMutateContext = () => useContext(StudentMutateContext);
