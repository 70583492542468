import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { tenantList } from 'graphql/customQueries';
import { updateTenant, createOperatingHourDay, updateOperatingHourDay } from 'graphql/customMutations';
import { DAYS_OF_WEEK, StringBoolean } from 'constants/index';
import { cacheKeys } from 'conf';
import { boolToStringBoolean, dayOfWeekOrder, stringBooleanToBool } from 'utils/index';
import { useAuthContext } from '../AuthContext';

const defaultOperatingHours = Object.keys(DAYS_OF_WEEK).map((it) => {
  const day = DAYS_OF_WEEK[it];
  return {
    order: dayOfWeekOrder[day],
    label: day,
    timeStart: '',
    timeEnd: '',
    isSelected: false,
    isArchived: StringBoolean.FALSE.value,
  };
});

const mapOperatingHours = (tenant) => {
  const {
    operatingHours: { items },
  } = tenant;
  if (items.length) {
    const sorted = items
      .sort((a, b) => a.order - b.order)
      .map((it) => {
        const { isSelected, ...rest } = it;
        return {
          ...rest,
          isSelected: stringBooleanToBool(isSelected),
        };
      });
    return {
      ...tenant,
      operatingHours: sorted,
    };
  }
  return {
    ...tenant,
    operatingHours: defaultOperatingHours,
  };
};

const TenantContext = React.createContext({
  getTenants: [],
  isLoading: false,
  error: null,
});

export function TenantDataProvider({ children }) {
  const { isAuthenticated, tenantId, orgId, userData } = useAuthContext();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    error,
    data: response,
  } = useQuery(
    cacheKeys.getTenants,
    () => API.graphql(graphqlOperation(tenantList, { filter: { isArchived: { eq: 'False' } } })),
    {
      enabled: isAuthenticated,
    }
  );

  const isSystemAdmin = useMemo(() => userData?.isSystemAdmin ?? false, [userData]);

  const getTenants = useMemo(() => {
    if (!isSuccess) return [];
    return response.data.listTenants.items
      .filter((it) => (isSystemAdmin ? true : it.orgId === orgId))
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(mapOperatingHours);
  }, [isSuccess, isSystemAdmin, orgId, response]);

  const getTenant = useMemo(
    () => (isSuccess ? getTenants.find((it) => it.id === tenantId) : null),
    [isSuccess, getTenants, tenantId]
  );

  const orgHasMultipleCenters = useMemo(
    () => (isSuccess ? response.data.listTenants.items.filter((it) => it.orgId === orgId).length > 1 : false),
    [isSuccess, orgId, response]
  );

  const {
    isError: updateIsError,
    isLoading: updateIsLoading,
    isSuccess: updateIsSuccess,
    error: updateError,
    mutateAsync: update,
  } = useMutation((data) => API.graphql(graphqlOperation(updateTenant, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries(cacheKeys.getTenants),
  });

  const normalizeData = (data) => {
    const { mobile, phone, email, ...rest } = data;
    return {
      ...rest,
      mobile: mobile || null,
      phone: phone || null,
      email: email || null,
    };
  };

  const doUpdate = useCallback(
    async (data) => {
      await update(normalizeData(data));
    },
    [update]
  );

  const batchCreateSchedule = async (data) => {
    const result = await Promise.allSettled(
      data.map(async (s) => {
        const res = await API.graphql(graphqlOperation(createOperatingHourDay, { input: { ...s, tenantId } }));
        return res;
      })
    );
    return result;
  };

  const {
    isError: scheduleCreateIsError,
    isLoading: scheduleCreateIsLoading,
    isSuccess: scheduleCreateIsSuccess,
    error: scheduleCreateError,
    mutateAsync: opHourCreate,
  } = useMutation(batchCreateSchedule, {
    onSuccess: () => queryClient.invalidateQueries(cacheKeys.getTenants),
  });

  const batchUpdateSchedule = async (data) => {
    const result = await Promise.allSettled(
      data.map(async (s) => {
        const res = await API.graphql(graphqlOperation(updateOperatingHourDay, { input: s }));
        return res;
      })
    );
    return result;
  };

  const {
    isError: scheduleUpdateIsError,
    isLoading: scheduleUpdateIsLoading,
    isSuccess: scheduleUpdateIsSuccess,
    error: scheduleUpdateError,
    mutateAsync: opHourUpdate,
  } = useMutation(batchUpdateSchedule, {
    onSuccess: () => queryClient.invalidateQueries(cacheKeys.getTenants),
  });

  const doScheduleCreateOrUpdate = useCallback(
    async (data) => {
      const mapped = data.map((it) => ({
        ...it,
        isSelected: boolToStringBoolean(it.isSelected),
      }));
      const toCreate = mapped.filter((it) => !it.id);
      const toUpdate = mapped.filter((it) => !!it.id);
      if (toCreate.length) {
        await opHourCreate(toCreate);
      }
      if (toUpdate.length) {
        await opHourUpdate(toUpdate);
      }
    },
    [opHourCreate, opHourUpdate]
  );

  const value = useMemo(
    () => ({
      getItems: getTenants,
      getItem: getTenant,
      isLoading,
      isSuccess,
      isError,
      error,
      update: {
        isLoading: updateIsLoading,
        isSuccess: updateIsSuccess,
        isError: updateIsError,
        error: updateError,
      },
      doUpdate,
      schedule: {
        isLoading: scheduleUpdateIsLoading || scheduleCreateIsLoading,
        isError: scheduleUpdateIsError || scheduleCreateIsError,
        isSuccess: scheduleUpdateIsSuccess || scheduleCreateIsSuccess,
        error: scheduleUpdateError || scheduleCreateError,
      },
      doScheduleCreateOrUpdate,
      orgHasMultipleCenters,
    }),
    [
      getTenants,
      getTenant,
      isLoading,
      isSuccess,
      isError,
      error,
      updateIsLoading,
      updateIsSuccess,
      updateIsError,
      updateError,
      doUpdate,
      scheduleCreateIsLoading,
      scheduleCreateIsError,
      scheduleCreateIsSuccess,
      scheduleCreateError,
      scheduleUpdateIsLoading,
      scheduleUpdateIsError,
      scheduleUpdateIsSuccess,
      scheduleUpdateError,
      doScheduleCreateOrUpdate,
      orgHasMultipleCenters,
    ]
  );

  return <TenantContext.Provider value={value}>{children}</TenantContext.Provider>;
}

TenantDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTenantContext = () => React.useContext(TenantContext);
