import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as LogoImg } from './Logo.svg';

function Logo(props) {
  const { className, includeHomeLink } = props;
  return (
    <div className={className}>
      {includeHomeLink ? (
        <Link to="/">
          <LogoImg />{' '}
        </Link>
      ) : (
        <LogoImg />
      )}
    </div>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  includeHomeLink: PropTypes.bool,
};

Logo.defaultProps = {
  className: 'logo--global',
  includeHomeLink: true,
};

export default Logo;
