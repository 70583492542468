import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

class SubmitButton extends PureComponent {
  static propTypes = {
    btnText: PropTypes.string.isRequired,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    btnClassName: PropTypes.string,
  };

  static defaultProps = {
    isDisabled: false,
    className: 'form-element form-element--submit',
    btnClassName: 'btn btn-action',
  };

  render() {
    const { isDisabled, btnText, className, btnClassName, ...props } = this.props;
    return (
      <div className={className}>
        <Button {...props} btnType="submit" isDisabled={isDisabled} className={btnClassName}>
          {btnText}
        </Button>
      </div>
    );
  }
}

export default SubmitButton;
