import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTenantContext } from 'context/Tenant/TenantContext';
import { useStudentListContext } from 'context/Student/StudentListContext';
import { EMPTY_STRING, StringBoolean } from 'constants/index';
import InputSelect from './InputSelect';

const empty = [{ label: EMPTY_STRING, value: EMPTY_STRING }];

const abbrs = {};

const getAbbr = (tenantId, tenantList) => {
  if (abbrs[tenantId]) return abbrs[tenantId];
  const fullTenantName = (tenantList || []).find((it) => it.id === tenantId)?.name ?? '';
  const suffix =
    fullTenantName
      ?.replace(/[()]/gi, '')
      .split(/\s/)
      .map((it) => it[0])
      .join('') ?? '';
  abbrs[tenantId] = `(${suffix})`;
  return abbrs[tenantId];
};

export function StudentSelect(props) {
  const { getStudents, getOrgStudents } = useStudentListContext();
  const { getItems: tenants } = useTenantContext();
  const { showInactiveParties, includeOrgStudents } = props;

  const options = useMemo(() => {
    const byTenant = includeOrgStudents ? getOrgStudents : getStudents;

    const values = showInactiveParties
      ? byTenant
      : byTenant.filter((it) => it.isArchived === StringBoolean.FALSE.value);

    return values
      .map((it) => ({
        label: includeOrgStudents ? `${it.fullName} ${getAbbr(it.tenantId, tenants)}` : it.fullName,
        value: it.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [getOrgStudents, getStudents, includeOrgStudents, showInactiveParties, tenants]);

  return <InputSelect {...props} options={empty.concat(options)} />;
}

StudentSelect.propTypes = {
  filter: PropTypes.bool,
  includeOrgStudents: PropTypes.bool,
  showInactiveParties: PropTypes.bool,
  idsToFilter: PropTypes.arrayOf(PropTypes.string),
};

StudentSelect.defaultProps = {
  filter: false,
  includeOrgStudents: false,
  showInactiveParties: false,
  idsToFilter: [],
};
