import PropTypes from 'prop-types';
import { AdultListDataProvider } from './Adult/AdultListContext';
import { StudentListDataProvider } from './Student/StudentListContext';

export function PartyListProviders({ children }) {
  return (
    <StudentListDataProvider>
      <AdultListDataProvider>{children}</AdultListDataProvider>
    </StudentListDataProvider>
  );
}

PartyListProviders.propTypes = {
  children: PropTypes.node.isRequired,
};
