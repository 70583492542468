import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Toggle extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    labelText: PropTypes.string,
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    isInline: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    isHorizontal: PropTypes.bool,
  };

  static defaultProps = {
    onChange: null,
    className: 'form-element form-element--toggle',
    labelClassName: 'label',
    isDisabled: false,
    isRequired: false,
    isInline: false,
    isHorizontal: false,
    labelText: '',
    id: '',
  };

  render() {
    const {
      id,
      className,
      labelClassName,
      labelText,
      isChecked,
      isDisabled,
      isRequired,
      isInline,
      onChange,
      isHorizontal,
    } = this.props;
    const wrapperClass = !isInline ? className : `${className}-inline`;
    return (
      /* eslint-disable jsx-a11y/label-has-for */
      <div className={isHorizontal ? `${wrapperClass} horizontal` : wrapperClass}>
        <label className={labelClassName} htmlFor={id}>
          {labelText}
          {isRequired && <span className="required">*</span>}
          {isRequired && <span className="required">*</span>}
        </label>
        <input id={id} name={id} type="checkbox" onChange={onChange} checked={isChecked} disabled={isDisabled} />
        <span className="toggle" />
      </div>
      /* eslint-enable */
    );
  }
}

export default Toggle;
