import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  ROUTE_ADMIN,
  ROUTE_CHOOSE_TENANT,
  ROUTE_TIMESHEET,
  ROUTE_STAFF_LIST,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_STAFFMEMBER_CREATE,
  ROUTE_STAFFMEMBER_UPDATE,
  ROUTE_STUDENT_LIST,
  ROUTE_STUDENT_VIEW,
  ROUTE_STUDENT_CREATE,
  ROUTE_STUDENT_UPDATE,
  ROUTE_ADULT_LIST,
  ROUTE_ADULT_VIEW,
  ROUTE_ADULT_CREATE,
  ROUTE_ADULT_UPDATE,
  ROUTE_RELATIONSHIP_UPDATE,
  ROUTE_RELATIONSHIP_LIST,
  ROUTE_RELATIONSHIP_ADD,
  ROUTE_RELATIONSHIP_SELECT_TYPE,
  ROUTE_DASHBOARD,
} from 'constants/index';
import { ErrorBoundary } from 'react-error-boundary';
import { LauncherGraphic } from 'components/graphic/LauncherGraphic';
import { useAuthContext } from 'context/AuthContext';
import { useAppContext } from 'context/AppContext';
import { TimesheetDataProvider } from 'context/Timesheet/TimesheetContext';
import { StudentMutateProvider } from 'context/Student/StudentMutateContext';
import { AdultMutateProvider } from 'context/Adult/AdultMutateContext';
import { StrategicDashboardProvider } from 'context/Dashboard/StrategicDashboardContext';
import { ClassroomDataProvider } from 'context/Classroom/ClassroomContext';
import ErrorFallback from 'components/ErrorFallback';
import TenantCheckWrapper from './TenantCheckWrapper';
import NoMatch from './NoMatch';
import SelectTenant from './SelectTenant';
import AmplifyAuth from './AmplifyAuth';
import Students from './student/Students';
import Adults from './adult/Adults';
import StaffMembers from './staffMember/StaffMembers';

import 'assets/css/loader-styles.css';

const Admin = lazy(() => import('./Admin'));
const StrategicDashboard = lazy(() => import('./StrategicDashboard'));
const Timesheets = lazy(() => import('./timesheets/Timesheets'));
const Student = lazy(() => import('./student/Student'));
const StudentAdd = lazy(() => import('./student/StudentAdd'));
const StudentUpdate = lazy(() => import('./student/StudentUpdate'));
const StaffMember = lazy(() => import('./staffMember/StaffMember'));
const StaffMemberAdd = lazy(() => import('./staffMember/StaffMemberAdd'));
const StaffMemberUpdate = lazy(() => import('./staffMember/StaffMemberUpdate'));
const Adult = lazy(() => import('./adult/Adult'));
const AdultAdd = lazy(() => import('./adult/AdultAdd'));
const AdultUpdate = lazy(() => import('./adult/AdultUpdate'));
const RelationshipAdd = lazy(() => import('./relationship/RelationshipAdd'));
const RelationshipUpdate = lazy(() => import('./relationship/RelationshipUpdate'));
const RelationshipSelectType = lazy(() => import('./relationship/RelationshipSelectType'));
const RelationshipListWrapper = lazy(() => import('./relationship/RelationshipListWrapper'));

export function AppLauncher() {
  const { isAuthenticated } = useAuthContext();
  const { isAppLoading } = useAppContext();
  if (isAuthenticated) {
    if (isAppLoading) {
      return (
        <div className="loader--main">
          <LauncherGraphic />
          <h4 data-text="Loading EasyAdmin">Loading EasyAdmin</h4>
        </div>
      );
    }

    import('assets/css/styles.css');

    return (
      <Router>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<div className="loading" />}>
            <Switch>
              <Route path={ROUTE_ADMIN}>
                <TenantCheckWrapper>
                  <Admin />
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_CHOOSE_TENANT} exact>
                <SelectTenant />
              </Route>
              <Route path={ROUTE_TIMESHEET} exact>
                <TimesheetDataProvider>
                  <TenantCheckWrapper>
                    <Timesheets />
                  </TenantCheckWrapper>
                </TimesheetDataProvider>
              </Route>
              <Route path={ROUTE_STUDENT_LIST} exact>
                <TenantCheckWrapper>
                  <Students />
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_STUDENT_CREATE} exact>
                <TenantCheckWrapper>
                  <StudentMutateProvider>
                    <StudentAdd />
                  </StudentMutateProvider>
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_STUDENT_UPDATE} exact>
                <TenantCheckWrapper>
                  <StudentMutateProvider>
                    <StudentUpdate />
                  </StudentMutateProvider>
                </TenantCheckWrapper>
              </Route>
              <Route path={`${ROUTE_STUDENT_VIEW}:id`}>
                <TenantCheckWrapper>
                  <Student />
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_ADULT_LIST} exact>
                <TenantCheckWrapper>
                  <Adults />
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_ADULT_CREATE} exact>
                <TenantCheckWrapper>
                  <AdultMutateProvider>
                    <AdultAdd />
                  </AdultMutateProvider>
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_ADULT_UPDATE} exact>
                <TenantCheckWrapper>
                  <AdultMutateProvider>
                    <AdultUpdate />
                  </AdultMutateProvider>
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_ADULT_VIEW}>
                <TenantCheckWrapper>
                  <Adult />
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_STAFF_LIST} exact>
                <TenantCheckWrapper>
                  <StaffMembers />
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_STAFFMEMBER_CREATE} exact>
                <TenantCheckWrapper>
                  <AdultMutateProvider>
                    <StaffMemberAdd />
                  </AdultMutateProvider>
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_STAFFMEMBER_UPDATE} exact>
                <TenantCheckWrapper>
                  <AdultMutateProvider>
                    <StaffMemberUpdate />
                  </AdultMutateProvider>
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_STAFFMEMBER_VIEW}>
                <TenantCheckWrapper>
                  <StaffMember />
                </TenantCheckWrapper>
              </Route>
              <Route exact path={ROUTE_RELATIONSHIP_LIST}>
                <TenantCheckWrapper>
                  <RelationshipListWrapper />
                </TenantCheckWrapper>
              </Route>
              <Route exact path={ROUTE_RELATIONSHIP_SELECT_TYPE}>
                <TenantCheckWrapper>
                  <RelationshipSelectType />
                </TenantCheckWrapper>
              </Route>
              <Route exact path={ROUTE_RELATIONSHIP_ADD}>
                <TenantCheckWrapper>
                  <RelationshipAdd />
                </TenantCheckWrapper>
              </Route>
              <Route exact path={ROUTE_RELATIONSHIP_UPDATE}>
                <TenantCheckWrapper>
                  <RelationshipUpdate />
                </TenantCheckWrapper>
              </Route>
              <Route path={`${ROUTE_DASHBOARD}y/:year/w/:weekNumber`}>
                <TenantCheckWrapper>
                  <ClassroomDataProvider>
                    <StrategicDashboardProvider>
                      <StrategicDashboard />
                    </StrategicDashboardProvider>
                  </ClassroomDataProvider>
                </TenantCheckWrapper>
              </Route>
              <Route path={ROUTE_DASHBOARD}>
                <TenantCheckWrapper>
                  <ClassroomDataProvider>
                    <StrategicDashboardProvider>
                      <StrategicDashboard />
                    </StrategicDashboardProvider>
                  </ClassroomDataProvider>
                </TenantCheckWrapper>
              </Route>
              <Route>
                <NoMatch />
              </Route>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Router>
    );
  }
  return <AmplifyAuth />;
}
