import { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

function Modal(props) {
  const ref = useRef(null);
  const { isShowing: initialIsShowing, modalTitle, children, onClose } = props;
  const [isShowing, setIsShowing] = useState(initialIsShowing);

  const closeModal = useCallback(() => {
    setIsShowing(false);
    if (onClose) onClose();
  }, [onClose]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       ref.current &&
  //       !ref.current.contains(event.target) &&
  //       !Array.from(event.target.classList).includes('CalendarDay')
  //     ) {
  //       closeModal();
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [closeModal, isShowing, onClose, setIsShowing]);

  // const openModal = useCallback(() => {
  //   setIsShowing(true);
  //   if (onOpen) onOpen();
  // }, [onOpen]);

  const classNames = ['modal'];

  if (isShowing) {
    classNames.push('modal--open');
    document.body.classList.add('has-modal');
  } else {
    document.body.classList.remove('has-modal');
  }

  return (
    <div className={`${classNames.join(' ')}`}>
      <div className="modal-content" ref={ref}>
        <div className="modal-header">
          <h2>{modalTitle}</h2>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button className="modal-close" type="button" onClick={closeModal} />
        </div>
        <div className="modal-inner">{children}</div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  // onOpen: null,
  onClose: null,
};

export default Modal;
