import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class InputTextarea extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    value: PropTypes.string,
    hintText: PropTypes.string,
    errorText: PropTypes.string,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    maxLength: PropTypes.number,
  };

  static defaultProps = {
    labelText: null,
    hintText: null,
    errorText: null,
    value: undefined,
    isDisabled: false,
    isRequired: false,
    onChange: null,
    maxLength: 0,
  };

  render() {
    const { id, labelText, hintText, value, isDisabled, isRequired, errorText, onChange, maxLength } = this.props;
    const classNames = ['form-element', `form-element--textarea`]
      .concat(errorText ? ['form-element--error'] : [])
      .concat(isRequired ? ['form-element--required'] : []);
    const inputProps = {
      id,
      name: id,
      disabled: isDisabled,
      required: isRequired,
      onChange,
      value,
      ...(hintText && { 'aria-describedby': `${id}-hint` }),
      ...(!!maxLength && { maxLength }),
    };
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <div className={classNames.join(' ')}>
        {labelText && (
          <label className="label" htmlFor={id} id={`${id}-label`}>
            {labelText}
            {isRequired && <span className="required">*</span>}
          </label>
        )}
        {hintText && (
          <span id={`${id}-hint`} className="form-element-hint">
            {hintText}
          </span>
        )}
        {errorText && <span className="form-element-error-msg">{errorText}</span>}
        <textarea {...inputProps} />
      </div>
    );
    /* eslint-enable */
  }
}

export default InputTextarea;
