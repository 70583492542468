import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const ERROR_TEXT = 'Invalid number.';

class InputPin extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    length: PropTypes.number,
    hintText: PropTypes.string,
    labelText: PropTypes.string,
    errorText: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    focusOnLoad: PropTypes.bool,
    onChange: PropTypes.func,
    getValue: PropTypes.func,
  };

  static defaultProps = {
    length: 6,
    labelText: null,
    hintText: null,
    errorText: null,
    isRequired: false,
    isDisabled: false,
    focusOnLoad: true,
    onChange: () => {},
    getValue: () => {},
  };

  constructor(props) {
    super(props);
    this.inputRefs = [];
    this.state = {
      error: '',
      pinValue: '',
    };
  }

  componentDidMount() {
    const { focusOnLoad, errorText } = this.props;
    this.setState({
      error: errorText,
    });
    if (focusOnLoad && this.inputRefs[0]) {
      this.inputRefs[0].focus();
    }
  }

  setRef = (ref) => {
    this.inputRefs.push(ref);
  };

  handleOnKeyDown = (index, e) => {
    const { onChange, length } = this.props;
    const { value } = e.target;

    if (e.keyCode === 8) {
      if (value === '' && index) {
        this.setState(
          (prevState) => ({
            pinValue: prevState.pinValue.substring(0, index - 1),
            error: '',
          }),
          () => {
            this.inputRefs[index - 1].focus();
          }
        );
      }
      return;
    }
    if (index !== length - 1) {
      setTimeout(() => {
        this.inputRefs[index + 1].focus();
      }, 0);
    } else {
      setTimeout(() => {
        this.inputRefs[index].blur();
      }, 0);
    }
    onChange();
  };

  handleOnBlur = (index, e) => {
    const pattern = /[0-9]/g;
    const { value } = e.target;

    if (value !== '' && !pattern.test(value)) {
      this.setState(
        {
          error: ERROR_TEXT,
        },
        () => {
          this.inputRefs[index].focus();
          this.inputRefs[index].select();
        }
      );
    } else if (value !== '') {
      this.setState(
        (prevState) => ({
          pinValue: prevState.pinValue.concat(value),
          error: '',
        }),
        () => {
          const { getValue } = this.props;
          const { pinValue } = this.state;
          getValue(pinValue);
        }
      );
    }
  };

  render() {
    const classNames = ['form-group', 'form-group--pin'];
    const { id, length, labelText, hintText, isDisabled, isRequired } = this.props;
    const { error } = this.state;
    const inputList = [];
    let inputProps = {};
    if (isDisabled) classNames.push('form-group--disabled');
    if (isRequired) classNames.push('form-group--required');
    if (error) classNames.push('form-group--error');
    /* eslint-disable jsx-a11y/label-has-for */
    /* eslint-disable jsx-a11y/label-has-associated-control */
    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < length; i++) {
      inputProps = {
        id: `pin_${i + 1}`,
        key: `pin_${i + 1}`,
        name: 'pin',
        maxLength: 1,
        type: 'text',
        ref: this.setRef,
        onBlur: (e) => this.handleOnBlur(i, e),
        onKeyDown: (e) => this.handleOnKeyDown(i, e),
      };
      inputList.push(<input {...inputProps} />);
    }
    return (
      <fieldset className={classNames.join(' ')}>
        {labelText && (
          <legend className="label">
            {labelText}
            {isRequired && <span className="required">*</span>}
          </legend>
        )}
        {hintText && (
          <span id={`${id}-hint`} className="form-group-hint">
            {hintText}
          </span>
        )}
        {error && <span className="form-group--error-msg">{error}</span>}
        <div className="input-group"> {inputList} </div>
      </fieldset>
    );
  }
}

export default InputPin;
