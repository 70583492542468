import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/button-has-type */
function Button(props) {
  const { onClick, className, children, btnType, isDisabled } = props;
  const classNames = [className];
  if (isDisabled) classNames.push('button--disabled');

  return (
    <button className={classNames.join(' ')} onClick={onClick} type={btnType} disabled={isDisabled}>
      {children}
    </button>
  );
}
/* eslint-enable */

Button.propTypes = {
  /* Function to call when button is clicked */
  onClick: PropTypes.func,
  /* Overriding class name(s) */
  className: PropTypes.string,
  /* Type of button: button/submit */
  btnType: PropTypes.string,
  /* Boolean to disable/enable button */
  isDisabled: PropTypes.bool,
  /* Text, spans, child components, etc... */
  children: PropTypes.node,
};

Button.defaultProps = {
  onClick: null,
  isDisabled: false,
  btnType: 'button',
  className: 'btn',
  children: null,
};

export default Button;
