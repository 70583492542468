import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAppContext } from 'context/AppContext';
import config from 'conf';

const AuthContext = React.createContext({
  isAuthenticated: false,
  authState: 'configuring',
  user: {},
  userData: {},
  tenantId: null,
  orgId: null,
  roles: [],
  chooseTenant: () => {},
  changeTenant: () => {},
  error: null,
  hasTenant: false,
});

const storeTenantSelection = (tenantId, stateFn, orgId, orgStateFn) => {
  stateFn(tenantId);
  orgStateFn(orgId);
  try {
    localStorage.setItem(config.tenant.key, tenantId);
    localStorage.setItem(config.org.key, orgId);
  } catch {
    // console.error(error);
  }
};

const resetTenantSelection = (stateFn) => {
  stateFn(null);
  try {
    localStorage.removeItem(config.tenant.key);
  } catch {
    // console.error(error);
  }
};

const loadTenantSelection = (stateFn, orgStateFn) => {
  try {
    const tenantId = localStorage.getItem(config.tenant.key);
    const orgId = localStorage.getItem(config.org.key);
    if (tenantId) {
      stateFn(tenantId);
    }
    if (orgId) {
      orgStateFn(orgId);
    }
  } catch {
    // console.error(error);
  }
};

export function AuthProvider({ children }) {
  const queryClient = useQueryClient();
  const { setIsAppLoading } = useAppContext();
  const { user, authStatus, signOut } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
    context.signOut,
  ]);

  const [authState, setAuthState] = useState('configuring');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tenantId, setTenantId] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setIsAppLoading(true);
    loadTenantSelection(setTenantId, setOrgId);
    // console.log(user);
    setAuthState(authStatus);
    if (authStatus === 'authenticated' && !!user) {
      const {
        attributes: { sub },
        signInUserSession: {
          idToken: { payload, jwtToken },
        },
      } = user;
      API.configure({
        graphql_headers: () => ({
          Authorization: jwtToken,
        }),
      });
      setUserId(sub);
      setIsAuthenticated(true);
      // setOrgId(payload.org_id);
      setRoles(payload['cognito:groups']);
      // if we have an org and tenant saved, exit early once auth data has
      // been added to the store
      if (!orgId && !tenantId) {
        const isSystemAdmin = JSON.parse(payload.is_system_admin);
        const tenants = JSON.parse(payload.tenants);
        const tenantCount = tenants.length;
        // auto-set tenant and org if there's only one tenant for the user's org
        // or if the user is a SysAdmin
        if (tenantCount === 1 && !isSystemAdmin) {
          storeTenantSelection(tenants[0], setTenantId, payload.org_id, setOrgId);
        } else {
          // just set the org
          setOrgId(payload.org_id);
        }
      }
      setIsAppLoading(false);
    }
  }, [setIsAppLoading, user, authStatus, orgId, tenantId]);

  const userData = useMemo(() => {
    if (authStatus !== 'authenticated' || !user) return {};
    const {
      username,
      attributes: { name },
      signInUserSession: {
        idToken: { payload },
      },
    } = user;

    return {
      username,
      fullName: name,
      tenants: JSON.parse(payload.tenants),
      isSystemAdmin: JSON.parse(payload.is_system_admin),
    };
  }, [authStatus, user]);

  const logOut = useCallback(async () => {
    signOut();
    queryClient.clear();
    setIsAuthenticated(false);
    resetTenantSelection(setTenantId);
  }, [queryClient, signOut]);

  const changeTenant = useCallback(
    (id, oId) => {
      storeTenantSelection(id, setTenantId, oId, setOrgId);
      queryClient.removeQueries();
    },
    [queryClient]
  );

  const value = useMemo(
    () => ({
      isAuthenticated,
      authState,
      userId,
      user,
      userData,
      tenantId,
      orgId,
      roles,
      hasTenant: !!tenantId,
      chooseTenant: (id, oId) => storeTenantSelection(id, setTenantId, oId, setOrgId),
      changeTenant,
      logOut,
    }),
    [isAuthenticated, authState, userId, user, userData, tenantId, orgId, roles, changeTenant, logOut]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuthContext = () => React.useContext(AuthContext);
