import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableRow from 'components/table/TableRow';
import { Link } from 'react-router-dom';
import { Button } from 'components/buttons';
import { useModal } from 'utils/useModal';
import { ConfirmModal } from 'components/modal';
import { useClassroomContext } from 'context/Classroom/ClassroomContext';
import { ROUTE_ADMIN_CLASSROOMS, TYPE_STUDENT, TYPE_STAFF } from 'constants/index';
import { ROUTE_STAFFMEMBER_PROFILE, ROUTE_STUDENT_PROFILE } from 'constants/routes';

const getConfirmationMessage = (classroomName, isClassroomAssigned) => {
  if (!isClassroomAssigned)
    return (
      <p>
        Are you sure you want to delete classroom <strong>{classroomName}</strong>?
      </p>
    );
  return (
    <>
      <p className="alert--error">This classroom cannot be deleted because it is assigned to some parties.</p>
      <p>Before you can delete this classroom the following parties need to be re-assigned:</p>
    </>
  );
};

const getContent = (isLoading, isClassroomAssigned, classroomName, students, adults) =>
  isLoading ? (
    <div className="loading" />
  ) : (
    <div>
      {getConfirmationMessage(classroomName, isClassroomAssigned)}
      {isClassroomAssigned && (
        <div className="table table--assigned-parties">
          <ul className="table-header" key="header">
            <li>Party</li>
            <li>Type</li>
          </ul>
          {students.map((s) => {
            const {
              schedule: { student },
            } = s;
            return (
              <ul className="table-row" key={student.id}>
                <li>
                  <Link to={`${ROUTE_STUDENT_PROFILE.replace(':id', student.id)}schedule/`} target="_blank">
                    {student.lastName}, {student.firstName}
                  </Link>
                </li>
                <li>{TYPE_STUDENT}</li>
              </ul>
            );
          })}
          {adults.map((a) => {
            const {
              schedule: { adult },
            } = a;
            return (
              <ul className="table-row" key={adult.id}>
                <li>
                  <Link to={`${ROUTE_STAFFMEMBER_PROFILE.replace(':id', adult.id)}schedule/`} target="_blank">
                    {adult.lastName}, {adult.firstName}
                  </Link>
                </li>
                <li>{TYPE_STAFF}</li>
              </ul>
            );
          })}
        </div>
      )}
    </div>
  );

function TableRowAdminClassrooms({ data }) {
  const { id, name, nickName, ratio, minMonths, maxMonths, maxSize, isAdmin } = data;
  const { isShowing, toggle } = useModal();

  const {
    doArchive,
    update: { isSuccess: isUpdateSuccess, isLoading: isUpdateLoading },
    doActiveCheck,
    activeCheck: { isLoading, isError, error, data: activeCheckData },
  } = useClassroomContext();

  const classroomName = useMemo(() => (nickName && nickName !== '' ? nickName : name), [name, nickName]);

  const confirmMessage = useMemo(
    () =>
      getContent(
        isLoading,
        activeCheckData?.isAssigned,
        classroomName,
        activeCheckData?.studentResults,
        activeCheckData?.staffResults
      ),
    [
      activeCheckData?.isAssigned,
      activeCheckData?.staffResults,
      activeCheckData?.studentResults,
      classroomName,
      isLoading,
    ]
  );

  const isAssigned = useMemo(() => activeCheckData?.isAssigned ?? false, [activeCheckData]);

  return (
    <>
      <TableRow key={id}>
        <li className="tc--name">{classroomName}</li>
        <li className="tc--age-range">{isAdmin ? '' : `${minMonths}-${maxMonths} months`}</li>
        <li className="tc--ratio">{isAdmin ? '' : `1:${ratio}`}</li>
        <li className="tc--max-size">{isAdmin ? '' : `${maxSize}`}</li>
        <li className="tc--edit">
          <Link className="button--text" to={`${ROUTE_ADMIN_CLASSROOMS}${id}/update`}>
            Edit
          </Link>
        </li>
        <li className="tc--delete">
          <Button
            className="button--text--neg"
            onClick={() => {
              toggle();
              doActiveCheck(id);
            }}
          >
            Delete
          </Button>
        </li>
      </TableRow>
      <ConfirmModal
        isShowing={isShowing}
        title="Delete Classroom"
        content={confirmMessage}
        isRequesting={isUpdateLoading}
        doAction={async () => {
          await doArchive(data);
          if (isUpdateSuccess) {
            toggle();
          }
        }}
        hide={toggle}
        disableConfirmation={isLoading || isAssigned}
        confirmLabel="Delete Classroom"
        confirmActingLabel="Deleting..."
        cancelLabel="Cancel"
      />
    </>
  );
}
TableRowAdminClassrooms.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    nickName: PropTypes.string,
    minMonths: PropTypes.number,
    maxMonths: PropTypes.number,
    ratio: PropTypes.number,
    maxSize: PropTypes.number,
    isAdmin: PropTypes.bool,
  }).isRequired,
};

export default TableRowAdminClassrooms;
