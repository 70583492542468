import { useMemo } from 'react';
import PropTypes from 'prop-types';

function TableHeader(props) {
  const { headerLabels, className } = props;
  const headerCells = useMemo(
    () =>
      headerLabels.map((it) => (
        <li className={`tc--${it.className}`} key={it.className}>
          {it.label}
        </li>
      )),
    [headerLabels]
  );

  return <ul className={className}>{headerCells}</ul>;
}

TableHeader.propTypes = {
  headerLabels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node)]).isRequired,
      className: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

TableHeader.defaultProps = {
  className: 'table-header',
};

export default TableHeader;
