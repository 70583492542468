import PropTypes from 'prop-types';
import { PageFocusview } from 'components/layout';

function ErrorFallback({ error }) {
  return (
    <PageFocusview pageClassNames={['page--form']} wrapperClassNames={['width--s']} pageTitle="Whoops!">
      <h4>Uh oh! Something went wrong&hellip;</h4>
      <br />
      <p>
        Hit the refresh button to try again.
        <br />
        If the error persists, please contact an EasyAdmin representative with the error message below
      </p>
      <br />
      <pre
        className="error"
        style={{
          padding: '12px 0',
          margin: '12px 0',
        }}
      >
        {error?.message}
      </pre>
    </PageFocusview>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.shape(),
};

ErrorFallback.defaultProps = {
  error: null,
};

export default ErrorFallback;
