export * from './routes';
export * from './api';

export const StringBoolean = {
  FALSE: {
    label: 'No',
    value: 'False',
  },
  TRUE: {
    label: 'Yes',
    value: 'True',
  },
};

export const QueryStatus = {
  LOADING: 'loading',
  IDLE: 'idle',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const RelationshipType = {
  Parent_Guardian: {
    label: 'Parent/Guardian',
    value: 'Parent_Guardian',
  },
  Sibling: {
    label: 'Sibling',
    value: 'Sibling',
  },
  Other: {
    label: 'Other',
    value: 'Other',
  },
};

export const BillingType = {
  FIXED: {
    label: 'Fixed Amount',
    value: 'FIXED',
  },
  PERC: {
    label: 'Percentage',
    value: 'PERC',
  },
};

export const TimesheetAction = {
  CHECKED_IN: {
    label: 'Checked In',
    value: 'CHECKED_IN',
  },
  CHECKED_OUT: {
    label: 'Checked Out',
    value: 'CHECKED_OUT',
  },
  OTHER: {
    label: 'Other',
    value: 'OTHER',
  },
  NO_ACTION_SELECTED: {
    label: 'No Action Selected',
    value: 'NO_ACTION_SELECTED',
  },
};

export const PartyType = {
  Adult: 'Adult',
  Student: 'Student',
  StaffMember: 'StaffMember',
};

export const ContactType = {
  AdHoc: 'AdHoc',
  Adult: 'Adult',
};

export const GqlRelationshipTypes = {
  Relationship: 'Relationship',
  Sibling: 'Sibling',
  Relative: 'Relative',
};

export const APP_LOAD_START = 'APP_LOAD_START';
export const APP_LOAD_END = 'APP_LOAD_END';

export const TIMESHEET_ALL_EXPAND_TOGGLE = 'TIMESHEET_ALL_EXPAND_TOGGLE';
export const TIMESHEET_ROW_EXPAND_TOGGLE = 'TIMESHEET_ROW_EXPAND_TOGGLE';

export const CHANGE_TENANT = 'CHANGE_TENANT';

/* eslint-disable no-underscore-dangle */
export const _MENU_OPEN = '_MENU_OPEN';
export const _MENU_CLOSE = '_MENU_CLOSE';
export const _MODAL_OPEN = '_MODAL_OPEN';
export const _MODAL_CLOSE = '_MODAL_CLOSE';
/* eslint-enable */

export const REPORT_TYPE_ATTENDANCE = 'Student';
export const REPORT_TYPE_PAYROLL = 'StaffMember';
export const REPORT_TYPE_ALL = 'REPORT_ALL';

export const TYPE_STUDENT = 'Student';
export const TYPE_STAFF = 'StaffMember';
export const TYPE_ADULT = 'Adult';
export const TYPE_ALL = 'All';

export const TYPE_STUDENT_PATH = 'student';
export const TYPE_STAFF_PATH = 'staff-member';
export const TYPE_ADULT_PATH = 'adult';

export const REL_TYPE_OTHER = 'Other';
export const REL_TYPE_PARENT_GUARDIAN = 'Parent/Guardian';
export const REL_TYPE_SIBLING = 'Sibling';

export const LIST_TYPE_RELATED = 'related-parties';
export const LIST_TYPE_EMERGENCY = 'emergency-contacts';

export const TYPE_SCHEDULE = 'Schedule';
export const TYPE_PLANNED_ABSENCE = 'PlannedAbsence';

export const EMPTY_STRING = '';

export const BILLING_TYPE_PERC = 'PERC';
export const BILLING_TYPE_FIXED = 'FIXED';

export const CHECKED_OUT = 'Checked out';
export const CHECKED_IN = 'Checked in';
export const OTHER_START = 'Other: Start';
export const OTHER_END = 'Other: End';
export const ACTION_OTHER = 'Other';
export const NO_ACTION_SELECTED = 'No action selected';

export const PROFILE_TYPE_ADULT = 'adult';
export const PROFILE_TYPE_STUDENT = 'child';
export const PROFILE_TYPE_STAFF = 'staff member';

export const PROFILE_ACTIVE = 'active';
export const PROFILE_INACTIVE = 'inactive';
export const PROFILE_ARCHIVED = 'archived';

export const SCHEDULE_TYPE_CENTER = 'CenterSchedule';
export const SCHEDULE_TYPE_PERSON = 'PersonSchedule';

export const PERMISSION_TYPE_ATTENDANCE = 'attendance';
export const PERMISSION_TYPE_BILLING = 'billing';

export const MODE_UPDATE = 'MODE_UPDATE';
export const MODE_CREATE = 'MODE_CREATE';

export const WORKFLOW_CREATE = 'WORKFLOW_CREATE';

export const PERMISSION_LABELS = {
  [PERMISSION_TYPE_ATTENDANCE]: 'Drop Off/Pick Up',
  [PERMISSION_TYPE_BILLING]: 'Payment',
};

export const TIMESHEET_LABEL_ATTENDANCE = 'Attendance';
export const TIMESHEET_LABEL_PAYROLL = 'Payroll';

export const OPTIONS_RELATIONSHIPS = [
  { label: EMPTY_STRING, value: EMPTY_STRING },
  { ...RelationshipType.Parent_Guardian },
  { ...RelationshipType.Other },
];

export const OPTIONS_BILLING_AMOUNTS = [
  { label: EMPTY_STRING, value: EMPTY_STRING },
  { ...BillingType.PERC },
  { ...BillingType.FIXED },
];

export const OPTIONS_TS_ACTIONS = [
  {
    label: EMPTY_STRING,
    value: EMPTY_STRING,
  },
  {
    ...TimesheetAction.CHECKED_IN,
  },
  {
    ...TimesheetAction.CHECKED_OUT,
  },
  {
    ...TimesheetAction.OTHER,
  },
];

export const DATE_PICKER_OPEN_DIRECTION = {
  Down: 'down',
  Up: 'up',
};

export const DAYS_OF_WEEK = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
};

export const MONTHS = {};

export const ADMIN_CLASSROOM = 'Admin/Office';

export const PREV = 'prev';
export const NEXT = 'next';
