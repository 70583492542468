import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const ToggleMenuContext = React.createContext({
  isShowing: false,
  setIsShowing: () => {},
});

export function ToggleMenuProvider({ children }) {
  const [isShowing, setIsShowing] = useState(false);

  const value = useMemo(
    () => ({
      isShowing,
      setIsShowing,
    }),
    [isShowing]
  );

  return <ToggleMenuContext.Provider value={value}>{children}</ToggleMenuContext.Provider>;
}

ToggleMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useToggleMenuContext = () => React.useContext(ToggleMenuContext);
