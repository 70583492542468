import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/buttons';

class TableOptions extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        classNames: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  };

  static defaultProps = {
    options: [],
  };

  render() {
    const { options } = this.props;
    const children = options.map((it) => (
      <li key={it.key}>
        <Button className={it.classNames} onClick={it.onClick}>
          {it.text}
        </Button>
      </li>
    ));
    return (
      <div className="table-options">
        <ul>{children}</ul>
      </div>
    );
  }
}

export default TableOptions;
