import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'components/buttons';
import { insertIf } from 'utils/index';

class ActionLink extends PureComponent {
  static propTypes = {
    linkText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    linkDesc: PropTypes.string,
    size: PropTypes.string,
    isButton: PropTypes.bool,
    url: PropTypes.string,
    linkState: PropTypes.shape(),
  };

  static defaultProps = {
    size: 'lg',
    linkDesc: '',
    isButton: true,
    onClick: null,
    url: null,
    linkState: null,
  };

  render() {
    const { size, linkText, linkDesc, onClick, isButton, url, linkState } = this.props;
    const toProps = {
      pathname: url,
      ...insertIf(!isButton && linkState, 'state', linkState),
    };
    return isButton ? (
      <Button className={`action--${size}`} onClick={onClick}>
        <span className="link-text">{linkText}</span>
        {linkDesc && <span className="link-desc">{linkDesc}</span>}
      </Button>
    ) : (
      <Link className={`action--${size}`} to={toProps}>
        <span className="link-text">{linkText}</span>
        {linkDesc && <span className="link-desc">{linkDesc}</span>}
      </Link>
    );
  }
}

export default ActionLink;
