import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useModal } from 'utils/useModal';
import { stringToMoment, getFormattedDate } from 'utils/index';
import { aggregateErrors } from 'utils/aggregateErrors';
import {
  ROUTE_STUDENT_VIEW,
  ROUTE_STAFFMEMBER_VIEW,
  ROUTE_ADULT_VIEW,
  TYPE_STUDENT,
  TYPE_ADULT,
  TYPE_STAFF,
} from 'constants/index';
import { useScheduleMutateContext } from 'context/Schedules/ScheduleMutateContext';
import { Button } from 'components/buttons';
import { Error } from 'components/layout';
import { ConfirmModal } from 'components/modal';

const getDeleteContent = (isLoading, isError, error) => {
  if (isLoading) return <div className="loading" />;

  return (
    <div>
      {isError && <Error error={error} />}
      <p>This schedule will be deleted, are you sure?</p>
    </div>
  );
};

function TableRowSchedule(props) {
  const { id, date, children, partyType, isCurrent, isTemporary, rawData } = props;
  const { id: partyId } = useParams();
  const [classExpand, setClassExpand] = useState(isCurrent ? 'expanded' : '');
  const { isShowing, toggle } = useModal();
  const {
    doDelete,
    update: { isLoading, isSuccess, isError, error },
  } = useScheduleMutateContext();

  const errorMessage = useMemo(() => aggregateErrors(error), [error]);

  const getUrl = useMemo(() => {
    if (partyType === TYPE_STUDENT) {
      return `${ROUTE_STUDENT_VIEW}${partyId}/schedule/update/${id}/`;
    }
    if (partyType === TYPE_STAFF) {
      return `${ROUTE_STAFFMEMBER_VIEW}${partyId}/schedule/update/${id}/`;
    }
    return `${ROUTE_ADULT_VIEW}${partyId}/schedule/update/${id}/`;
  }, [id, partyId, partyType]);

  const dateFmt = useMemo(() => getFormattedDate(stringToMoment(date)), [date]);

  const clickExpandToggle = useCallback(() => setClassExpand(classExpand === '' ? 'expanded' : ''), [classExpand]);

  const classNames = ['table-row', id];
  if (classExpand) classNames.push(classExpand);
  if (isTemporary) classNames.push('flag--attention');

  return (
    <>
      <ul className={classNames.join(' ')}>
        <li className="tc--effective-date">
          <Button className="button--min" btnType="button" onClick={() => clickExpandToggle()} />
          <span>{dateFmt}</span>
        </li>
        <li className="tc--data">{!!children && children}</li>
        <li className="tc--edit">
          {/* {!isCurrent && ( */}
          <Link className="button--text" to={getUrl}>
            Edit
          </Link>
          {/* )} */}
        </li>
        <li className="tc--delete">
          {(!isCurrent || isTemporary) && (
            <Button className="button--text--neg" onClick={toggle}>
              Delete
            </Button>
          )}
        </li>
      </ul>
      {(!isCurrent || isTemporary) && (
        <ConfirmModal
          isShowing={isShowing}
          title="Delete Schedule"
          content={getDeleteContent(isLoading, isError, errorMessage)}
          isRequesting={false}
          doAction={async () => {
            await doDelete(rawData);
            if (isSuccess) toggle();
          }}
          hide={toggle}
          cancelLabel="Cancel"
          confirmLabel="Delete Schedule"
        />
      )}
    </>
  );
}
TableRowSchedule.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  isTemporary: PropTypes.bool,
  partyType: PropTypes.oneOf([TYPE_ADULT, TYPE_STAFF, TYPE_STUDENT]).isRequired,
  children: PropTypes.node.isRequired,
  rawData: PropTypes.shape(),
};

TableRowSchedule.defaultProps = {
  isTemporary: false,
  rawData: {},
};

export default TableRowSchedule;
