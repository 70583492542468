/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:eab23143-9d88-4f3e-978c-46b2c55b002e',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_R0lhMwart',
  aws_user_pools_web_client_id: '723ftheg5uhsp7d2hon9luth7k',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS', 'TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '8',
    passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_NUMBERS'],
  },
  aws_cognito_verification_mechanisms: [],
  aws_appsync_graphqlEndpoint: 'https://pjx7qlnherhkxgfuedfelcc3eq.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-eom65n7r6vg6vkrzezltfbdny4',
  aws_mobile_analytics_app_id: '905db87954d6442a8b25afb98a0ed786',
  aws_mobile_analytics_app_region: 'us-east-1',
  aws_cognito_login_mechanisms: ['PREFERRED_USERNAME'],
};

export default awsmobile;
