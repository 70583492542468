import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import {
  IconStrategicDashboard,
  IconChild,
  IconStaff,
  IconParents,
  IconTimesheet,
  IconAdmin,
} from 'components/graphic';
import {
  ROUTE_DASHBOARD,
  ROUTE_STUDENT_LIST,
  ROUTE_STAFF_LIST,
  ROUTE_TIMESHEET,
  ROUTE_ADULT_LIST,
  ROUTE_ADMIN,
} from 'constants/index';

function GlobalNav(props) {
  const {
    match: { path },
  } = props;

  return (
    <nav className="nav--global">
      <div className="wrapper--center">
        <ul>
          <li>
            {path === ROUTE_STUDENT_LIST ? (
              <span className="active">
                <IconChild />
                Children
              </span>
            ) : (
              <Link to={ROUTE_STUDENT_LIST}>
                <IconChild />
                Children
              </Link>
            )}
          </li>
          <li>
            {path === ROUTE_STAFF_LIST ? (
              <span className="active">
                <IconStaff />
                Staff
              </span>
            ) : (
              <Link to={ROUTE_STAFF_LIST}>
                <IconStaff />
                Staff
              </Link>
            )}
          </li>
          <li>
            {path === ROUTE_ADULT_LIST ? (
              <span className="active">
                <IconParents />
                Parents/Guardians
              </span>
            ) : (
              <Link to={ROUTE_ADULT_LIST}>
                <IconParents />
                Parents/Guardians
              </Link>
            )}
          </li>
          <li>
            {path === ROUTE_TIMESHEET ? (
              <span className="active">
                <IconTimesheet />
                Timesheets
              </span>
            ) : (
              <Link to={ROUTE_TIMESHEET}>
                <IconTimesheet />
                Timesheets
              </Link>
            )}
          </li>
          <li>
            {path === ROUTE_ADMIN ? (
              <span className="active">
                <IconAdmin />
                Admin
              </span>
            ) : (
              <Link to={ROUTE_ADMIN}>
                <IconAdmin />
                Admin
              </Link>
            )}
          </li>
          <li>
            {path === ROUTE_DASHBOARD || path.includes('dashboard') ? (
              <span className="active">
                <IconStrategicDashboard />
                Dashboard
              </span>
            ) : (
              <Link to={ROUTE_DASHBOARD}>
                <IconStrategicDashboard />
                Dashboard
              </Link>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
}

GlobalNav.propTypes = {
  match: PropTypes.shape().isRequired,
};

export { GlobalNav };
export default withRouter(GlobalNav);
