// import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToggleMenuContext } from 'context/ToggleMenuContext';
import { Button } from 'components/buttons';

const toggle = (toggleFn, isOpen, onOpen, onClose) => {
  if (isOpen) {
    toggleFn(false);
    if (onClose) {
      onClose();
    }
  } else {
    toggleFn(true);
    if (onOpen) {
      onOpen();
    }
  }
};

// @TODO: enable the click outside functionality once styling has been updated
function DropMenu({ menuChildren, btnContent, btnClassName, menuClassName, onOpen, onClose }) {
  // const ref = useRef(null);
  const { isShowing, setIsShowing } = useToggleMenuContext();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       toggle(setIsShowing, isShowing, onOpen, onClose);
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [isShowing, onClose, onOpen, setIsShowing]);

  const menuClassNames = [];
  const btnClassNames = ['nav-trigger'];
  if (btnClassName) btnClassNames.push(btnClassName);
  if (menuClassName) menuClassNames.push(menuClassName);
  if (isShowing) {
    btnClassNames.push('expanded');
    menuClassNames.push('visible');
  }
  return (
    <>
      <Button className={`${btnClassNames.join(' ')}`} onClick={() => toggle(setIsShowing, isShowing, onOpen, onClose)}>
        {btnContent}
      </Button>
      <nav className={`${menuClassNames.join(' ')}`}>{menuChildren}</nav>
    </>
  );
}

DropMenu.propTypes = {
  menuChildren: PropTypes.node.isRequired,
  btnContent: PropTypes.node.isRequired,
  btnClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

DropMenu.defaultProps = {
  onOpen: null,
  onClose: null,
  btnClassName: '',
  menuClassName: '',
};

export default DropMenu;
