/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStateRatio = /* GraphQL */ `
  query GetStateRatio($id: ID!) {
    getStateRatio(id: $id) {
      id
      name
      ratio
      minMonths
      maxMonths
      maxSize
      state
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listStateRatios = /* GraphQL */ `
  query ListStateRatios($filter: ModelStateRatioFilterInput, $limit: Int, $nextToken: String) {
    listStateRatios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ratio
        minMonths
        maxMonths
        maxSize
        state
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const ratioByState = /* GraphQL */ `
  query RatioByState(
    $state: String
    $sortDirection: ModelSortDirection
    $filter: ModelStateRatioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RatioByState(state: $state, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ratio
        minMonths
        maxMonths
        maxSize
        state
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStateRatios = /* GraphQL */ `
  query SyncStateRatios($filter: ModelStateRatioFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncStateRatios(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        name
        ratio
        minMonths
        maxMonths
        maxSize
        state
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClassroom = /* GraphQL */ `
  query GetClassroom($id: ID!) {
    getClassroom(id: $id) {
      id
      orgId
      tenantId
      name
      nickName
      ratio
      minMonths
      maxMonths
      maxSize
      isAdmin
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      tenant {
        id
        orgId
        name
        address
        address2
        city
        zip
        state
        mobile
        phone
        email
        notifyOnTimesheetAdd
        billPayrollToHomeCenter
        billAttendanceToHomeCenter
        timeZone
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        org {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        operatingHours {
          nextToken
          startedAt
        }
      }
      studentDays {
        items {
          id
          tenantId
          scheduleId
          order
          label
          timeStart
          timeEnd
          isSelected
          classroomId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      staffDays {
        items {
          id
          tenantId
          scheduleId
          order
          label
          timeStart
          timeEnd
          isSelected
          classroomId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listClassrooms = /* GraphQL */ `
  query ListClassrooms($filter: ModelClassroomFilterInput, $limit: Int, $nextToken: String) {
    listClassrooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        tenantId
        name
        nickName
        ratio
        minMonths
        maxMonths
        maxSize
        isAdmin
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tenant {
          id
          orgId
          name
          address
          address2
          city
          zip
          state
          mobile
          phone
          email
          notifyOnTimesheetAdd
          billPayrollToHomeCenter
          billAttendanceToHomeCenter
          timeZone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        studentDays {
          nextToken
          startedAt
        }
        staffDays {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const classroomByTenant = /* GraphQL */ `
  query ClassroomByTenant(
    $tenantId: ID
    $isArchived: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ClassroomByTenant(
      tenantId: $tenantId
      isArchived: $isArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        name
        nickName
        ratio
        minMonths
        maxMonths
        maxSize
        isAdmin
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tenant {
          id
          orgId
          name
          address
          address2
          city
          zip
          state
          mobile
          phone
          email
          notifyOnTimesheetAdd
          billPayrollToHomeCenter
          billAttendanceToHomeCenter
          timeZone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        studentDays {
          nextToken
          startedAt
        }
        staffDays {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClassrooms = /* GraphQL */ `
  query SyncClassrooms($filter: ModelClassroomFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncClassrooms(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        orgId
        tenantId
        name
        nickName
        ratio
        minMonths
        maxMonths
        maxSize
        isAdmin
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tenant {
          id
          orgId
          name
          address
          address2
          city
          zip
          state
          mobile
          phone
          email
          notifyOnTimesheetAdd
          billPayrollToHomeCenter
          billAttendanceToHomeCenter
          timeZone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        studentDays {
          nextToken
          startedAt
        }
        staffDays {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      orgId
      roles
      tenants
      adultId
      userName
      isSystemAdmin
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        roles
        tenants
        adultId
        userName
        isSystemAdmin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const userByAdultId = /* GraphQL */ `
  query UserByAdultId(
    $adultId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserByAdultId(
      adultId: $adultId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        roles
        tenants
        adultId
        userName
        isSystemAdmin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncUsers(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        orgId
        roles
        tenants
        adultId
        userName
        isSystemAdmin
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      tenants {
        items {
          id
          orgId
          name
          address
          address2
          city
          zip
          state
          mobile
          phone
          email
          notifyOnTimesheetAdd
          billPayrollToHomeCenter
          billAttendanceToHomeCenter
          timeZone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations($filter: ModelOrganisationFilterInput, $limit: Int, $nextToken: String) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tenants {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganisations = /* GraphQL */ `
  query SyncOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tenants {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      orgId
      name
      address
      address2
      city
      zip
      state
      mobile
      phone
      email
      notifyOnTimesheetAdd
      billPayrollToHomeCenter
      billAttendanceToHomeCenter
      timeZone
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      org {
        id
        name
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tenants {
          nextToken
          startedAt
        }
      }
      operatingHours {
        items {
          id
          tenantId
          order
          label
          timeStart
          timeEnd
          isSelected
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants($filter: ModelTenantFilterInput, $limit: Int, $nextToken: String) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        name
        address
        address2
        city
        zip
        state
        mobile
        phone
        email
        notifyOnTimesheetAdd
        billPayrollToHomeCenter
        billAttendanceToHomeCenter
        timeZone
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        org {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        operatingHours {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const tenantsByOrg = /* GraphQL */ `
  query TenantsByOrg(
    $orgId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TenantsByOrg(orgId: $orgId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        name
        address
        address2
        city
        zip
        state
        mobile
        phone
        email
        notifyOnTimesheetAdd
        billPayrollToHomeCenter
        billAttendanceToHomeCenter
        timeZone
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        org {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        operatingHours {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTenants = /* GraphQL */ `
  query SyncTenants($filter: ModelTenantFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncTenants(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        orgId
        name
        address
        address2
        city
        zip
        state
        mobile
        phone
        email
        notifyOnTimesheetAdd
        billPayrollToHomeCenter
        billAttendanceToHomeCenter
        timeZone
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        org {
          id
          name
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        operatingHours {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getOperatingHourDay = /* GraphQL */ `
  query GetOperatingHourDay($id: ID!) {
    getOperatingHourDay(id: $id) {
      id
      tenantId
      order
      label
      timeStart
      timeEnd
      isSelected
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOperatingHourDays = /* GraphQL */ `
  query ListOperatingHourDays($filter: ModelOperatingHourDayFilterInput, $limit: Int, $nextToken: String) {
    listOperatingHourDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        order
        label
        timeStart
        timeEnd
        isSelected
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const activeOperatingHourByTenant = /* GraphQL */ `
  query ActiveOperatingHourByTenant(
    $tenantId: ID
    $isArchived: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOperatingHourDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActiveOperatingHourByTenant(
      tenantId: $tenantId
      isArchived: $isArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        order
        label
        timeStart
        timeEnd
        isSelected
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOperatingHourDays = /* GraphQL */ `
  query SyncOperatingHourDays(
    $filter: ModelOperatingHourDayFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOperatingHourDays(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        order
        label
        timeStart
        timeEnd
        isSelected
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdult = /* GraphQL */ `
  query GetAdult($id: ID!) {
    getAdult(id: $id) {
      id
      tenantId
      orgId
      userId
      startDate
      isStartDateEstimated
      endDate
      isEndDateEstimated
      isLocked
      lockReason
      pin
      firstName
      lastName
      email
      mobile
      dateOfBirth
      homePhone
      workPhone
      address
      address2
      city
      state
      zip
      didSendInvite
      isStaffMember
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      relatives {
        items {
          id
          tenantId
          ownerId
          relativeId
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      relatedTo {
        items {
          id
          tenantId
          ownerId
          relativeId
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      children {
        items {
          id
          tenantId
          adultId
          studentId
          rel
          relType
          attendance
          billing
          billingAmount
          billingType
          isPrimary
          relDetails
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      timeSheets {
        items {
          id
          orgId
          tenantId
          billTo
          adultId
          action
          actionDetails
          dateTime
          timeStamp
          date
          time
          isBestGuess
          isManualEntry
          manualEntryReason
          isIgnored
          isIgnoredReason
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      schedules {
        items {
          id
          tenantId
          adultId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      plannedAbsences {
        items {
          id
          tenantId
          adultId
          startDate
          endDate
          reason
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      emergencyContacts {
        items {
          id
          tenantId
          adultId
          adHocContactId
          relationship
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listAdults = /* GraphQL */ `
  query ListAdults($filter: ModelAdultFilterInput, $limit: Int, $nextToken: String) {
    listAdults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const adultByUserId = /* GraphQL */ `
  query AdultByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAdultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AdultByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const adultByPin = /* GraphQL */ `
  query AdultByPin(
    $pin: String
    $isArchivedTenantId: ModelAdultAdultByPinCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AdultByPin(
      pin: $pin
      isArchivedTenantId: $isArchivedTenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const adultByTenant = /* GraphQL */ `
  query AdultByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAdultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AdultByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const adultByOrg = /* GraphQL */ `
  query AdultByOrg(
    $orgId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAdultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AdultByOrg(orgId: $orgId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const adultQuery = /* GraphQL */ `
  query AdultQuery(
    $tenantId: ID
    $pinIsStaffMemberIsArchived: ModelAdultAdultQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AdultQuery(
      tenantId: $tenantId
      pinIsStaffMemberIsArchived: $pinIsStaffMemberIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdults = /* GraphQL */ `
  query SyncAdults($filter: ModelAdultFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncAdults(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      tenantId
      orgId
      firstName
      lastName
      dateOfBirth
      isDateOfBirthEstimated
      startDate
      isStartDateEstimated
      endDate
      isEndDateEstimated
      hasSubsidy
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      siblings {
        items {
          id
          tenantId
          ownerId
          siblingId
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      siblingTo {
        items {
          id
          tenantId
          ownerId
          siblingId
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      parents {
        items {
          id
          tenantId
          adultId
          studentId
          rel
          relType
          attendance
          billing
          billingAmount
          billingType
          isPrimary
          relDetails
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      timeSheets {
        items {
          id
          orgId
          tenantId
          billTo
          studentId
          adultId
          action
          actionDetails
          dateTime
          timeStamp
          date
          time
          isBestGuess
          isManualEntry
          manualEntryReason
          isIgnored
          isIgnoredReason
          hasSubsidy
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      schedules {
        items {
          id
          tenantId
          studentId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      plannedAbsences {
        items {
          id
          tenantId
          studentId
          startDate
          endDate
          reason
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      emergencyContacts {
        items {
          id
          tenantId
          studentId
          contactType
          adultId
          adHocContactId
          relationship
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents($filter: ModelStudentFilterInput, $limit: Int, $nextToken: String) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentByTenant = /* GraphQL */ `
  query StudentByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentByOrg = /* GraphQL */ `
  query StudentByOrg(
    $orgId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentByOrg(orgId: $orgId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentQuery = /* GraphQL */ `
  query StudentQuery(
    $tenantId: ID
    $dateOfBirthStartDateHasSubsidyIsArchived: ModelStudentStudentQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentQuery(
      tenantId: $tenantId
      dateOfBirthStartDateHasSubsidyIsArchived: $dateOfBirthStartDateHasSubsidyIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudents = /* GraphQL */ `
  query SyncStudents($filter: ModelStudentFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncStudents(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getRelative = /* GraphQL */ `
  query GetRelative($id: ID!) {
    getRelative(id: $id) {
      id
      tenantId
      ownerId
      relativeId
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      relative {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listRelatives = /* GraphQL */ `
  query ListRelatives($filter: ModelRelativeFilterInput, $limit: Int, $nextToken: String) {
    listRelatives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        ownerId
        relativeId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        relative {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const relativeByRelative = /* GraphQL */ `
  query RelativeByRelative(
    $relativeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRelativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelativeByRelative(
      relativeId: $relativeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        ownerId
        relativeId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        relative {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const relativeByOwner = /* GraphQL */ `
  query RelativeByOwner(
    $ownerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRelativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelativeByOwner(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        ownerId
        relativeId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        relative {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const relativeQuery = /* GraphQL */ `
  query RelativeQuery(
    $tenantId: ID
    $ownerIdRelativeIdIsArchived: ModelRelativeRelativeQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRelativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelativeQuery(
      tenantId: $tenantId
      ownerIdRelativeIdIsArchived: $ownerIdRelativeIdIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        ownerId
        relativeId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        relative {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRelatives = /* GraphQL */ `
  query SyncRelatives($filter: ModelRelativeFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncRelatives(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        ownerId
        relativeId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        relative {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getSibling = /* GraphQL */ `
  query GetSibling($id: ID!) {
    getSibling(id: $id) {
      id
      tenantId
      ownerId
      siblingId
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      owner {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      sibling {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listSiblings = /* GraphQL */ `
  query ListSiblings($filter: ModelSiblingFilterInput, $limit: Int, $nextToken: String) {
    listSiblings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        ownerId
        siblingId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sibling {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const siblingBySibling = /* GraphQL */ `
  query SiblingBySibling(
    $siblingId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSiblingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SiblingBySibling(
      siblingId: $siblingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        ownerId
        siblingId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sibling {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const siblingByOwner = /* GraphQL */ `
  query SiblingByOwner(
    $ownerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSiblingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SiblingByOwner(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        ownerId
        siblingId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sibling {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const siblingQuery = /* GraphQL */ `
  query SiblingQuery(
    $tenantId: ID
    $ownerIdSiblingIdIsArchived: ModelSiblingSiblingQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiblingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SiblingQuery(
      tenantId: $tenantId
      ownerIdSiblingIdIsArchived: $ownerIdSiblingIdIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        ownerId
        siblingId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sibling {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSiblings = /* GraphQL */ `
  query SyncSiblings($filter: ModelSiblingFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncSiblings(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        ownerId
        siblingId
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        owner {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        sibling {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getRelationship = /* GraphQL */ `
  query GetRelationship($id: ID!) {
    getRelationship(id: $id) {
      id
      tenantId
      adultId
      studentId
      rel
      relType
      attendance
      billing
      billingAmount
      billingType
      isPrimary
      relDetails
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adult {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      student {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listRelationships = /* GraphQL */ `
  query ListRelationships($filter: ModelRelationshipFilterInput, $limit: Int, $nextToken: String) {
    listRelationships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        adultId
        studentId
        rel
        relType
        attendance
        billing
        billingAmount
        billingType
        isPrimary
        relDetails
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const relByAdult = /* GraphQL */ `
  query RelByAdult(
    $adultId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelByAdult(
      adultId: $adultId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        studentId
        rel
        relType
        attendance
        billing
        billingAmount
        billingType
        isPrimary
        relDetails
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const relByStudent = /* GraphQL */ `
  query RelByStudent(
    $studentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelByStudent(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        studentId
        rel
        relType
        attendance
        billing
        billingAmount
        billingType
        isPrimary
        relDetails
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const relByTAAI = /* GraphQL */ `
  query RelByTAAI(
    $tenantId: ID
    $adultIdAttendanceIsArchived: ModelRelationshipRelByTAAICompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelByTAAI(
      tenantId: $tenantId
      adultIdAttendanceIsArchived: $adultIdAttendanceIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        studentId
        rel
        relType
        attendance
        billing
        billingAmount
        billingType
        isPrimary
        relDetails
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const relByTSAI = /* GraphQL */ `
  query RelByTSAI(
    $tenantId: ID
    $studentIdAttendanceIsArchived: ModelRelationshipRelByTSAICompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelByTSAI(
      tenantId: $tenantId
      studentIdAttendanceIsArchived: $studentIdAttendanceIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        studentId
        rel
        relType
        attendance
        billing
        billingAmount
        billingType
        isPrimary
        relDetails
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const relQuery = /* GraphQL */ `
  query RelQuery(
    $tenantId: ID
    $adultIdStudentIdAttendanceBillingIsArchived: ModelRelationshipRelQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelQuery(
      tenantId: $tenantId
      adultIdStudentIdAttendanceBillingIsArchived: $adultIdStudentIdAttendanceBillingIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        studentId
        rel
        relType
        attendance
        billing
        billingAmount
        billingType
        isPrimary
        relDetails
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRelationships = /* GraphQL */ `
  query SyncRelationships(
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRelationships(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        adultId
        studentId
        rel
        relType
        attendance
        billing
        billingAmount
        billingType
        isPrimary
        relDetails
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      orgId
      tenantId
      label
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        tenantId
        label
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const tagsByOrg = /* GraphQL */ `
  query TagsByOrg(
    $orgId: ID
    $tenantId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TagsByOrg(
      orgId: $orgId
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        label
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const tagsByLabel = /* GraphQL */ `
  query TagsByLabel(
    $tenantId: ID
    $label: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TagsByLabel(
      tenantId: $tenantId
      label: $label
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        label
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTags = /* GraphQL */ `
  query SyncTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncTags(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        orgId
        tenantId
        label
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTimesheetTag = /* GraphQL */ `
  query GetTimesheetTag($id: ID!) {
    getTimesheetTag(id: $id) {
      id
      orgId
      tenantId
      tagId
      attendanceTimesheetId
      payrollTimesheetId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      tag {
        id
        orgId
        tenantId
        label
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listTimesheetTags = /* GraphQL */ `
  query ListTimesheetTags($filter: ModelTimesheetTagFilterInput, $limit: Int, $nextToken: String) {
    listTimesheetTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        tenantId
        tagId
        attendanceTimesheetId
        payrollTimesheetId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tag {
          id
          orgId
          tenantId
          label
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const byTag = /* GraphQL */ `
  query ByTag(
    $tagId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTimesheetTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ByTag(tagId: $tagId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        tenantId
        tagId
        attendanceTimesheetId
        payrollTimesheetId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tag {
          id
          orgId
          tenantId
          label
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTimesheetTags = /* GraphQL */ `
  query SyncTimesheetTags(
    $filter: ModelTimesheetTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTimesheetTags(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        orgId
        tenantId
        tagId
        attendanceTimesheetId
        payrollTimesheetId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tag {
          id
          orgId
          tenantId
          label
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAttendanceTimesheet = /* GraphQL */ `
  query GetAttendanceTimesheet($id: ID!) {
    getAttendanceTimesheet(id: $id) {
      id
      orgId
      tenantId
      billTo
      studentId
      adultId
      action
      actionDetails
      dateTime
      timeStamp
      date
      time
      isBestGuess
      isManualEntry
      manualEntryReason
      isIgnored
      isIgnoredReason
      hasSubsidy
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      tags {
        items {
          id
          orgId
          tenantId
          tagId
          attendanceTimesheetId
          payrollTimesheetId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listAttendanceTimesheets = /* GraphQL */ `
  query ListAttendanceTimesheets($filter: ModelAttendanceTimesheetFilterInput, $limit: Int, $nextToken: String) {
    listAttendanceTimesheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const attendanceByStudent = /* GraphQL */ `
  query AttendanceByStudent(
    $studentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceByStudent(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const attendanceByTenant = /* GraphQL */ `
  query AttendanceByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const attendanceByTenantByDate = /* GraphQL */ `
  query AttendanceByTenantByDate(
    $tenantId: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceByTenantByDate(
      tenantId: $tenantId
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const attendanceBillingQuery = /* GraphQL */ `
  query AttendanceBillingQuery(
    $billTo: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceBillingQuery(
      billTo: $billTo
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const attendanceStudentQuery = /* GraphQL */ `
  query AttendanceStudentQuery(
    $studentId: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceStudentQuery(
      studentId: $studentId
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAttendanceTimesheets = /* GraphQL */ `
  query SyncAttendanceTimesheets(
    $filter: ModelAttendanceTimesheetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttendanceTimesheets(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getPayrollTimesheet = /* GraphQL */ `
  query GetPayrollTimesheet($id: ID!) {
    getPayrollTimesheet(id: $id) {
      id
      orgId
      tenantId
      billTo
      adultId
      action
      actionDetails
      dateTime
      timeStamp
      date
      time
      isBestGuess
      isManualEntry
      manualEntryReason
      isIgnored
      isIgnoredReason
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adult {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      tags {
        items {
          id
          orgId
          tenantId
          tagId
          attendanceTimesheetId
          payrollTimesheetId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listPayrollTimesheets = /* GraphQL */ `
  query ListPayrollTimesheets($filter: ModelPayrollTimesheetFilterInput, $limit: Int, $nextToken: String) {
    listPayrollTimesheets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const payrollByAdult = /* GraphQL */ `
  query PayrollByAdult(
    $adultId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPayrollTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PayrollByAdult(
      adultId: $adultId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const payrollByTenant = /* GraphQL */ `
  query PayrollByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPayrollTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PayrollByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const payrollByTenantByDate = /* GraphQL */ `
  query PayrollByTenantByDate(
    $tenantId: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayrollTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PayrollByTenantByDate(
      tenantId: $tenantId
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const payrollBillingQuery = /* GraphQL */ `
  query PayrollBillingQuery(
    $billTo: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayrollTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PayrollBillingQuery(
      billTo: $billTo
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const payrollStaffQuery = /* GraphQL */ `
  query PayrollStaffQuery(
    $adultId: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayrollTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PayrollStaffQuery(
      adultId: $adultId
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPayrollTimesheets = /* GraphQL */ `
  query SyncPayrollTimesheets(
    $filter: ModelPayrollTimesheetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayrollTimesheets(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudentDay = /* GraphQL */ `
  query GetStudentDay($id: ID!) {
    getStudentDay(id: $id) {
      id
      tenantId
      scheduleId
      order
      label
      timeStart
      timeEnd
      isSelected
      classroomId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      classroom {
        id
        orgId
        tenantId
        name
        nickName
        ratio
        minMonths
        maxMonths
        maxSize
        isAdmin
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tenant {
          id
          orgId
          name
          address
          address2
          city
          zip
          state
          mobile
          phone
          email
          notifyOnTimesheetAdd
          billPayrollToHomeCenter
          billAttendanceToHomeCenter
          timeZone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        studentDays {
          nextToken
          startedAt
        }
        staffDays {
          nextToken
          startedAt
        }
      }
      schedule {
        id
        tenantId
        studentId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listStudentDays = /* GraphQL */ `
  query ListStudentDays($filter: ModelStudentDayFilterInput, $limit: Int, $nextToken: String) {
    listStudentDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          studentId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentDayBySchedule = /* GraphQL */ `
  query StudentDayBySchedule(
    $scheduleId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentDayBySchedule(
      scheduleId: $scheduleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          studentId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentDayByClassroom = /* GraphQL */ `
  query StudentDayByClassroom(
    $tenantId: ID
    $classroomId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentDayByClassroom(
      tenantId: $tenantId
      classroomId: $classroomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          studentId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentDayQuery = /* GraphQL */ `
  query StudentDayQuery(
    $tenantId: ID
    $classroomIdScheduleIdIsSelected: ModelStudentDayStudentDayQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentDayQuery(
      tenantId: $tenantId
      classroomIdScheduleIdIsSelected: $classroomIdScheduleIdIsSelected
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          studentId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudentDays = /* GraphQL */ `
  query SyncStudentDays($filter: ModelStudentDayFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncStudentDays(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          studentId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getStaffDay = /* GraphQL */ `
  query GetStaffDay($id: ID!) {
    getStaffDay(id: $id) {
      id
      tenantId
      scheduleId
      order
      label
      timeStart
      timeEnd
      isSelected
      classroomId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      classroom {
        id
        orgId
        tenantId
        name
        nickName
        ratio
        minMonths
        maxMonths
        maxSize
        isAdmin
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        tenant {
          id
          orgId
          name
          address
          address2
          city
          zip
          state
          mobile
          phone
          email
          notifyOnTimesheetAdd
          billPayrollToHomeCenter
          billAttendanceToHomeCenter
          timeZone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        studentDays {
          nextToken
          startedAt
        }
        staffDays {
          nextToken
          startedAt
        }
      }
      schedule {
        id
        tenantId
        adultId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listStaffDays = /* GraphQL */ `
  query ListStaffDays($filter: ModelStaffDayFilterInput, $limit: Int, $nextToken: String) {
    listStaffDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          adultId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffDayBySchedule = /* GraphQL */ `
  query StaffDayBySchedule(
    $scheduleId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStaffDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffDayBySchedule(
      scheduleId: $scheduleId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          adultId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffDayByClassroom = /* GraphQL */ `
  query StaffDayByClassroom(
    $tenantId: ID
    $classroomId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffDayByClassroom(
      tenantId: $tenantId
      classroomId: $classroomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          adultId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffDayQuery = /* GraphQL */ `
  query StaffDayQuery(
    $tenantId: ID
    $classroomIdScheduleIdIsSelected: ModelStaffDayStaffDayQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffDayQuery(
      tenantId: $tenantId
      classroomIdScheduleIdIsSelected: $classroomIdScheduleIdIsSelected
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          adultId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStaffDays = /* GraphQL */ `
  query SyncStaffDays($filter: ModelStaffDayFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncStaffDays(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        scheduleId
        order
        label
        timeStart
        timeEnd
        isSelected
        classroomId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        classroom {
          id
          orgId
          tenantId
          name
          nickName
          ratio
          minMonths
          maxMonths
          maxSize
          isAdmin
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          id
          tenantId
          adultId
          date
          isArchived
          isActive
          isTemporary
          expiryDate
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudentSchedule = /* GraphQL */ `
  query GetStudentSchedule($id: ID!) {
    getStudentSchedule(id: $id) {
      id
      tenantId
      studentId
      date
      isArchived
      isActive
      isTemporary
      expiryDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      schedule {
        items {
          id
          tenantId
          scheduleId
          order
          label
          timeStart
          timeEnd
          isSelected
          classroomId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listStudentSchedules = /* GraphQL */ `
  query ListStudentSchedules($filter: ModelStudentScheduleFilterInput, $limit: Int, $nextToken: String) {
    listStudentSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        studentId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const scheduleByStudent = /* GraphQL */ `
  query ScheduleByStudent(
    $studentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ScheduleByStudent(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentScheduleByDate = /* GraphQL */ `
  query StudentScheduleByDate(
    $tenantId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentScheduleByDate(
      tenantId: $tenantId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentScheduleQuery = /* GraphQL */ `
  query StudentScheduleQuery(
    $tenantId: ID
    $studentIdDateIsArchived: ModelStudentScheduleStudentScheduleQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentScheduleQuery(
      tenantId: $tenantId
      studentIdDateIsArchived: $studentIdDateIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentScheduleQ = /* GraphQL */ `
  query StudentScheduleQ(
    $tenantId: ID
    $studentIdIsArchived: ModelStudentScheduleStudentScheduleQCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentScheduleQ(
      tenantId: $tenantId
      studentIdIsArchived: $studentIdIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudentSchedules = /* GraphQL */ `
  query SyncStudentSchedules(
    $filter: ModelStudentScheduleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentSchedules(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        studentId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getStaffSchedule = /* GraphQL */ `
  query GetStaffSchedule($id: ID!) {
    getStaffSchedule(id: $id) {
      id
      tenantId
      adultId
      date
      isArchived
      isActive
      isTemporary
      expiryDate
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adult {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      schedule {
        items {
          id
          tenantId
          scheduleId
          order
          label
          timeStart
          timeEnd
          isSelected
          classroomId
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listStaffSchedules = /* GraphQL */ `
  query ListStaffSchedules($filter: ModelStaffScheduleFilterInput, $limit: Int, $nextToken: String) {
    listStaffSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        adultId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const scheduleByAdult = /* GraphQL */ `
  query ScheduleByAdult(
    $adultId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ScheduleByAdult(
      adultId: $adultId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffScheduleByDate = /* GraphQL */ `
  query StaffScheduleByDate(
    $tenantId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffScheduleByDate(
      tenantId: $tenantId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffScheduleQuery = /* GraphQL */ `
  query StaffScheduleQuery(
    $tenantId: ID
    $adultIdDateIsArchived: ModelStaffScheduleStaffScheduleQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffScheduleQuery(
      tenantId: $tenantId
      adultIdDateIsArchived: $adultIdDateIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffScheduleQ = /* GraphQL */ `
  query StaffScheduleQ(
    $tenantId: ID
    $adultIdIsArchived: ModelStaffScheduleStaffScheduleQCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffScheduleQ(
      tenantId: $tenantId
      adultIdIsArchived: $adultIdIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStaffSchedules = /* GraphQL */ `
  query SyncStaffSchedules(
    $filter: ModelStaffScheduleFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStaffSchedules(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        adultId
        date
        isArchived
        isActive
        isTemporary
        expiryDate
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        schedule {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudentPlannedAbsence = /* GraphQL */ `
  query GetStudentPlannedAbsence($id: ID!) {
    getStudentPlannedAbsence(id: $id) {
      id
      tenantId
      studentId
      startDate
      endDate
      reason
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      student {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listStudentPlannedAbsences = /* GraphQL */ `
  query ListStudentPlannedAbsences($filter: ModelStudentPlannedAbsenceFilterInput, $limit: Int, $nextToken: String) {
    listStudentPlannedAbsences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        studentId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const absenceByStudent = /* GraphQL */ `
  query AbsenceByStudent(
    $studentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentPlannedAbsenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AbsenceByStudent(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentAbsenceUpcoming = /* GraphQL */ `
  query StudentAbsenceUpcoming(
    $tenantId: ID
    $studentIdStartDateEndDateIsArchived: ModelStudentPlannedAbsenceStudentAbsenceUpcomingCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentPlannedAbsenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentAbsenceUpcoming(
      tenantId: $tenantId
      studentIdStartDateEndDateIsArchived: $studentIdStartDateEndDateIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const studentAbsenceQuery = /* GraphQL */ `
  query StudentAbsenceQuery(
    $tenantId: ID
    $studentIdStartDateEndDateIsArchived: ModelStudentPlannedAbsenceStudentAbsenceQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentPlannedAbsenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentAbsenceQuery(
      tenantId: $tenantId
      studentIdStartDateEndDateIsArchived: $studentIdStartDateEndDateIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudentPlannedAbsences = /* GraphQL */ `
  query SyncStudentPlannedAbsences(
    $filter: ModelStudentPlannedAbsenceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentPlannedAbsences(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        studentId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getStaffPlannedAbsence = /* GraphQL */ `
  query GetStaffPlannedAbsence($id: ID!) {
    getStaffPlannedAbsence(id: $id) {
      id
      tenantId
      adultId
      startDate
      endDate
      reason
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adult {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listStaffPlannedAbsences = /* GraphQL */ `
  query ListStaffPlannedAbsences($filter: ModelStaffPlannedAbsenceFilterInput, $limit: Int, $nextToken: String) {
    listStaffPlannedAbsences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        adultId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const absenceByAdult = /* GraphQL */ `
  query AbsenceByAdult(
    $adultId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStaffPlannedAbsenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AbsenceByAdult(
      adultId: $adultId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffAbsenceUpcoming = /* GraphQL */ `
  query StaffAbsenceUpcoming(
    $tenantId: ID
    $adultIdStartDateEndDateIsArchived: ModelStaffPlannedAbsenceStaffAbsenceUpcomingCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffPlannedAbsenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffAbsenceUpcoming(
      tenantId: $tenantId
      adultIdStartDateEndDateIsArchived: $adultIdStartDateEndDateIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const staffAbsenceQuery = /* GraphQL */ `
  query StaffAbsenceQuery(
    $tenantId: ID
    $adultIdStartDateEndDateIsArchived: ModelStaffPlannedAbsenceStaffAbsenceQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffPlannedAbsenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffAbsenceQuery(
      tenantId: $tenantId
      adultIdStartDateEndDateIsArchived: $adultIdStartDateEndDateIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStaffPlannedAbsences = /* GraphQL */ `
  query SyncStaffPlannedAbsences(
    $filter: ModelStaffPlannedAbsenceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStaffPlannedAbsences(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        adultId
        startDate
        endDate
        reason
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      tenantId
      studentId
      contactType
      adultId
      adHocContactId
      relationship
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adult {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      student {
        id
        tenantId
        orgId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        hasSubsidy
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        siblings {
          nextToken
          startedAt
        }
        siblingTo {
          nextToken
          startedAt
        }
        parents {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      adHocContact {
        id
        tenantId
        contactId
        firstName
        lastName
        mobile
        homePhone
        workPhone
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts($filter: ModelContactFilterInput, $limit: Int, $nextToken: String) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        studentId
        contactType
        adultId
        adHocContactId
        relationship
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        adHocContact {
          id
          tenantId
          contactId
          firstName
          lastName
          mobile
          homePhone
          workPhone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const conByStudent = /* GraphQL */ `
  query ConByStudent(
    $tenantId: ID
    $studentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ConByStudent(
      tenantId: $tenantId
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        contactType
        adultId
        adHocContactId
        relationship
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        adHocContact {
          id
          tenantId
          contactId
          firstName
          lastName
          mobile
          homePhone
          workPhone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncContacts = /* GraphQL */ `
  query SyncContacts($filter: ModelContactFilterInput, $limit: Int, $nextToken: String, $lastSync: AWSTimestamp) {
    syncContacts(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        studentId
        contactType
        adultId
        adHocContactId
        relationship
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        student {
          id
          tenantId
          orgId
          firstName
          lastName
          dateOfBirth
          isDateOfBirthEstimated
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          hasSubsidy
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        adHocContact {
          id
          tenantId
          contactId
          firstName
          lastName
          mobile
          homePhone
          workPhone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdultContact = /* GraphQL */ `
  query GetAdultContact($id: ID!) {
    getAdultContact(id: $id) {
      id
      tenantId
      adultId
      adHocContactId
      relationship
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      adult {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        relatives {
          nextToken
          startedAt
        }
        relatedTo {
          nextToken
          startedAt
        }
        children {
          nextToken
          startedAt
        }
        timeSheets {
          nextToken
          startedAt
        }
        schedules {
          nextToken
          startedAt
        }
        plannedAbsences {
          nextToken
          startedAt
        }
        emergencyContacts {
          nextToken
          startedAt
        }
      }
      adHocContact {
        id
        tenantId
        contactId
        firstName
        lastName
        mobile
        homePhone
        workPhone
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const listAdultContacts = /* GraphQL */ `
  query ListAdultContacts($filter: ModelAdultContactFilterInput, $limit: Int, $nextToken: String) {
    listAdultContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        adultId
        adHocContactId
        relationship
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        adHocContact {
          id
          tenantId
          contactId
          firstName
          lastName
          mobile
          homePhone
          workPhone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const conByAdult = /* GraphQL */ `
  query ConByAdult(
    $tenantId: ID
    $adultId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdultContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ConByAdult(
      tenantId: $tenantId
      adultId: $adultId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        adHocContactId
        relationship
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        adHocContact {
          id
          tenantId
          contactId
          firstName
          lastName
          mobile
          homePhone
          workPhone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAdultContacts = /* GraphQL */ `
  query SyncAdultContacts(
    $filter: ModelAdultContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAdultContacts(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        adultId
        adHocContactId
        relationship
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
          tenantId
          orgId
          userId
          startDate
          isStartDateEstimated
          endDate
          isEndDateEstimated
          isLocked
          lockReason
          pin
          firstName
          lastName
          email
          mobile
          dateOfBirth
          homePhone
          workPhone
          address
          address2
          city
          state
          zip
          didSendInvite
          isStaffMember
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        adHocContact {
          id
          tenantId
          contactId
          firstName
          lastName
          mobile
          homePhone
          workPhone
          isArchived
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAddHocContact = /* GraphQL */ `
  query GetAddHocContact($id: ID!) {
    getAddHocContact(id: $id) {
      id
      tenantId
      contactId
      firstName
      lastName
      mobile
      homePhone
      workPhone
      isArchived
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAddHocContacts = /* GraphQL */ `
  query ListAddHocContacts($filter: ModelAddHocContactFilterInput, $limit: Int, $nextToken: String) {
    listAddHocContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        contactId
        firstName
        lastName
        mobile
        homePhone
        workPhone
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const addHocContacts = /* GraphQL */ `
  query AddHocContacts(
    $tenantId: ID
    $contactIdIsArchived: ModelAddHocContactAddHocContactsCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAddHocContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AddHocContacts(
      tenantId: $tenantId
      contactIdIsArchived: $contactIdIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        contactId
        firstName
        lastName
        mobile
        homePhone
        workPhone
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAddHocContacts = /* GraphQL */ `
  query SyncAddHocContacts(
    $filter: ModelAddHocContactFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAddHocContacts(filter: $filter, limit: $limit, nextToken: $nextToken, lastSync: $lastSync) {
      items {
        id
        tenantId
        contactId
        firstName
        lastName
        mobile
        homePhone
        workPhone
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
