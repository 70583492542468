import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class InputSelect extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    value: PropTypes.string,
    labelText: PropTypes.string,
    hintText: PropTypes.string,
    isButton: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    labelText: null,
    hintText: null,
    isButton: false,
    isDisabled: false,
    isRequired: false,
    onChange: null,
    value: undefined,
  };

  render() {
    const { id, value, options, labelText, hintText, isButton, isDisabled, isRequired, onChange } = this.props;
    const classNames = ['form-element', 'form-element--select'];
    if (isButton) classNames.push('button');
    if (isDisabled) classNames.push('form-element--disabled');
    const opts = options.map((it) => (
      <option value={it.value} key={it.value}>
        {it.label}
      </option>
    ));
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <div className={classNames.join(' ')}>
        <label className="label" htmlFor={id}>
          {labelText}
          {isRequired && <span className="required">*</span>}
        </label>
        {hintText && (
          <span id={`${id}-hint`} className="form-element-hint">
            {hintText}
          </span>
        )}
        <select id={id} name={id} onChange={onChange} value={value} disabled={isDisabled} required={isRequired}>
          {opts}
        </select>
      </div>
    );
    /* eslint-enable */
  }
}

export default InputSelect;
