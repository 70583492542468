import PropTypes from 'prop-types';
import { Logo } from 'components/graphic';

function Header(props) {
  const { children, classNames, includeHomeLink } = props;
  return (
    <header className={classNames?.join(' ')}>
      <div className="wrapper--center">
        <Logo includeHomeLink={includeHomeLink} />
        {children}
      </div>
    </header>
  );
}

Header.propTypes = {
  children: PropTypes.node,
  includeHomeLink: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
};

Header.defaultProps = {
  children: null,
  includeHomeLink: true,
  classNames: ['header--global'],
};

export default Header;
