import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { DateTime } from 'luxon';
import { useStudentListContext } from 'context/Student/StudentListContext';
import { useAdultListContext } from 'context/Adult/AdultListContext';
import { useTimesheetMutateContext } from 'context/Timesheet/TimesheetMutateContext';
import { Modal } from 'components/modal';
import { AddTimesheetEntryForm } from 'components/form';
import { REPORT_TYPE_ATTENDANCE, TimesheetAction, StringBoolean } from 'constants/index';
import conf from 'conf/index';

const doAddTimesheet = async (data, getStudent, getAdult, createFn) => {
  const { partyId, date, time, startTime, endTime, action, actionDetails, manualEntryReason, type, tenantId, billTo } =
    data;
  const member = type === REPORT_TYPE_ATTENDANCE ? getStudent(partyId) : getAdult(partyId);
  const { hasSubsidy } = member;
  const year = date.year();
  const month = date.month() + 1;
  const day = date.date();
  const commonData = {
    tenantId,
    billTo,
    [type === REPORT_TYPE_ATTENDANCE ? 'studentId' : 'adultId']: partyId,
    actionDetails,
    isBestGuess: false,
    isManualEntry: true,
    manualEntryReason,
    isIgnored: StringBoolean.FALSE.value,
    isIgnoredReason: null,
    ...(type === REPORT_TYPE_ATTENDANCE ? { hasSubsidy } : {}),
  };
  const submitData = [];
  if (startTime && endTime && action === TimesheetAction.OTHER.value) {
    const [sHour, sMinute] = startTime.split(':');
    const [eHour, eMinute] = endTime.split(':');
    const start = DateTime.fromObject(
      {
        year,
        month,
        day,
        hour: parseInt(sHour, 10),
        minute: parseInt(sMinute, 10),
      },
      {
        zone: conf.timeZone,
      }
    );
    const end = DateTime.fromObject(
      {
        year,
        month,
        day,
        hour: parseInt(eHour, 10),
        minute: parseInt(eMinute, 10),
      },
      {
        zone: conf.timeZone,
      }
    );
    const sUTC = start.toUTC();
    const eUTC = end.toUTC();
    submitData.push({
      ...commonData,
      action: TimesheetAction.CHECKED_IN.value,
      date: null,
      time: null,
      dateTime: sUTC.toISO(),
      timeStamp: Math.floor(sUTC.toSeconds()),
    });
    submitData.push({
      ...commonData,
      action: TimesheetAction.CHECKED_OUT.value,
      date: null,
      time: null,
      dateTime: eUTC.toISO(),
      timeStamp: Math.floor(eUTC.toSeconds()),
    });
  } else {
    const [hour, minute] = time.split(':');
    const dt = DateTime.fromObject(
      {
        year,
        month,
        day,
        hour: parseInt(hour, 10),
        minute: parseInt(minute, 10),
      },
      {
        zone: conf.timeZone,
      }
    );
    const dtUTC = dt.toUTC();
    const result = {
      ...commonData,
      action,
      date: null,
      time: null,
      dateTime: dtUTC.toISO(),
      timeStamp: Math.floor(dtUTC.toSeconds()),
    };
    submitData.push(result);
  }
  // console.log(type, submitData);
  await createFn(type, submitData);
};

function AddTimesheetEntry(props) {
  const { hide, isShowing, isInline, initialData } = props;
  const { getStudent } = useStudentListContext();
  const { getAdult } = useAdultListContext();
  const { doCreate, payrollCreate, attendanceCreate } = useTimesheetMutateContext();
  const isLoading = payrollCreate.isLoading || attendanceCreate.isLoading;
  const isError = payrollCreate.isError || attendanceCreate.isError;
  const error = payrollCreate.error || attendanceCreate.error;

  if (!isShowing) {
    document.body.classList.remove('has-modal');
  }

  return isShowing
    ? ReactDOM.createPortal(
        <Modal isShowing={isShowing} modalTitle="Add Missing Timesheet Entry" onClose={() => hide()}>
          <AddTimesheetEntryForm
            doSubmit={async (data) => {
              await doAddTimesheet(data, getStudent, getAdult, doCreate);
              if (!isError) hide();
            }}
            isLoading={isLoading}
            isError={isError}
            error={error}
            doCancel={() => hide()}
            initialData={initialData}
            isInline={isInline}
          />
        </Modal>,
        document.body
      )
    : null;
}

AddTimesheetEntry.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    date: momentPropTypes.momentObj,
    partyId: PropTypes.string,
    type: PropTypes.string,
  }),
  isInline: PropTypes.bool,
};

AddTimesheetEntry.defaultProps = {
  isInline: false,
  initialData: {},
};

export default AddTimesheetEntry;
