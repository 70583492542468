import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import { isInclusivelyAfterDay } from 'react-dates';
import { useAuthContext } from 'context/AuthContext';
import { useTenantContext } from 'context/Tenant/TenantContext';
import { useAdultListContext } from 'context/Adult/AdultListContext';
import { useStudentListContext } from 'context/Student/StudentListContext';
import { Button, ActingSubmitButton } from 'components/buttons';
import { InputSelect, InputTime, InputText, AdultSelect, StudentSelect } from 'components/formElements';
import { CenterSelect } from 'components/formElements/CenterSelect';
import { Form, Error } from 'components/layout';
import { SingleDatePickerWrapper } from 'components/dates';
import { insertIf, reportTypeOptions } from 'utils/index';
import { TYPE_STUDENT, OPTIONS_TS_ACTIONS, TimesheetAction } from 'constants/index';

const defaultState = {
  billTo: null,
  tenantId: null,
  partyId: '',
  date: null,
  time: '',
  startTime: '',
  endTime: '',
  action: '',
  actionDetails: '',
  isManualEntry: true,
  type: '',
  manualEntryReason: '',
};

function AddTimesheetEntryForm(props) {
  const { orgId, tenantId: tId } = useAuthContext();
  const { orgHasMultipleCenters } = useTenantContext();
  const { getOrgStaffMembers } = useAdultListContext();
  const { getOrgStudents } = useStudentListContext();
  const { doSubmit, isLoading, isError, error, doCancel, isInline, initialData } = props;
  const [values, setValues] = useState({
    ...defaultState,
    ...initialData,
    orgId,
    tenantId: tId,
    billTo: tId,
  });
  const datePickerRef = useRef(null);

  const { partyId, type, time, action, actionDetails, startTime, endTime, manualEntryReason, date, tenantId, billTo } =
    values;

  const onSubmit = async () => {
    await doSubmit(values);
  };

  const onFieldChange = (e) => {
    const { id, value } = e.target;

    const newValues = {
      ...values,
      [id]: value,
      // reset action details, start time, end time, if action IS NOT OTHER
      ...insertIf(id === 'action' && value !== TimesheetAction.OTHER.value, 'actionDetails', ''),
      ...insertIf(id === 'action' && value !== TimesheetAction.OTHER.value, 'startTime', ''),
      ...insertIf(id === 'action' && value !== TimesheetAction.OTHER.value, 'endTime', ''),
      // reset time if action IS OTHER
      ...insertIf(id === 'action' && value === TimesheetAction.OTHER.value, 'time', ''),
      ...insertIf(
        id === 'partyId' && type === TYPE_STUDENT,
        'billTo',
        getOrgStudents.find((it) => it.id === value)?.tenantId
      ),
      ...insertIf(
        id === 'partyId' && type !== TYPE_STUDENT,
        'billTo',
        getOrgStaffMembers.find((it) => it.id === value)?.tenantId
      ),
    };

    setValues(newValues);
  };

  const onDateChange = (fieldId, { date: newDate }) => {
    if (newDate === undefined) return;
    setValues({
      ...values,
      [fieldId]: newDate,
    });
  };

  const reset = () => {
    setValues(defaultState);
  };

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Save Entry',
    actionText: 'Saving...',
    isActing: isLoading,
  };

  return (
    <Form onSubmit={onSubmit} className="form--missing-entry">
      {isError && (
        <Error
          error={error.errors.reduce((acc, nx) => `${acc ? `${acc}, ` : ``} ${nx.message}`, '')}
          className="form-error-msg"
        />
      )}
      <fieldset className="fieldset" disabled={isLoading}>
        <div className="wrapper--flex">
          <InputSelect
            id="type"
            labelText="Entry type"
            options={reportTypeOptions}
            value={type}
            onChange={onFieldChange}
            isDisabled={isInline}
            isRequired
          />
          {type === TYPE_STUDENT ? (
            <StudentSelect
              id="partyId"
              labelText="Name"
              value={partyId}
              onChange={onFieldChange}
              isDisabled={!type || isInline}
              isRequired
              includeOrgStudents
              // showInactiveParties
            />
          ) : (
            <AdultSelect
              id="partyId"
              labelText="Name"
              value={partyId}
              onChange={onFieldChange}
              isDisabled={!type || isInline}
              isRequired
              filterStaffMembers
              includeOrgStaffMembers
              // showInactiveParties
            />
          )}
        </div>
        <div className="wrapper--flex">
          <SingleDatePickerWrapper
            id="date"
            labelText="Date"
            hintText="Please use this format: MM/DD/YYYY"
            placeholder={null}
            onClose={(d) => onDateChange('date', d)}
            onChange={(d) => onDateChange('date', d)}
            initialDate={date}
            isOutsideRange={(d) => isInclusivelyAfterDay(d, moment().add(1, 'days'))}
            displayFormat="MM/DD/Y"
            numberOfMonths={1}
            isRequired
            isDisabled={isInline}
            block
            ref={datePickerRef}
          />
          <InputSelect
            id="action"
            labelText="Action"
            options={OPTIONS_TS_ACTIONS}
            value={action}
            onChange={onFieldChange}
            isRequired
          />
        </div>
        {/* Show Time Input if Action is not 'Other' */}
        {action !== TimesheetAction.OTHER.value && (
          <div className="wrapper--flex">
            <InputTime id="time" labelText="Time" onChange={onFieldChange} value={time} isRequired />
          </div>
        )}
        {/* Otherwise show start time and end time */}
        {!!action && action === TimesheetAction.OTHER.value && (
          <div className="wrapper--flex">
            <InputTime id="startTime" labelText="Start Time" onChange={onFieldChange} value={startTime} isRequired />
            <InputTime id="endTime" labelText="End Time" onChange={onFieldChange} value={endTime} isRequired />
            <div>
              <InputText
                id="actionDetails"
                labelText="Action Details"
                value={actionDetails}
                onChange={onFieldChange}
                isRequired
              />
            </div>
          </div>
        )}
        <InputText
          id="manualEntryReason"
          labelText="Reason for Manual Entry"
          value={manualEntryReason}
          onChange={onFieldChange}
        />
        {orgHasMultipleCenters && (
          <>
            <CenterSelect
              id="tenantId"
              labelText="At which center was this entry recorded?"
              value={tenantId}
              onChange={onFieldChange}
              isRequired
            />
            <CenterSelect
              id="billTo"
              labelText="To which center should this entry be billed?"
              value={billTo}
              onChange={onFieldChange}
              isRequired
            />
          </>
        )}
      </fieldset>
      <div className="form-actions">
        <Button
          onClick={() => {
            reset();
            doCancel();
          }}
          className="button--text--cancel"
        >
          Cancel
        </Button>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
}

AddTimesheetEntryForm.propTypes = {
  doCancel: PropTypes.func.isRequired,
  doSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  isInline: PropTypes.bool,
  initialData: PropTypes.shape({
    date: momentPropTypes.momentObj,
    partyId: PropTypes.string,
    type: PropTypes.string,
  }),
  error: PropTypes.string,
};

AddTimesheetEntryForm.defaultProps = {
  isLoading: false,
  isInline: false,
  isError: false,
  initialData: {},
  error: null,
};

export default AddTimesheetEntryForm;
