import PropTypes from 'prop-types';

export default PropTypes.shape({
  refId: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  state: PropTypes.string,
  mobile: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
});
