import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import {
  createStaffDay,
  createStaffSchedule,
  updateStaffDay,
  updateStaffSchedule,
  createStudentDay,
  createStudentSchedule,
  updateStudentDay,
  updateStudentSchedule,
} from 'graphql/customMutations';
import { cacheKeys } from 'conf';
import { StringBoolean, TYPE_STAFF, TYPE_STUDENT } from 'constants/index';
import { boolToStringBoolean, getInterimId } from 'utils/index';
import { useAuthContext } from '../AuthContext';

const ScheduleMutateContext = React.createContext({
  doCreate: null,
  create: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    error: null,
  },
  doUpdate: null,
  update: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    error: null,
  },
  doDelete: null,
});

export function ScheduleMutateProvider({ children, partyType }) {
  const { id: partyId } = useParams();
  const { tenantId } = useAuthContext();
  const queryClient = useQueryClient();

  const isStaffMember = partyType === TYPE_STAFF;

  const batchCreate = async ({ schedule, days }) => {
    await API.graphql(
      graphqlOperation(isStaffMember ? createStaffSchedule : createStudentSchedule, { input: schedule })
    );
    const dayOps = days.map(async (s) => {
      const res = await API.graphql(graphqlOperation(isStaffMember ? createStaffDay : createStudentDay, { input: s }));
      return res;
    });

    const result = await Promise.allSettled(dayOps);
    return result;
  };

  const cacheKey = useMemo(
    () =>
      isStaffMember
        ? [cacheKeys.getStaffSchedule, partyId, tenantId]
        : [cacheKeys.getStudentSchedule, partyId, tenantId],
    [isStaffMember, partyId, tenantId]
  );

  const {
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
    isError: isCreateError,
    error: createError,
    mutateAsync: create,
  } = useMutation(batchCreate, {
    onSuccess: () => queryClient.invalidateQueries(cacheKey),
  });

  const doCreate = useCallback(
    async (data) => {
      const { date, schedule } = data;
      const scheduleId = getInterimId();
      const d = {
        schedule: {
          id: scheduleId,
          date,
          tenantId,
          ...(isStaffMember ? { adultId: partyId } : { studentId: partyId }),
          isArchived: StringBoolean.FALSE.value,
          isTemporary: false,
        },
        days: schedule.map((day) => ({
          ...day,
          tenantId,
          scheduleId,
          isSelected: boolToStringBoolean(day.isSelected),
          classroomId: day.classroomId || 'N/A',
        })),
      };
      await create(d);
      // console.log(d);
    },
    [create, isStaffMember, partyId, tenantId]
  );

  const batchUpdate = async ({ schedule, days }) => {
    if (schedule) {
      await API.graphql(
        graphqlOperation(isStaffMember ? updateStaffSchedule : updateStudentSchedule, { input: schedule })
      );
    }
    if (days) {
      const dayOps = days.map(async (s) => {
        const res = await API.graphql(
          graphqlOperation(isStaffMember ? updateStaffDay : updateStudentDay, { input: s })
        );
        return res;
      });

      await Promise.allSettled(dayOps);
    }
  };

  const {
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
    mutateAsync: update,
  } = useMutation(batchUpdate, {
    onSuccess: () => queryClient.invalidateQueries(cacheKey),
  });

  const doDelete = useCallback(
    async (data) => {
      const { schedule, dtObj, isCurrent, ...rest } = data;
      await update({
        schedule: {
          ...rest,
          isArchived: StringBoolean.TRUE.value,
        },
      });
    },
    [update]
  );

  const doUpdate = useCallback(
    async (data) => {
      const { id: scheduleId, date, isTemporary, schedule, dtObj, isCurrent, ...rest } = data;

      // we only change schedule if the date changes or they confirm a temporary schedule
      const d = {
        ...(date || !isTemporary
          ? {
              schedule: {
                id: scheduleId,
                ...rest,
                ...(date ? { date } : {}),
                ...(!isTemporary ? { isTemporary } : {}),
              },
            }
          : {}),
        days: schedule.map((day) => {
          const { classroom, classroomId, isSelected, ...dRest } = day;
          return {
            ...dRest,
            isSelected: boolToStringBoolean(isSelected),
            classroomId: classroomId || 'N/A',
            scheduleId,
          };
        }),
      };
      // console.log(d);
      await update(d);
    },
    [update]
  );

  const value = useMemo(
    () => ({
      doCreate,
      create: {
        isLoading: isCreateLoading,
        isError: isCreateError,
        isSuccess: isCreateSuccess,
        error: createError,
      },
      doUpdate,
      update: {
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        isSuccess: isUpdateSuccess,
        error: updateError,
      },
      doDelete,
    }),
    [
      createError,
      doCreate,
      doUpdate,
      doDelete,
      isCreateError,
      isCreateLoading,
      isCreateSuccess,
      isUpdateError,
      isUpdateLoading,
      isUpdateSuccess,
      updateError,
    ]
  );

  return <ScheduleMutateContext.Provider value={value}>{children}</ScheduleMutateContext.Provider>;
}

ScheduleMutateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  // partyId: PropTypes.string.isRequired,
  partyType: PropTypes.oneOf([TYPE_STUDENT, TYPE_STAFF]).isRequired,
};

export const useScheduleMutateContext = () => React.useContext(ScheduleMutateContext);
