import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class InputCheckbox extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    hintText: PropTypes.string,
    errorText: PropTypes.string,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    onChange: PropTypes.func,
    labelClassName: PropTypes.string,
  };

  static defaultProps = {
    onChange: null,
    labelClassName: 'label',
    hintText: '',
    errorText: '',
    isChecked: false,
    isDisabled: false,
    isRequired: false,
  };

  render() {
    const { id, labelClassName, labelText, hintText, errorText, isChecked, isDisabled, isRequired, onChange } =
      this.props;
    const classNames = ['form-element', 'form-element--checkbox'];
    if (isDisabled) classNames.push('form-element--disabled');
    if (isRequired) classNames.push('form-element--required');
    if (errorText) classNames.push('form-element--error');

    const inputProps = {
      id,
      name: id,
      type: 'checkbox',
      checked: isChecked,
      disabled: isDisabled,
      required: isRequired,
      onChange,
      ...(hintText && { 'aria-describedby': `${id}-hint` }),
    };

    return (
      /* eslint-disable jsx-a11y/label-has-for */
      <div className={classNames.join(' ')}>
        <input {...inputProps} />
        {labelText && (
          <label className={labelClassName} htmlFor={id} id={`${id}-label`}>
            {labelText}
            {isRequired && <span className="required">*</span>}
          </label>
        )}
        {hintText && (
          <span id={`${id}-hint`} className="form-element-hint">
            {hintText}
          </span>
        )}
        {errorText && <span className="form-element-error-msg">{errorText}</span>}
      </div>
      /* eslint-enable */
    );
  }
}

export default InputCheckbox;
