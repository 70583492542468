import PropTypes from 'prop-types';
import TableRow from 'components/table/TableRow';
import { getFormattedTime, stringToMoment } from 'utils/index';

function TableRowScheduleData(props) {
  const { dayLabel, timeStart, timeEnd, classroom, isSelected } = props;
  return (
    <TableRow>
      <li className="tc--day">{dayLabel}</li>
      <li className="tc--time-in">{isSelected ? getFormattedTime(stringToMoment(timeStart, 'hh:mm')) : 'N/A'}</li>
      <li className="tc--time-out">{isSelected ? getFormattedTime(stringToMoment(timeEnd, 'hh:mm')) : 'N/A'}</li>
      <li className="tc--classroom">{classroom || 'N/A'}</li>
    </TableRow>
  );
}
TableRowScheduleData.propTypes = {
  dayLabel: PropTypes.string.isRequired,
  timeStart: PropTypes.string.isRequired,
  timeEnd: PropTypes.string.isRequired,
  classroom: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default TableRowScheduleData;
