import { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from 'react-query';
import { adultByOrg } from 'graphql/customQueries';
import { cacheKeys } from 'conf';
import { stringBooleanToBool, boolToStringBoolean } from 'utils';
import { useAuthContext } from '../AuthContext';

const initialState = {
  isLoading: false,
  error: null,
  getAdult: () => {},
  getAdults: [],
  getStaffMembers: [],
  getAll: [],
  isActiveFilter: false,
  setIsActiveFilter: () => {},
};

const AdultListContext = createContext(initialState);

export function AdultListDataProvider({ children }) {
  const { orgId, tenantId, isAuthenticated } = useAuthContext();

  const [isActiveFilter, setIsActiveFilter] = useState(false);

  const {
    isLoading,
    isError,
    isSuccess,
    error: queryError,
    data: response,
  } = useQuery(
    [cacheKeys.getAdults, orgId],
    () =>
      API.graphql(
        graphqlOperation(adultByOrg, {
          orgId,
          limit: 10000,
          // filter: { isArchived: { eq: boolToStringBoolean(isActiveFilter) } },
        })
      ),
    {
      enabled: isAuthenticated && !!orgId && !!tenantId,
    }
  );

  const getAdults = useMemo(
    () =>
      isSuccess
        ? response.data.AdultByOrg.items
            .filter(
              (it) =>
                it.tenantId === tenantId &&
                !stringBooleanToBool(it.isStaffMember) &&
                it.isArchived === boolToStringBoolean(isActiveFilter)
            )
            .map((it) => ({ ...it, fullName: `${it.lastName}, ${it.firstName}` }))
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
        : [],
    [isActiveFilter, isSuccess, response, tenantId]
  );

  const getStaffMembers = useMemo(
    () =>
      isSuccess
        ? response.data.AdultByOrg.items
            .filter(
              (it) =>
                it.tenantId === tenantId &&
                stringBooleanToBool(it.isStaffMember) &&
                it.isArchived === boolToStringBoolean(isActiveFilter)
            )
            .map((it) => ({ ...it, fullName: `${it.lastName}, ${it.firstName}` }))
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
        : [],
    [isActiveFilter, isSuccess, response, tenantId]
  );

  const getAll = useMemo(
    () =>
      isSuccess
        ? response.data.AdultByOrg.items
            .map((it) => ({ ...it, fullName: `${it.lastName}, ${it.firstName}` }))
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
        : [],
    [isSuccess, response]
  );

  const getOrgStaffMembers = useMemo(
    () =>
      isSuccess
        ? response.data.AdultByOrg.items
            .filter(
              (it) => stringBooleanToBool(it.isStaffMember) && it.isArchived === boolToStringBoolean(isActiveFilter)
            )
            .map((it) => ({ ...it, fullName: `${it.lastName}, ${it.firstName}` }))
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
        : [],
    [isActiveFilter, isSuccess, response]
  );

  const getValue = useMemo(() => {
    const getAdult = (id) => {
      const rec = isSuccess ? response.data.AdultByOrg.items.find((it) => it.id === id) : {};
      if (isSuccess) {
        return { ...rec, fullName: `${rec.lastName}, ${rec.firstName}` };
      }
      return rec;
    };

    return {
      isLoading,
      isSuccess,
      error: isError ? queryError : null,
      getAdult,
      getAdults,
      getStaffMembers,
      getOrgStaffMembers,
      getAll,
      isActiveFilter,
      setIsActiveFilter,
    };
  }, [
    isLoading,
    isSuccess,
    isError,
    queryError,
    getAdults,
    getStaffMembers,
    getOrgStaffMembers,
    getAll,
    isActiveFilter,
    response,
  ]);

  return <AdultListContext.Provider value={getValue}>{children}</AdultListContext.Provider>;
}

AdultListDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAdultListContext = () => useContext(AdultListContext);
