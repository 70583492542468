import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const ERROR_TEXT = 'You have to check at least one item.';

class RadioButtons extends PureComponent {
  /* eslint-disable react/forbid-prop-types */
  static propTypes = {
    name: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        hint: PropTypes.string,
        error: PropTypes.string,
      }).isRequired
    ).isRequired,
    value: PropTypes.string,
    labelText: PropTypes.string,
    hintText: PropTypes.string,
    errorText: PropTypes.string,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    isInline: PropTypes.bool,
    onChange: PropTypes.func,
    labelClassName: PropTypes.string,
  };
  /* eslint-enable */

  static defaultProps = {
    value: null,
    onChange: null,
    labelClassName: 'label',
    labelText: '',
    hintText: '',
    errorText: '',
    isDisabled: false,
    isRequired: false,
    isInline: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({
      value,
    });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    const { value: prevValue } = prevProps;
    if (value !== prevValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        value,
      });
    }
  }

  handleOnChange = (e) => {
    const { onChange, name } = this.props;
    const { value } = e.target;
    this.setState({
      value,
    });
    onChange(value, name);
  };

  render() {
    const { value } = this.state;
    const { name, list, labelClassName, labelText, hintText, errorText, isDisabled, isRequired, isInline } = this.props;
    const classNames = ['form-group', 'form-group--radio'];
    if (isDisabled) classNames.push('form-group--disabled');
    if (isRequired) classNames.push('form-group--required');

    let inputProps = {};
    let error = errorText;
    if (isRequired && !value) {
      error = ERROR_TEXT;
    }
    if (error) classNames.push('form-group--error');
    /* eslint-disable jsx-a11y/label-has-for */
    /* eslint-disable jsx-a11y/label-has-associated-control */
    const radioButtons = list.map((it, i) => {
      inputProps = {
        id: `${name}-${i + 1}`,
        name,
        value: it.value,
        type: 'radio',
        checked: it.value === value,
        required: isRequired,
        onChange: this.handleOnChange,
        ...(it.hint && { 'aria-describedby': `${name}-hint` }),
      };
      return (
        <div className="form-element form-element--radio" key={`${name}-${i + 1}`}>
          <input {...inputProps} />
          {it.label && (
            <label className={labelClassName} htmlFor={`${name}-${i + 1}`} id={`${it.value}-label-${i + 1}`}>
              {it.label}
            </label>
          )}
          {it.hint && (
            <span id={`${it.value}-hint-${i + 1}`} className="form-element-hint">
              {it.hint}
            </span>
          )}
          {it.error && <span className="form-element-error-msg">{it.error}</span>}
        </div>
      );
    });

    return (
      <fieldset className={classNames.join(' ')}>
        {labelText && (
          <legend className="label">
            {labelText}
            {isRequired && <span className="required">*</span>}
          </legend>
        )}
        {hintText && (
          <span id={`${name}-hint`} className="form-group-hint">
            {hintText}
          </span>
        )}
        {/* {error && <span className="form-group--error-msg">{error}</span>} */}
        {!!list.length && <div className={isInline ? 'radio-group--inline' : ''}>{radioButtons}</div>}
      </fieldset>
    );
    /* eslint-enable */
  }
}

export default RadioButtons;
