import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableActions from './TableActions';

function Table(props) {
  const { className, id, headerLabels, rows, resultsRow, tableActions } = props;
  return (
    <div className={`${className} table--${id}`}>
      {!!resultsRow && resultsRow}

      <TableHeader headerLabels={headerLabels} />
      {!!rows.length && rows}

      {!!tableActions && <TableActions actions={tableActions} />}
    </div>
  );
}

Table.propTypes = {
  id: PropTypes.string.isRequired,
  headerLabels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node)]).isRequired,
      className: PropTypes.string.isRequired,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.node),
  resultsRow: PropTypes.node,
  className: PropTypes.string,
  tableActions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      classNames: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};

Table.defaultProps = {
  className: 'table',
  rows: [],
  resultsRow: null,
  tableActions: [],
};

export default Table;
