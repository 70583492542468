import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { ROUTE_CHOOSE_TENANT } from 'constants/index';
import { useAuthContext } from 'context/AuthContext';

function TenantCheckWrapper({ children }) {
  const { hasTenant } = useAuthContext();
  return !hasTenant ? <Redirect to={ROUTE_CHOOSE_TENANT} /> : children;
}

TenantCheckWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TenantCheckWrapper;
