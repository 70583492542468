import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputField from './InputField';

class InputSearch extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    hintText: PropTypes.string,
    errorText: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    labelText: null,
    hintText: null,
    placeholder: null,
    value: undefined,
    errorText: null,
    isRequired: false,
    isDisabled: false,
    onChange: null,
  };

  render() {
    return <InputField {...this.props} type="search" />;
  }
}

export default InputSearch;
