import { createContext, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { getUser as getUserQuery } from 'graphql/queries';
import { createAdult, updateAdult } from 'graphql/customMutations';
import conf, { cacheKeys } from 'conf';
import { StringBoolean } from 'constants/index';
import { boolToStringBoolean, stringBooleanToBool } from 'utils/common';
import { useAuthContext } from '../AuthContext';
import { authenticatedAxios } from '../../clients/axiosClient';

const initialState = {
  doInvite: () => {},
  doCreate: () => {},
  doUpdate: () => {},
  doArchive: () => {},
  doReinvite: () => {},
  create: {
    id: null,
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: null,
  },
  update: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: false,
  },
  invite: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: false,
  },
  reinvite: {
    isSuccess: false,
    isLoading: false,
    isError: false,
    error: false,
  },
};

const AdultMutateContext = createContext(initialState);

export function AdultMutateProvider({ children }) {
  const { tenantId, orgId } = useAuthContext();
  const queryClient = useQueryClient();

  const {
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
    isError: isCreateError,
    error: createError,
    data: createResult,
    mutateAsync: create,
    // reset: createReset,
  } = useMutation((data) => API.graphql(graphqlOperation(createAdult, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getAdults, orgId]),
  });

  const {
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
    // data: updateResult,
    mutateAsync: update,
    // reset: updateReset,
  } = useMutation((data) => API.graphql(graphqlOperation(updateAdult, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getAdults, orgId]),
  });

  const { mutateAsync: deleteUser } = useMutation(
    async (id) => {
      const axiosClient = await authenticatedAxios();
      return axiosClient.request({
        url: `${conf.api.resources.user.index}/${id}`,
        method: 'DELETE',
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([cacheKeys.getAdults, orgId]);
      },
    }
  );

  const {
    isSuccess: isInviteSuccess,
    isLoading: isInviteLoading,
    isError: isInviteError,
    error: inviteError,
    data: inviteResult,
    mutateAsync: invite,
    reset: inviteReset,
  } = useMutation(
    async (data) => {
      const axiosClient = await authenticatedAxios();
      return axiosClient.request({
        url: conf.api.resources.user.index,
        method: 'POST',
        data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([cacheKeys.getAdults, orgId]);
        // setInviteResponse(res.data);
      },
    }
  );

  const {
    isSuccess: isReinviteSuccess,
    isLoading: isReinviteLoading,
    isError: isReinviteError,
    error: reinviteError,
    data: reinviteResult,
    mutateAsync: reinvite,
    reset: reinviteReset,
  } = useMutation(
    async (data) => {
      const { id, ...rest } = data;
      const axiosClient = await authenticatedAxios();
      return axiosClient.request({
        url: `${conf.api.resources.user.index}/${id}${conf.api.resources.user.reinvite}`,
        method: 'POST',
        data: { ...rest },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([cacheKeys.getAdults, orgId]);
        // setReinviteResponse(res.data);
      },
    }
  );

  const newId = useMemo(() => {
    if (isCreateSuccess) return createResult.data.createAdult.id;
    return null;
  }, [createResult, isCreateSuccess]);

  const inviteRes = useMemo(() => {
    // console.log(inviteResult?.data);
    if (isInviteSuccess && inviteResult && inviteResult.data) return inviteResult.data;
    return null;
  }, [inviteResult, isInviteSuccess]);

  const reinviteRes = useMemo(() => {
    // console.log(reinviteResult?.data);
    if (isReinviteSuccess && reinviteResult && reinviteResult.data) return reinviteResult.data;
    return null;
  }, [isReinviteSuccess, reinviteResult]);

  const doAdultCreate = useCallback(
    async (data) => {
      await create({
        ...data,
        tenantId,
        orgId,
      });
    },
    [create, orgId, tenantId]
  );

  const doAdultUpdate = useCallback(
    async (data, oldData = null) => {
      const {
        fullName,
        updatedAt,
        timeSheets,
        schedules,
        relatives,
        plannedAbsences,
        createdAt,
        children: cc,
        _deleted,
        _lastChangedAt,
        isStaffMember,
        ...rest
      } = data;
      await update({ ...rest, isStaffMember: boolToStringBoolean(isStaffMember) });
      // If an email or phone number has changed, we need to change it in Cognito as well
      if (oldData) {
        const { userId, email: oldEmail, phone: oldPhone, didSendInvite } = oldData;
        const { id, email: newEmail, phone: newPhone } = data;
        const isInvited = stringBooleanToBool(didSendInvite);
        if (isInvited && !!userId && (oldEmail !== newEmail || newPhone !== oldPhone)) {
          const userQueryRes = await API.graphql(graphqlOperation(getUserQuery, { id: userId }));
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { userName } = userQueryRes.data?.getUser;
          const axiosClient = await authenticatedAxios();
          if (userName) {
            await axiosClient.request({
              url: `${conf.api.resources.user.index}/${id}`,
              method: 'PUT',
              data: {
                userName,
                ...(oldEmail !== newEmail ? { email: newEmail } : {}),
                ...(newPhone !== oldPhone ? { phone: newPhone } : {}),
              },
            });
            // console.log(r);
          }
        }
      }
      // console.log(updateResult);
    },
    [update]
  );

  const doAdultArchive = useCallback(
    async (data) => {
      const {
        fullName,
        updatedAt,
        timeSheets,
        schedules,
        relatives,
        plannedAbsences,
        createdAt,
        children: cc,
        _deleted,
        _lastChangedAt,
        isStaffMember,
        ...rest
      } = data;
      await update({
        ...rest,
        isStaffMember,
        isArchived: StringBoolean.TRUE.value,
      });
      if (data.didSendInvite === StringBoolean.TRUE.value) {
        await deleteUser(data.userId);
      }
      // console.log(updateResult);
    },
    [deleteUser, update]
  );

  const doAdultRestore = useCallback(
    async (data) => {
      const { fullName, isStaffMember, ...rest } = data;
      await update({
        ...rest,
        isStaffMember,
        isArchived: StringBoolean.FALSE.value,
      });
    },
    [update]
  );

  const doAdultInvite = useCallback(
    async ({ id, firstName, lastName, email, phone, dateOfBirth, isStaffMember }) => {
      await invite({
        adultId: id,
        tenantId,
        orgId,
        firstName,
        lastName,
        email,
        phone,
        dateOfBirth,
        isStaffMember: isStaffMember === StringBoolean.TRUE.value,
        isTenantAdmin: false,
        sendInvite: true,
      });
      // console.log(updateResult);
    },
    [invite, orgId, tenantId]
  );

  const doAdultReinvite = useCallback(
    async ({ id, userId, firstName, email }) => {
      await reinvite({
        id,
        userId,
        tenantId,
        firstName,
        email,
      });
      // console.log(updateResult);
    },
    [reinvite, tenantId]
  );

  const value = useMemo(
    () => ({
      doInvite: doAdultInvite,
      doCreate: doAdultCreate,
      doUpdate: doAdultUpdate,
      doArchive: doAdultArchive,
      doRestore: doAdultRestore,
      doReinvite: doAdultReinvite,
      create: {
        id: newId,
        isSuccess: isCreateSuccess,
        isLoading: isCreateLoading,
        isError: isCreateError,
        error: createError,
      },
      update: {
        isSuccess: isUpdateSuccess,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
      },
      invite: {
        isSuccess: isInviteSuccess,
        isLoading: isInviteLoading,
        isError: isInviteError,
        error: inviteError,
        result: inviteRes,
        reset: inviteReset,
      },
      reinvite: {
        isSuccess: isReinviteSuccess,
        isLoading: isReinviteLoading,
        isError: isReinviteError,
        error: reinviteError,
        result: reinviteRes,
        reset: reinviteReset,
      },
    }),
    [
      createError,
      doAdultArchive,
      doAdultRestore,
      doAdultCreate,
      doAdultInvite,
      doAdultReinvite,
      doAdultUpdate,
      inviteError,
      inviteRes,
      inviteReset,
      isCreateError,
      isCreateLoading,
      isCreateSuccess,
      isInviteError,
      isInviteLoading,
      isInviteSuccess,
      isReinviteError,
      isReinviteLoading,
      isReinviteSuccess,
      isUpdateError,
      isUpdateLoading,
      isUpdateSuccess,
      newId,
      reinviteError,
      reinviteRes,
      reinviteReset,
      updateError,
    ]
  );

  return <AdultMutateContext.Provider value={value}>{children}</AdultMutateContext.Provider>;
}

AdultMutateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAdultMutateContext = () => useContext(AdultMutateContext);
