import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PageTitle extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    child: PropTypes.node,
  };

  static defaultProps = {
    subtitle: null,
    child: null,
  };

  render() {
    const { title, subtitle, child } = this.props;
    return (
      <div className="page-title">
        <h1>{title}</h1>
        {subtitle && <p className="page-subtitle">{subtitle}</p>}
        {!!child && child}
      </div>
    );
  }
}

export default PageTitle;
