import { DateTime, Interval } from 'luxon';
import moment from 'moment';
import isAfterDay from 'react-dates/src/utils/isAfterDay';
import humanizeDuration from 'humanize-duration';
import config from 'conf/index';

/**
 * Create DateTime object from date string
 * @param str {String} date string
 * @param fmt {String?} optional overriding date format, defaults to yyyy-MM-dd
 * @returns {moment.Moment} Moment object representing the date
 */
export const stringToMoment = (str, fmt = null) => moment(str, fmt || config.formats.momentDateSystem);

/**
 * Converts datetime to app's time zone and
 * formats to the app's default Date format
 * @param datetime {moment.Moment} ISO datetime string
 * @returns {string} The formatted date
 */
export const getFormattedDate = (m) => m.format(config.formats.momentDate);

/**
 * Converts datetime to app's time zone and
 * formats to the app's default Time format
 * @param datetime {moment.Moment} ISO datetime string
 * @returns {string} The formatted date
 */
export const getFormattedTime = (m) => m.format(config.formats.momentTime);

export const formatTimeTo24Hour = (s) => moment(s, config.formats.momentTime).format('HH:mm');

/**
 * Converts datetime to app's time zone and
 * formats to the app's default Time format
 * @param datetime {moment.Moment} ISO datetime string
 * @returns {string} The formatted date
 */
export const getTodayAsServerDate = () => moment().format(config.formats.momentDateSystem);

export const getAge = (dob) => {
  if (!dob) return 'N/A';
  const today = moment();
  const isProjected = isAfterDay(dob, today);
  if (isProjected) return 0;
  const d = moment.duration(today.diff(dob));
  return humanizeDuration(d.asMilliseconds(), { largest: 2 });
};

export const getAgeInMonths = (dob) => {
  if (!dob) return 'N/A';
  const today = moment();
  const d = moment.duration(today.diff(dob));
  return d.years() * 12 + d.months();
};

function* weekIterator(interval) {
  let cursor = interval.start.startOf('day');
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ weeks: 1 });
  }
}

function* dayIterator(interval) {
  let cursor = interval.start.startOf('day');
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

export const getWeeksForYear = (year) => {
  const y = year || DateTime.now().year;
  let startOfYear = DateTime.fromObject({
    month: 1,
    day: 1,
    year: y,
  }).startOf('week');

  if (startOfYear.year !== y) {
    startOfYear = startOfYear.plus({ weeks: 1 });
  }

  const endOfYear = DateTime.fromObject({
    month: 12,
    day: 31,
    year: y,
  }).endOf('week');

  const yearRange = Interval.fromDateTimes(startOfYear, endOfYear);

  const weekList = Array.from(weekIterator(yearRange)).map((it) => ({
    year: it.year,
    weekNumber: it.weekNumber,
    start: it,
    end: it.plus({ weeks: 1 }),
  }));
  return weekList;
};

export const dayList = (interval) =>
  Array.from(dayIterator(interval)).map((it) => ({
    dt: it,
    dtFmt: it.toFormat('EEE, LL/dd'),
  }));

export const getWeekNumberOfDate = (date) => moment(date).week();

export const getDateWithWeekNumber = (year, week) => moment().year(year).week(week);
