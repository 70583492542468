import { createContext, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { createRelationship, updateRelationship } from 'graphql/customMutations';
import { cacheKeys } from 'conf';
import { StringBoolean } from 'constants/index';
import { boolToStringBoolean } from 'utils/index';
import { useAuthContext } from '../AuthContext';

const initialState = {
  isLoading: false,
  error: null,
};

const RelationshipMutateContext = createContext(initialState);

export function RelationshipMutateProvider({ children, ownerId }) {
  const { tenantId } = useAuthContext();
  const queryClient = useQueryClient();

  const {
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
    isError: isCreateError,
    error: createError,
    data: createResult,
    mutateAsync: create,
  } = useMutation((data) => API.graphql(graphqlOperation(createRelationship, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getRelationships, ownerId, tenantId]),
  });

  const {
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
    // data: updateResult,
    mutateAsync: update,
  } = useMutation((data) => API.graphql(graphqlOperation(updateRelationship, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getRelationships, ownerId, tenantId]),
  });

  const doCreate = useCallback(
    async (data) => {
      const { attendance, billing, __typename, ...rest } = data;
      await create({
        ...rest,
        tenantId,
        attendance: boolToStringBoolean(attendance),
        billing: boolToStringBoolean(billing),
      });
      // console.log(createResult);
    },
    [create, tenantId]
  );

  const doUpdate = useCallback(
    async (data) => {
      const { updatedAt, createdAt, _deleted, _lastChangedAt, attendance, billing, __typename, ...rest } = data;
      await update({
        ...rest,
        attendance: boolToStringBoolean(attendance),
        billing: boolToStringBoolean(billing),
      });
    },
    [update]
  );

  const doArchive = useCallback(
    async (data) => {
      const { updatedAt, createdAt, _deleted, _lastChangedAt, student, adult, __typename, ...rest } = data;
      await update({
        ...rest,
        isArchived: StringBoolean.TRUE.value,
      });
    },
    [update]
  );

  const newId = useMemo(() => {
    if (isCreateSuccess) return createResult.data.createRelationship.id;
    return null;
  }, [createResult, isCreateSuccess]);

  const value = useMemo(
    () => ({
      doCreate,
      doUpdate,
      doArchive,
      create: {
        id: newId,
        isSuccess: isCreateSuccess,
        isLoading: isCreateLoading,
        isError: isCreateError,
        error: createError,
      },
      update: {
        isSuccess: isUpdateSuccess,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
      },
    }),
    [
      createError,
      doArchive,
      doCreate,
      doUpdate,
      isCreateError,
      isCreateLoading,
      isCreateSuccess,
      isUpdateError,
      isUpdateLoading,
      isUpdateSuccess,
      newId,
      updateError,
    ]
  );

  return <RelationshipMutateContext.Provider value={value}>{children}</RelationshipMutateContext.Provider>;
}

RelationshipMutateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  ownerId: PropTypes.string.isRequired,
};

export const useRelationshipMutateContext = () => useContext(RelationshipMutateContext);
