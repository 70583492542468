import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import 'react-dates/initialize';
import { Amplify } from 'aws-amplify';
import Root from './components/containers/Root';
// import devConfig from './conf/aws-exports.dev';
import prodConfig from './conf/aws-exports.prod';
import config from './conf';

Amplify.configure(prodConfig);

const target = document.getElementById('root');

if (config.isProd) {
  Sentry.init({
    dsn: config.sentryDsn,
    release: `easyadmin-admin-webapp@${config.version}`,
  });
}

const root = createRoot(target);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
