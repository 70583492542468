import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { onFieldChange, validationChangeForm, isEmpty } from 'utils/index';
import { Form } from 'components/layout';
import { InputText, InputSelect, InputEmail, InputTel, Toggle } from 'components/formElements';
import { ActingSubmitButton } from 'components/buttons';
import TenantShape from 'shapes/TenantShape';
import states from '../../__data/us-states.json';

const getState = (tenant) => {
  const {
    id,
    name,
    address,
    address2,
    city,
    zip,
    state,
    mobile,
    phone,
    email,
    notifyOnTimesheetAdd,
    billPayrollToHomeCenter,
    billAttendanceToHomeCenter,
    _version,
  } = tenant;
  return {
    id: id || '',
    name: name || '',
    address: address || '',
    address2: address2 || '',
    city: city || '',
    zip: zip || '',
    state: state || '',
    mobile: mobile || '',
    phone: phone || '',
    email: email || '',
    billPayrollToHomeCenter: billPayrollToHomeCenter || false,
    billAttendanceToHomeCenter: billAttendanceToHomeCenter || false,
    notifyOnTimesheetAdd: isEmpty(notifyOnTimesheetAdd) || notifyOnTimesheetAdd,
    _version,
  };
};

function AdminInfoForm(props) {
  const { tenant, isSubmitting, cancelUrl, submitFn } = props;
  const [values, setValues] = useState(getState(tenant));
  const [copyValues] = useState({ ...values });
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const {
    name,
    address,
    address2,
    city,
    zip,
    state,
    mobile,
    phone,
    email,
    notifyOnTimesheetAdd,
    billPayrollToHomeCenter,
    billAttendanceToHomeCenter,
  } = values;

  useEffect(() => {
    validationChangeForm(values, copyValues, setDisabledSubmit);
  }, [copyValues, values]);

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Save Changes',
    actionText: 'Saving...',
    isActing: isSubmitting,
    isDisabled: disabledSubmit,
  };

  return (
    <Form
      onSubmit={() => {
        submitFn(values);
      }}
    >
      <InputText
        id="name"
        value={name}
        labelText="Center Name"
        onChange={(e) => onFieldChange(values, setValues, e)}
        isRequired
      />
      <fieldset className="fieldset">
        <legend>Contact Information</legend>
        <InputTel
          id="mobile"
          value={mobile}
          labelText="Mobile Number"
          hintText="(SMS notifications will be sent to this number)"
          maxlength={10}
          onChange={(e) => onFieldChange(values, setValues, e)}
          isRequired
        />
        <InputEmail
          id="email"
          value={email}
          labelText="Email Address"
          onChange={(e) => onFieldChange(values, setValues, e)}
          isRequired
        />
        <InputTel
          id="phone"
          value={phone}
          labelText="Phone"
          hintText="Just the numbers please, no spaces, dashes, or other characters."
          maxlength={10}
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
      </fieldset>
      <fieldset className="fieldset">
        <legend>Address</legend>
        <InputText
          id="address"
          value={address}
          labelText="Address"
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <InputText
          id="address2"
          value={address2}
          labelText="Building, Apartment, suite, etc."
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <InputText id="city" value={city} labelText="City" onChange={(e) => onFieldChange(values, setValues, e)} />
        <InputText
          id="zip"
          value={zip}
          labelText="ZIP"
          pattern="[0-9]{5}"
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <InputSelect
          id="state"
          value={state}
          labelText="State"
          isRequired
          onChange={(e) => onFieldChange(values, setValues, e)}
          options={states}
        />
      </fieldset>
      <fieldset className="fieldset">
        <legend>Preferences</legend>
        <Toggle
          id="notifyOnTimesheetAdd"
          isChecked={notifyOnTimesheetAdd}
          labelText="Receive an SMS when someone signs in to or out of the Center?"
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <Toggle
          id="billPayrollToHomeCenter"
          isChecked={billPayrollToHomeCenter}
          labelText="Bill payroll to the home center of a Staff Member?"
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <Toggle
          id="billAttendanceToHomeCenter"
          isChecked={billAttendanceToHomeCenter}
          labelText="Bill tuition to the home center of a Child?"
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
      </fieldset>
      <div className="form-actions">
        <Link className="button--text--cancel" to={cancelUrl}>
          Cancel
        </Link>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
}

AdminInfoForm.propTypes = {
  submitFn: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  tenant: PropTypes.shape(TenantShape).isRequired,
};

export default AdminInfoForm;
