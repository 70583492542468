/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useRelationshipMutateContext } from 'context/Relationships/RelationshipMutateContext';
import {
  getFormattedPin,
  getFormattedBilling,
  boolToYesNo,
  isEmpty,
  getRecordPathFromType,
  stringBooleanToBool,
} from 'utils/index';
import { useModal } from 'utils/useModal';
import {
  TYPE_STUDENT,
  PERMISSION_LABELS,
  ROUTE_RELATIONSHIP,
  RelationshipType,
  GqlRelationshipTypes,
} from 'constants/index';
import { Button } from 'components/buttons';
import { ConfirmModal } from 'components/modal';
import { Error } from 'components/layout';
import TableRow from 'components/table/TableRow';

function TableRowRelated(props) {
  const { isShowing, toggle } = useModal();
  const { data, referrerType } = props;
  const {
    id,
    adultId,
    studentId,
    adult,
    student,
    rel,
    relDetails,
    isPrimary,
    attendance,
    billing,
    billingAmount,
    billingType,
    __typename,
    // relType,
  } = data;

  const tName = GqlRelationshipTypes[__typename];

  const {
    doArchive,
    update: { isLoading, isError, isSuccess, error },
  } = useRelationshipMutateContext();

  const ownerIsStudent = useMemo(() => referrerType === TYPE_STUDENT, [referrerType]);

  const partyId = useMemo(() => (ownerIsStudent ? studentId : adultId), [adultId, ownerIsStudent, studentId]);

  const updateUrl = useMemo(
    () => `${ROUTE_RELATIONSHIP}/update/${id}/${getRecordPathFromType(referrerType)}/${partyId}`,
    [id, partyId, referrerType]
  );

  const pin = useMemo(() => (ownerIsStudent ? getFormattedPin(adult.pin) : null), [adult, ownerIsStudent]);

  const fullName = useMemo(
    () => (ownerIsStudent ? `${adult.lastName}, ${adult.firstName}` : `${student.lastName}, ${student.firstName}`),
    [adult, ownerIsStudent, student]
  );

  const permValues = useMemo(
    () => ({ billing: stringBooleanToBool(billing), attendance: stringBooleanToBool(attendance) }),
    [attendance, billing]
  );

  const perms = useMemo(
    () =>
      Object.keys(permValues)
        .filter((it) => permValues[it])
        .reduce((acc, nx) => `${acc ? `${acc}, ` : ``}${PERMISSION_LABELS[nx]}`, ''),
    [permValues]
  );

  const deleteContent = useMemo(() => {
    if (isLoading) {
      return '';
    }
    if (isError) {
      return <Error error={error.errors.reduce((acc, nx) => `${acc ? `${acc}, ` : ``} ${nx.message}`, '')} />;
    }
    return `This will remove the relationship and cannot be undone. You will have to add a new relationship
          if you need to link these two parties again.`;
  }, [error, isError, isLoading]);

  return !isEmpty(data) ? (
    <>
      <TableRow key={id}>
        <li className="tc--name">{fullName}</li>
        <li className="tc--relationship">
          {rel === RelationshipType.Other.value ? relDetails : RelationshipType[rel].label}
        </li>
        <li className="tc--primary-guardian">{boolToYesNo(isPrimary)}</li>
        <li className="tc--permissions">{perms}</li>
        <li className="tc--billing">{getFormattedBilling(stringBooleanToBool(billing), billingType, billingAmount)}</li>
        <li className="tc--pin">{getFormattedPin(pin)}</li>
        <li className="tc--edit">
          {tName === GqlRelationshipTypes.Relationship ? (
            <Link className="button--text" to={updateUrl}>
              Edit
            </Link>
          ) : null}
        </li>
        <li className="tc--remove">
          <Button className="button--text--neg" onClick={toggle}>
            Remove
          </Button>
        </li>
      </TableRow>
      <ConfirmModal
        isShowing={isShowing}
        title="Remove relationship?"
        content={deleteContent}
        isRequesting={isLoading}
        doAction={async () => {
          await doArchive(data);
          if (isSuccess) toggle();
        }}
        hide={toggle}
        confirmLabel="Remove Relationship"
        cancelLabel="Cancel"
      />
    </>
  ) : null;
}

TableRowRelated.propTypes = {
  data: PropTypes.shape().isRequired,
  referrerType: PropTypes.string.isRequired,
};

export default TableRowRelated;
