const {
  NODE_ENV,
  REACT_APP_DATE_FORMAT,
  REACT_APP_DATE_SYSTEM_FORMAT,
  REACT_APP_TIME_FORMAT,
  REACT_APP_TIME_SYSTEM_FORMAT,
  REACT_APP_MOMENT_DATE_FORMAT,
  REACT_APP_MOMENT_DATE_FORMAT_WITH_WEEK,
  REACT_APP_MOMENT_DATE_SYSTEM_FORMAT,
  REACT_APP_MOMENT_TIME_FORMAT,
  REACT_APP_BASE_DOMAIN,
  REACT_APP_DEFAULT_DOMAIN,
  REACT_APP_TIME_ZONE,
  REACT_APP_FIREBASE_APIKEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DB_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_DB_DATA_PATH,
  REACT_APP_DB_MEMBER_PATH,
  REACT_APP_DB_TIMESHEET_PATH,
  REACT_APP_DB_SCHEDULE_PATH,
  REACT_APP_DB_SYSTEM_PATH,
  REACT_APP_DB_SETTINGS_PATH,
  REACT_APP_DB_TENANTS_PATH,
  REACT_APP_DB_RATIOS_PATH,
  REACT_APP_DB_CLASSROOM_PATH,
  REACT_APP_DB_CONTACTS_PATH,
  REACT_APP_NO_ACTION_TIMEOUT,
  REACT_APP_SUCCESS_TIMEOUT,
  REACT_APP_PIN_ERROR_TIMEOUT,
  REACT_APP_DEFAULT_TENANT,
  REACT_APP_TENANT_KEY,
  REACT_APP_VERSION,
  REACT_APP_SENTRY_DSN,
  REACT_APP_NOTIFY_SMS_URL,
  REACT_APP_UPDATE_USER_EMAIL_URL,
  REACT_APP_API_GATEWAY_BASE_URL,
  REACT_APP_USER_URL,
  REACT_APP_USER_REINVITE_URL,
} = process.env;

const config = {
  version: REACT_APP_VERSION,
  baseDomain: REACT_APP_BASE_DOMAIN,
  defaultDomain: REACT_APP_DEFAULT_DOMAIN,
  env: NODE_ENV,
  api: {
    baseUrl: REACT_APP_API_GATEWAY_BASE_URL,
    defaultHeaders: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=UTF-8',
    },
    resources: {
      notify: {
        sms: REACT_APP_NOTIFY_SMS_URL,
      },
      updateUser: REACT_APP_UPDATE_USER_EMAIL_URL,
      user: {
        index: REACT_APP_USER_URL,
        reinvite: REACT_APP_USER_REINVITE_URL,
      },
    },
  },
  formats: {
    dateSystem: REACT_APP_DATE_SYSTEM_FORMAT,
    date: REACT_APP_DATE_FORMAT,
    time: REACT_APP_TIME_FORMAT,
    timeSystem: REACT_APP_TIME_SYSTEM_FORMAT,
    momentDateSystem: REACT_APP_MOMENT_DATE_SYSTEM_FORMAT,
    momentDate: REACT_APP_MOMENT_DATE_FORMAT,
    momentDateWithWeek: REACT_APP_MOMENT_DATE_FORMAT_WITH_WEEK,
    momentTime: REACT_APP_MOMENT_TIME_FORMAT,
  },
  store: {
    dataPath: REACT_APP_DB_DATA_PATH,
    memberPath: REACT_APP_DB_MEMBER_PATH,
    timesheetPath: REACT_APP_DB_TIMESHEET_PATH,
    schedulePath: REACT_APP_DB_SCHEDULE_PATH,
    systemPath: REACT_APP_DB_SYSTEM_PATH,
    settingsPath: REACT_APP_DB_SETTINGS_PATH,
    tenantsPath: REACT_APP_DB_TENANTS_PATH,
    ratiosPath: REACT_APP_DB_RATIOS_PATH,
    classroomPath: REACT_APP_DB_CLASSROOM_PATH,
    contactsPath: REACT_APP_DB_CONTACTS_PATH,
  },
  apiConf: {
    apiKey: REACT_APP_FIREBASE_APIKEY,
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: REACT_APP_FIREBASE_DB_URL,
    projectId: REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: REACT_APP_FIREBASE_APP_ID,
  },
  isProd: NODE_ENV === 'production',
  tenant: {
    default: REACT_APP_DEFAULT_TENANT,
    key: REACT_APP_TENANT_KEY,
  },
  org: {
    key: 'org-id',
  },
  timeouts: {
    noAction: REACT_APP_NO_ACTION_TIMEOUT,
    success: REACT_APP_SUCCESS_TIMEOUT,
    pinError: REACT_APP_PIN_ERROR_TIMEOUT,
  },
  timeZone: REACT_APP_TIME_ZONE,
  sentryDsn: REACT_APP_SENTRY_DSN,
};

export default config;

export const cacheKeys = {
  getAdults: 'getAdults',
  getStudents: 'getStudents',
  getRelationships: 'getRelationships',
  getRelatives: 'getRelatives',
  getSiblings: 'getSiblings',
  getPayrollTimesheets: 'getPayrollTimesheets',
  getAttendanceTimesheets: 'getAttendanceTimesheets',
  getStateRatios: 'getStateRatios',
  getTenants: 'getTenants',
  getTenantPreference: 'getTenantPreference',
  getTenantSchedule: 'getTenantSchedule',
  getUser: 'getUser',
  getClassrooms: 'getClassrooms',
  getStudentSchedule: 'getStudentSchedule',
  getStudentPlannedAbsence: 'getStudentPlannedAbsence',
  getStaffSchedule: 'getStaffSchedule',
  getStaffPlannedAbsence: 'getStaffPlannedAbsence',
  getEmergencyContacts: 'getEmergencyContacts',
  getStrategicData: 'getStrategicData',
};

export const cacheKeysToKeepOnTenantChange = [cacheKeys.getStateRatios];
