import PropTypes from 'prop-types';
import { PageHeader, PageTitle } from 'components/layout';
import { NavFocusview } from 'components/nav';

function PageFocusview(props) {
  const {
    children,
    pageTitle,
    pageTitleChild,
    pageClassNames,
    wrapperClassNames,
    pageHeaderClassNames,
    pageHeaderChildren,
    pageSubtitle,
    showNav,
    showBackButton,
    backButtonUrl,
    showCloseButton,
    closeButtonUrl,
    backButtonState,
    closeButtonState,
  } = props;
  const joinedPageClassNames = ['page--focusview'].concat(pageClassNames);
  const joinedWrapperClassNames = ['wrapper--center'].concat(wrapperClassNames);
  return (
    <div className={joinedPageClassNames.join(' ')}>
      {showNav && (
        <NavFocusview
          showCloseButton={showCloseButton}
          showBackButton={showBackButton}
          backButtonUrl={backButtonUrl}
          closeButtonUrl={closeButtonUrl}
          backButtonState={backButtonState}
          closeButtonState={closeButtonState}
        />
      )}

      <main className={joinedWrapperClassNames.join(' ')}>
        <PageHeader classNames={pageHeaderClassNames.join(' ')}>
          <PageTitle title={pageTitle} subtitle={pageSubtitle} child={pageTitleChild} />
          {pageHeaderChildren}
        </PageHeader>

        {children}
      </main>
    </div>
  );
}

PageFocusview.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  pageClassNames: PropTypes.arrayOf(PropTypes.string),
  wrapperClassNames: PropTypes.arrayOf(PropTypes.string),
  pageHeaderClassNames: PropTypes.arrayOf(PropTypes.string),
  pageHeaderChildren: PropTypes.node,
  pageTitleChild: PropTypes.node,
  pageSubtitle: PropTypes.string,
  showNav: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  backButtonUrl: PropTypes.string,
  closeButtonUrl: PropTypes.string,
  backButtonState: PropTypes.shape(),
  closeButtonState: PropTypes.shape(),
};

PageFocusview.defaultProps = {
  pageClassNames: [],
  wrapperClassNames: [],
  pageHeaderClassNames: [],
  pageHeaderChildren: null,
  pageTitleChild: null,
  pageSubtitle: null,
  showNav: true,
  showBackButton: false,
  showCloseButton: false,
  backButtonUrl: null,
  closeButtonUrl: null,
  backButtonState: null,
  closeButtonState: null,
};

export default PageFocusview;
