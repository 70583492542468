import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form } from 'components/layout';
import { ActingSubmitButton } from 'components/buttons';
import { InputText, InputTel } from 'components/formElements';
import { onFieldChange, validationChangeForm } from 'utils/index';
import { ContactType } from 'constants/index';

const getState = (emergencyContact) => {
  const { firstName, lastName, relationship, mobile, homePhone, workPhone, contactType } = emergencyContact;
  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    relationship: relationship ?? '',
    mobile: mobile ?? '',
    homePhone: homePhone ?? '',
    workPhone: workPhone ?? '',
    contactType: contactType ?? ContactType.AdHoc,
  };
};

function EmergencyContactForm(props) {
  const { isSubmitting, submitFn, emergencyContact, cancelUrl } = props;
  const [values, setValues] = useState(getState(emergencyContact));
  const [copyValues] = useState({ ...values });
  const [disabledSubmit, setDisabledSubmit] = useState(true);

  useEffect(() => {
    validationChangeForm(values, copyValues, setDisabledSubmit);
  }, [copyValues, values]);

  const submitProps = {
    btnClassName: 'button',
    actionBtnClassName: 'button button--icon--loading',
    btnText: 'Save',
    actionText: 'Saving...',
    isActing: isSubmitting,
    isDisabled: disabledSubmit,
  };
  return (
    <Form
      onSubmit={() => {
        submitFn(values);
      }}
    >
      <InputText
        id="firstName"
        labelText="First Name"
        onChange={(e) => onFieldChange(values, setValues, e)}
        value={values.firstName}
        isRequired
      />
      <InputText
        id="lastName"
        labelText="Last Name"
        onChange={(e) => onFieldChange(values, setValues, e)}
        value={values.lastName}
        isRequired
      />
      <InputText
        id="relationship"
        labelText="Relationship"
        onChange={(e) => onFieldChange(values, setValues, e)}
        value={values.relationship}
        isRequired
      />
      <InputTel
        id="mobile"
        labelText="Mobile Number"
        hintText="Just the numbers please, no dashes or spaces or brackets"
        onChange={(e) => onFieldChange(values, setValues, e)}
        value={values.mobile}
        maxLength={10}
        isRequired
      />
      <InputTel
        id="homePhone"
        labelText="Home Number"
        hintText="Just the numbers please, no dashes or spaces or brackets"
        onChange={(e) => onFieldChange(values, setValues, e)}
        value={values.homePhone}
        maxLength={10}
      />
      <InputTel
        id="workPhone"
        labelText="Work Number"
        hintText="Just the numbers please, no dashes or spaces or brackets"
        onChange={(e) => onFieldChange(values, setValues, e)}
        value={values.workPhone}
        maxLength={10}
      />
      <div className="form-actions">
        <Link className="button--text--cancel" to={cancelUrl}>
          Cancel
        </Link>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
}

EmergencyContactForm.propTypes = {
  submitFn: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  cancelUrl: PropTypes.string.isRequired,
  emergencyContact: PropTypes.shape(),
};

EmergencyContactForm.defaultProps = {
  emergencyContact: {
    firstName: '',
    lastName: '',
    relationship: '',
    mobile: '',
    homePhone: '',
    workPhone: '',
    contactType: ContactType.AdHoc,
  },
  isSubmitting: false,
};

export default EmergencyContactForm;
