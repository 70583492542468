import PropTypes from 'prop-types';

function ErrorMessage({ error, className }) {
  return (
    <div className={className}>
      <p>{error}</p>
    </div>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ErrorMessage.defaultProps = {
  className: 'form-error-msg',
};

export default ErrorMessage;
