import React, { useMemo, useState, useEffect } from 'react';
import { DateTime, Interval } from 'luxon';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from 'react-query';
import { staffScheduleByDate, studentScheduleByDate } from 'graphql/customQueries';
import config, { cacheKeys } from 'conf';
import { TYPE_STAFF, TYPE_STUDENT, StringBoolean, ROUTE_DASHBOARD, PREV, NEXT } from 'constants/index';
import { getWeeksForYear, dayList } from 'utils/dateTime';
import { useClassroomContext } from 'context/Classroom/ClassroomContext';
import { useAuthContext } from '../AuthContext';

const StrategicDashboardContext = React.createContext({
  getItems: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: null,
});

const WEEK_NAV_TYPE = 'WEEK';
const MONTH_NAV_TYPE = 'MONTH';
const WEEK_NAV_UNITS = 'weeks';
const WEEK_NAV_UNIT = 'week';
// const MONTH_NAV_UNIT = 'month';

const getNavUrl = (selectValue, action, type) => {
  const { year, weekNumber } = selectValue;
  const date = DateTime.fromObject({
    weekYear: year,
    weekNumber,
  });

  let adjustedDate = null;
  let prevOrNextDate = null;

  if (type === MONTH_NAV_TYPE) {
    adjustedDate = date.startOf(WEEK_NAV_UNIT);
    prevOrNextDate = adjustedDate[action === PREV ? 'minus' : 'plus']({ [WEEK_NAV_UNITS]: 4 });
  } else {
    adjustedDate = date.startOf(WEEK_NAV_UNIT);
    prevOrNextDate = adjustedDate[action === PREV ? 'minus' : 'plus']({ [WEEK_NAV_UNITS]: 1 });
  }

  return {
    url: `${ROUTE_DASHBOARD}y/${prevOrNextDate?.year}/w/${prevOrNextDate?.weekNumber}`,
    weekNumber: prevOrNextDate?.weekNumber,
    year: prevOrNextDate?.year,
  };
};

const getGroupedSchedules = (data, partyType) => groupBy(data, partyType === TYPE_STUDENT ? 'studentId' : 'adultId');

export function StrategicDashboardProvider({ children }) {
  const { isAuthenticated, tenantId } = useAuthContext();

  const params = useParams();
  const { year, weekNumber } = params;

  const today = useMemo(() => DateTime.local(), []);

  const [currentNavValue, setCurrentNavValue] = useState(
    weekNumber && year
      ? { year: parseInt(year, 10), weekNumber: parseInt(weekNumber, 10) }
      : { year: today.year, weekNumber: today.weekNumber }
  );

  const days = useMemo(() => {
    const weekStartDate = DateTime.fromObject({
      weekYear: currentNavValue.year,
      weekNumber: currentNavValue.weekNumber,
    });
    const wholeWeek = Interval.fromDateTimes(weekStartDate, weekStartDate.endOf('week'));
    return dayList(wholeWeek);
  }, [currentNavValue.weekNumber, currentNavValue.year]);

  useEffect(() => {
    if (year && weekNumber) {
      setCurrentNavValue({
        year: parseInt(year, 10),
        weekNumber: parseInt(weekNumber, 10),
      });
    }
  }, [weekNumber, year]);

  const prevMonth = useMemo(() => getNavUrl(currentNavValue, PREV, MONTH_NAV_TYPE), [currentNavValue]);
  const nextMonth = useMemo(() => getNavUrl(currentNavValue, NEXT, MONTH_NAV_TYPE), [currentNavValue]);
  const prevWeek = useMemo(() => getNavUrl(currentNavValue, PREV, WEEK_NAV_TYPE), [currentNavValue]);
  const nextWeek = useMemo(() => getNavUrl(currentNavValue, NEXT, WEEK_NAV_TYPE), [currentNavValue]);
  const selectValue = useMemo(() => `${currentNavValue.year}-${currentNavValue.weekNumber}`, [currentNavValue]);

  const weeks = useMemo(() => getWeeksForYear(currentNavValue.year), [currentNavValue]);

  const { startDate, endDate, endDateQuery } = useMemo(() => {
    const { start, end } = weeks.find(
      (it) => it.weekNumber === currentNavValue.weekNumber && it.year === currentNavValue.year
    );
    return { startDate: start, endDate: end, endDateQuery: end.plus({ weeks: 4 }) };
  }, [currentNavValue, weeks]);

  const { isLoading, isSuccess, getItems: classrooms } = useClassroomContext();

  const batchQuerySchedules = async () => {
    const studentSchedulesRaw = await API.graphql(
      graphqlOperation(studentScheduleByDate, {
        tenantId,
        isArchived: { eq: StringBoolean.FALSE.value },
        date: { le: endDateQuery.toFormat(config.formats.dateSystem) },
        limit: 10000,
      })
    );
    const staffSchedulesRaw = await API.graphql(
      graphqlOperation(staffScheduleByDate, {
        tenantId,
        isArchived: { eq: StringBoolean.FALSE.value },
        date: { le: endDateQuery.toFormat(config.formats.dateSystem) },
        limit: 10000,
      })
    );
    const studentSchedulesGrouped = getGroupedSchedules(
      studentSchedulesRaw.data?.StudentScheduleByDate?.items,
      TYPE_STUDENT
    );
    const staffSchedulesGrouped = getGroupedSchedules(staffSchedulesRaw.data?.StaffScheduleByDate?.items, TYPE_STAFF);

    const data = classrooms
      .filter((cl) => !cl.isAdmin)
      .map((classroom) => {
        const { id } = classroom;
        const classroomDays = days
          .filter((d) => d.dt <= endDate)
          .map((day) => {
            const { dt } = day;
            const dtServerFmt = dt.toFormat(config.formats.dateSystem);
            const dtDayLabel = dt.toFormat('EEEE');
            const studentsForThisDay = Object.keys(studentSchedulesGrouped).reduce((acc, studentId) => {
              const [firstApplicableSchedule] = studentSchedulesGrouped[studentId]
                .map((sD) => ({
                  ...sD,
                  dt: DateTime.fromISO(sD.date),
                }))
                .filter((it) => it.dt <= dt)
                .sort((a, b) => b.dt - a.dt);
              if (firstApplicableSchedule) {
                const thisDay = firstApplicableSchedule.schedule.items.find(
                  (sch) =>
                    sch.label === dtDayLabel && sch.isSelected === StringBoolean.TRUE.value && sch.classroomId === id
                );
                if (thisDay) {
                  const dob = firstApplicableSchedule.student.dateOfBirth;
                  const dobDt = DateTime.fromISO(dob);
                  const monthsDuration = dobDt > dt ? 0 : Interval.fromDateTimes(dobDt, dt).toDuration('month').months;
                  return acc.concat([
                    {
                      id: studentId,
                      fullName: `${firstApplicableSchedule.student.lastName}, ${firstApplicableSchedule.student.firstName}`,
                      ageInMonths: Math.floor(monthsDuration),
                      dobDt,
                      dob,
                      isTemporary: firstApplicableSchedule.isTemporary,
                    },
                  ]);
                }
              }
              return acc;
            }, []);
            const staffForThisDay = Object.keys(staffSchedulesGrouped).reduce((acc, adultId) => {
              const [firstApplicableSchedule] = staffSchedulesGrouped[adultId]
                .map((sD) => ({
                  ...sD,
                  dt: DateTime.fromISO(sD.date),
                }))
                .filter((it) => it.dt <= dt)
                .sort((a, b) => b.dt - a.dt);
              if (firstApplicableSchedule) {
                const thisDay = firstApplicableSchedule.schedule.items.find(
                  (sch) =>
                    sch.label === dtDayLabel && sch.isSelected === StringBoolean.TRUE.value && sch.classroomId === id
                );
                if (thisDay) {
                  return acc.concat([
                    {
                      id: adultId,
                      fullName: `${firstApplicableSchedule.adult.lastName}, ${firstApplicableSchedule.adult.firstName}`,
                    },
                  ]);
                }
              }
              return acc;
            }, []);

            const hasTemporary = !!studentsForThisDay.find((it) => it.isTemporary);

            const res = {
              id: dtServerFmt,
              dt,
              dtDayLabel,
              students: studentsForThisDay,
              studentCount: studentsForThisDay.length,
              staff: staffForThisDay,
              staffCount: staffForThisDay.length,
              hasTemporary,
            };

            return res;
          });

        return {
          ...classroom,
          days: classroomDays,
          hasTemporary: !!classroomDays.find((it) => it.hasTemporary),
        };
      });

    const groupedByDay = groupBy(
      data.map((it) => it.days).reduce((acc, nx) => acc.concat(nx), []),
      'id'
    );

    const totals = Object.keys(groupedByDay).map((dayFmt) => {
      const dayGroup = groupedByDay[dayFmt];
      return {
        id: dayFmt,
        dt: dayGroup[0].dt,
        studentTotal: dayGroup.reduce((acc, nx) => acc + nx.studentCount, 0),
        staffTotal: dayGroup.reduce((acc, nx) => acc + nx.staffCount, 0),
      };
    });

    return {
      classrooms: data,
      totals,
    };
  };

  const {
    isLoading: isSchedulesLoading,
    isError: isSchedulesError,
    isSuccess: isSchedulesSuccess,
    error: schedulesError,
    data: schedulesData,
  } = useQuery(
    [cacheKeys.getStaffSchedule, cacheKeys.getStudentSchedule, tenantId, startDate, endDateQuery],
    batchQuerySchedules,
    { enabled: isAuthenticated && isSuccess && !!startDate && !!endDateQuery }
  );

  /**
   *  Classrooms
   *  [
   *    {
   *      ...classroom,
   *      days: [
   *        {
   *          id: "2022-09-01",
   *          dt: // DateTime object,
   *          dtDayLabel: "Monday",
   *          staff: [
   *            {
   *              id,
   *              fullName
   *            }
   *          ],
   *          staffCount: 0,
   *          students: [
   *            {
   *              id,
   *              fullName
   *              ageInMonths,
   *            }
   *          ],
   *          studentCount: 0,
   *        }
   *      ]
   *    }
   *  ]
   */

  const value = useMemo(
    () => ({
      nav: {
        weeks,
        days,
        startDate,
        endDate,
        prevMonth,
        nextMonth,
        prevWeek,
        nextWeek,
        selectValue,
        currentNavValue,
        setCurrentNavValue,
      },
      getItems: schedulesData,
      isLoading: isSchedulesLoading || isLoading,
      isSuccess: isSchedulesSuccess || isSuccess,
      isError: isSchedulesError,
      error: schedulesError,
    }),
    [
      weeks,
      days,
      startDate,
      endDate,
      prevMonth,
      nextMonth,
      prevWeek,
      nextWeek,
      selectValue,
      currentNavValue,
      schedulesData,
      isSchedulesLoading,
      isLoading,
      isSchedulesSuccess,
      isSuccess,
      isSchedulesError,
      schedulesError,
    ]
  );

  return <StrategicDashboardContext.Provider value={value}>{children}</StrategicDashboardContext.Provider>;
}

StrategicDashboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useStrategicContext = () => React.useContext(StrategicDashboardContext);
