import { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isInclusivelyAfterDay } from 'react-dates';
import { Link } from 'react-router-dom';
import { useAuthContext } from 'context/AuthContext';
import { useTenantContext } from 'context/Tenant/TenantContext';
import { useRelationshipContext } from 'context/Relationships/RelationshipContext';
import { ActingSubmitButton } from 'components/buttons';
import { InputText, InputEmail, InputTel, InputSelect, InputCheckbox } from 'components/formElements';
import { Form, Error } from 'components/layout';
import { SingleDatePickerWrapper } from 'components/dates';
import { onFieldChange, onDateChange, isEmpty, validationChangeForm, stringBooleanToBool } from 'utils/index';
import config from 'conf';
import { TYPE_STAFF, TYPE_ADULT, MODE_CREATE, MODE_UPDATE, DATE_PICKER_OPEN_DIRECTION } from 'constants/index';
import states from '../../__data/us-states.json';

const getDefaultState = (defaultStaffMember, record) => {
  const { fullName, isStaffMember, ...rest } = record;
  return !isEmpty(record)
    ? { ...rest, isStaffMember: stringBooleanToBool(isStaffMember) }
    : {
        tenantId: null,
        startDate: null,
        endDate: null,
        pin: '',
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        dateOfBirth: null,
        homePhone: '',
        workPhone: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        isStaffMember: defaultStaffMember,
        isStartDateEstimated: false,
        isEndDateEstimated: false,
      };
};

function FormAdultPersonalInformation(props) {
  const { orgId } = useAuthContext();
  const { getItems: tenantsList } = useTenantContext();
  const { isLoading: isRelLoading, adultItems } = useRelationshipContext();
  const dobRef = useRef(null);
  const sdRef = useRef(null);
  const edRef = useRef(null);
  const { onSubmit, isSubmitting, error, record, origin, type, mode } = props;
  const [values, setValues] = useState(getDefaultState(type === TYPE_STAFF, record));
  const [copyValues] = useState({ ...values });
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const {
    tenantId,
    firstName,
    lastName,
    dateOfBirth,
    pin,
    email,
    mobile,
    homePhone,
    workPhone,
    address,
    address2,
    city,
    zip,
    state,
    isStaffMember,
    startDate,
    endDate,
    isStartDateEstimated,
    isEndDateEstimated,
  } = values;

  const tenantOptions = useMemo(
    () =>
      tenantsList
        .filter((t) => t.orgId === orgId)
        .map((t) => ({
          label: t.name,
          value: t.id,
        })),
    [orgId, tenantsList]
  );

  const dob = dateOfBirth ? moment(dateOfBirth, config.formats.momentDateSystem) : null;
  const sd = startDate ? moment(startDate, config.formats.momentDateSystem) : null;
  const ed = endDate ? moment(endDate, config.formats.momentDateSystem) : null;

  useEffect(() => {
    validationChangeForm(values, copyValues, setDisabledSubmit);
  }, [copyValues, values]);

  const submitProps = useMemo(
    () => ({
      btnClassName: mode === MODE_CREATE ? 'button button--icon--proceed' : 'button',
      actionBtnClassName: 'button button--icon--loading',
      btnText: mode === MODE_CREATE ? 'Save and continue' : 'Save',
      actionText: 'Saving...',
      isActing: isSubmitting,
      isDisabled: disabledSubmit,
    }),
    [disabledSubmit, isSubmitting, mode]
  );

  const canChangeCenter = useMemo(
    () =>
      isRelLoading
        ? false
        : isStaffMember &&
          mode === MODE_UPDATE &&
          tenantOptions.length > 1 &&
          !adultItems.data?.RelByAdult?.items?.length,
    [adultItems.data, isRelLoading, isStaffMember, mode, tenantOptions]
  );

  return (
    <Form onSubmit={() => onSubmit(values)}>
      {!isSubmitting && !!error && (
        <Error
          error={error.errors.reduce((acc, nx) => `${acc ? `${acc}, ` : ``} ${nx.message}`, '')}
          className="form-error-msg"
        />
      )}
      <InputText
        id="firstName"
        value={firstName}
        labelText="First Name"
        isRequired
        onChange={(e) => onFieldChange(values, setValues, e)}
      />
      <InputText
        id="lastName"
        value={lastName}
        labelText="Last Name"
        isRequired
        onChange={(e) => onFieldChange(values, setValues, e)}
      />
      {isStaffMember && (
        <SingleDatePickerWrapper
          id="dateOfBirth"
          labelText="Date of Birth"
          hintText="Please use this format: MM/DD/YYYY"
          placeholder={null}
          onClose={(date) => onDateChange(values, setValues, 'dateOfBirth', date)}
          onChange={(date) => onDateChange(values, setValues, 'dateOfBirth', date)}
          initialDate={dob}
          displayFormat="MMM Do, Y"
          numberOfMonths={2}
          isOutsideRange={(day) => isInclusivelyAfterDay(day, moment())}
          appendToBody={false}
          block
          ref={dobRef}
        />
      )}
      {/* eslint-disable max-len */}
      <InputText
        id="pin"
        value={pin}
        labelText="PIN"
        hintText="This number must be unique and 8 digits long. Please use the first four digits of the person's birth date and 4 additional random, non-sequential numbers."
        onChange={(e) => onFieldChange(values, setValues, e)}
        maxLength={8}
        isRequired
      />

      <fieldset className="fieldset">
        <legend>Contact Information</legend>

        <InputEmail
          id="email"
          value={email}
          labelText="Email Address"
          isRequired
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <InputTel
          id="mobile"
          value={mobile}
          labelText="Mobile"
          hintText="Just the numbers please, no spaces, dashes, or other characters."
          isRequired
          maxlength={10}
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <InputTel
          id="homePhone"
          value={homePhone}
          labelText="Home Phone"
          hintText="Just the numbers please, no spaces, dashes, or other characters."
          maxlength={10}
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <InputTel
          id="workPhone"
          value={workPhone}
          labelText="Work Phone"
          hintText="Just the numbers please, no spaces, dashes, or other characters."
          maxlength={10}
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
      </fieldset>

      <fieldset className="fieldset">
        <legend>Address</legend>

        <InputText
          id="address"
          value={address}
          labelText="Address"
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <InputText
          id="address2"
          value={address2}
          labelText="Apartment, suite, etc."
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <InputText id="city" value={city} labelText="City" onChange={(e) => onFieldChange(values, setValues, e)} />
        <InputText
          id="zip"
          value={zip}
          labelText="ZIP"
          pattern="[0-9]{5}"
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        <InputSelect
          id="state"
          value={state}
          labelText="State"
          onChange={(e) => onFieldChange(values, setValues, e)}
          options={states}
        />
      </fieldset>

      <fieldset className="fieldset">
        <legend>Staff Member</legend>

        <InputCheckbox
          id="isStaffMember"
          labelText="Is staff member?"
          isChecked={isStaffMember}
          onChange={(e) => onFieldChange(values, setValues, e)}
        />
        {isStaffMember && (
          <>
            {mode === MODE_UPDATE && canChangeCenter && (
              <InputSelect
                id="tenantId"
                value={tenantId}
                labelText="Home Center"
                hintText="Payroll is billed to this center by default"
                onChange={(e) => onFieldChange(values, setValues, e)}
                options={tenantOptions}
              />
            )}
            <SingleDatePickerWrapper
              id="startDate"
              labelText="Start Date"
              hintText="Or anticipated start date"
              placeholder={null}
              openDirection={DATE_PICKER_OPEN_DIRECTION.Up}
              onClose={(date) => onDateChange(values, setValues, 'startDate', date)}
              onChange={(date) => onDateChange(values, setValues, 'startDate', date)}
              initialDate={sd}
              displayFormat="MMM Do, Y"
              numberOfMonths={2}
              appendToBody={false}
              block
              ref={sdRef}
            />
            <InputCheckbox
              id="isStartDateEstimated"
              labelText="Anticipated start date"
              isChecked={isStartDateEstimated}
              onChange={(e) => onFieldChange(values, setValues, e)}
            />
            <SingleDatePickerWrapper
              id="endDate"
              labelText="End Date"
              hintText="Or anticipated end date"
              placeholder={null}
              openDirection={DATE_PICKER_OPEN_DIRECTION.Up}
              onClose={(date) => onDateChange(values, setValues, 'endDate', date)}
              onChange={(date) => onDateChange(values, setValues, 'endDate', date)}
              initialDate={ed}
              displayFormat="MMM Do, Y"
              numberOfMonths={2}
              appendToBody={false}
              block
              ref={edRef}
            />
            <InputCheckbox
              id="isEndDateEstimated"
              labelText="Anticipated end date"
              isChecked={isEndDateEstimated}
              onChange={(e) => onFieldChange(values, setValues, e)}
            />
          </>
        )}
      </fieldset>

      <div className="form-actions">
        <Link className="button--text--cancel" to={origin}>
          Cancel
        </Link>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
}

FormAdultPersonalInformation.propTypes = {
  origin: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([MODE_UPDATE, MODE_CREATE]).isRequired,
  type: PropTypes.oneOf([TYPE_STAFF, TYPE_ADULT]).isRequired,
  error: PropTypes.string,
  record: PropTypes.shape(),
};

FormAdultPersonalInformation.defaultProps = {
  error: null,
  record: {},
};

export default FormAdultPersonalInformation;
