import { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'components/layout';
import { ActingSubmitButton } from 'components/buttons';
import { InputText, InputSelect, InputNumber } from 'components/formElements';
import { onFieldChange, isEmpty, validationChangeForm } from 'utils/index';
import { EMPTY_STRING, ADMIN_CLASSROOM } from 'constants/index';

const getState = (classroom) => {
  if (isEmpty(classroom)) {
    return {
      name: '',
      nickName: '',
      minMonths: 0,
      maxMonths: 0,
      ratio: 0,
      maxSize: 0,
      state: '',
      isAdmin: false,
    };
  }
  return {
    ...classroom,
  };
};

const getOptions = (stateClassrooms) =>
  [
    {
      label: EMPTY_STRING,
      value: EMPTY_STRING,
    },
  ].concat(
    stateClassrooms
      .sort((a, b) => a.maxMonths - b.maxMonths)
      .map((it) => {
        const { name, maxSize, ratio } = it;
        return {
          label: name === ADMIN_CLASSROOM ? name : `${name} (1:${ratio}, ${maxSize} max)`,
          value: it.id,
          name,
        };
      })
  );

function ClassroomForm(props) {
  const { isSubmitting, submitFn, classroom, cancelUrl, stateClassrooms } = props;

  const options = useMemo(() => getOptions(stateClassrooms), [stateClassrooms]);

  const formState = useMemo(() => getState(classroom), [classroom]);

  const [values, setValues] = useState(formState);
  const [copyValues] = useState({ ...formState });

  const [disabledSubmit, setDisabledSubmit] = useState(true);

  useEffect(() => {
    validationChangeForm(values, copyValues, setDisabledSubmit);
  }, [copyValues, values]);

  const getSelectValue = useCallback(
    (name) => {
      if (!name) return '';
      const res = options.find((it) => it.name === name);
      return res?.value;
    },
    [options]
  );

  const onAgeRangeChange = useCallback(
    (e) => {
      const val = e.target.value;
      const rec = stateClassrooms.find((it) => it.id === val);
      if (rec) {
        const { id: ratioId, _version, ...rest } = rec;
        setValues({ ...classroom, ...rest, nickName: values.nickName, isAdmin: rec.name === ADMIN_CLASSROOM });
      } else {
        setValues({
          ...getState(classroom),
          nickName: values.nickName,
          isAdmin: false,
        });
      }
    },
    [classroom, stateClassrooms, values.nickName]
  );

  const getMinMax = useMemo(() => {
    const rec = stateClassrooms.find((it) => it.name === values.name);
    if (!rec) return { min: 0, max: 0 };
    return {
      min: rec.minMonths,
      max: rec.maxMonths,
    };
  }, [stateClassrooms, values.name]);

  const submitProps = useMemo(
    () => ({
      btnClassName: 'button',
      actionBtnClassName: 'button button--icon--loading',
      btnText: 'Save',
      actionText: 'Saving...',
      isActing: isSubmitting,
      isDisabled: disabledSubmit,
    }),
    [disabledSubmit, isSubmitting]
  );

  const { id } = useParams();

  return (
    <Form
      onSubmit={() => {
        submitFn(values);
      }}
    >
      <InputText
        id="nickName"
        labelText="Classroom name"
        onChange={(e) => onFieldChange(values, setValues, e)}
        value={values.nickName}
        isRequired
      />
      {!id && (
        <InputSelect
          id="name"
          labelText="Age Range (State mandated)"
          options={options}
          value={getSelectValue(values.name, options)}
          onChange={onAgeRangeChange}
          isRequired
        />
      )}
      {!!values.name && !values.isAdmin && (
        <fieldset className="fieldset">
          <legend>
            Change the below values if you want to split a classroom into two age groups. You cannot go over or under
            the selected Age Range.
          </legend>
          <div className="wrapper--flex">
            <InputNumber
              id="minMonths"
              labelText="Mininum Age (in months)"
              onChange={(e) => onFieldChange(values, setValues, e)}
              value={values.minMonths}
              min={getMinMax.min}
              max={getMinMax.max - 1}
              hasMinMax
            />
            <InputNumber
              id="maxMonths"
              labelText="Maximum Age (in months)"
              onChange={(e) => onFieldChange(values, setValues, e)}
              value={values.maxMonths}
              min={getMinMax.min + 1}
              max={getMinMax.max}
              hasMinMax
            />
          </div>
        </fieldset>
      )}
      <div className="form-actions">
        <Link className="button--text--cancel" to={cancelUrl}>
          Cancel
        </Link>
        <ActingSubmitButton {...submitProps} />
      </div>
    </Form>
  );
}

ClassroomForm.propTypes = {
  submitFn: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  cancelUrl: PropTypes.string.isRequired,
  classroom: PropTypes.shape(),
  stateClassrooms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ClassroomForm.defaultProps = {
  classroom: {
    name: '',
    nickName: '',
    minMonths: 0,
    maxMonths: 0,
    ratio: 0,
    maxSize: 0,
    isAdmin: false,
  },
  isSubmitting: false,
};

export default ClassroomForm;
