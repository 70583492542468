import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useTimesheetMutateContext } from 'context/Timesheet/TimesheetMutateContext';
import { Modal } from 'components/modal';
import { UpdateTimesheetEntryForm } from 'components/form';
import { REPORT_TYPE_ATTENDANCE, StringBoolean } from 'constants/index';
import { stringBooleanToBool } from 'utils/common';

const doUpdateTimesheet = async (data, version, updateFn) => {
  const { id, studentId, adultId, action, actionDetails, manualEntryReason, type, hasSubsidy, dt, tenantId, billTo } =
    data;
  const utcDt = dt.toUTC();
  const submitData = {
    id,
    tenantId,
    billTo,
    ...(type === REPORT_TYPE_ATTENDANCE ? { studentId } : { adultId }),
    action,
    actionDetails,
    date: null,
    time: null,
    dateTime: utcDt.toISO(),
    timeStamp: Math.floor(utcDt.toSeconds()),
    isBestGuess: false,
    isManualEntry: true,
    manualEntryReason,
    isIgnored: StringBoolean.FALSE.value,
    isIgnoredReason: null,
    ...(stringBooleanToBool(hasSubsidy) ? { hasSubsidy } : {}),
    _version: version,
  };
  await updateFn(type, submitData);
};

function UpdateTimesheetEntry(props) {
  const { hide, isShowing, data } = props;
  const { doUpdate, payrollUpdate, attendanceUpdate } = useTimesheetMutateContext();
  const isLoading = payrollUpdate.isLoading || attendanceUpdate.isLoading;
  const isError = payrollUpdate.isError || attendanceUpdate.isError;
  const error = payrollUpdate.error || attendanceUpdate.error;
  const { fullName } = data;

  if (!isShowing) {
    document.body.classList.remove('has-modal');
  }

  return isShowing
    ? ReactDOM.createPortal(
        <Modal isShowing={isShowing} modalTitle="Update Timesheet Entry" onClose={() => hide()}>
          <UpdateTimesheetEntryForm
            doSubmit={async (d) => {
              // eslint-disable-next-line no-underscore-dangle
              await doUpdateTimesheet(d, data._version, doUpdate);
              if (!isError) hide();
            }}
            isLoading={isLoading}
            isError={isError}
            error={error}
            doCancel={() => hide()}
            data={data}
            fullName={fullName}
          />
        </Modal>,
        document.body
      )
    : null;
}

UpdateTimesheetEntry.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
};

export default UpdateTimesheetEntry;
