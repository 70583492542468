import { Authenticator } from '@aws-amplify/ui-react';
import Header from 'components/layout/Header';

import '../../amplifyTheme/styles.css';

function AmplifyAuth() {
  const formFields = {
    signIn: {
      username: {
        labelHidden: false,
        label: 'Username:',
        placeholder: 'Enter your Username:',
      },
      password: {
        labelHidden: false,
        label: 'Password:',
        placeholder: 'Enter your Password:',
      },
    },
    forceNewPassword: {
      password: {
        labelHidden: false,
        label: 'Create a new Password',
        placeholder: 'Enter your Password:',
      },
    },
  };

  return (
    <>
      <Header includeHomeLink={false} classNames={['header--global', 'header--min']} />
      <main className="wrapper--center width--s">
        <Authenticator hideSignUp formFields={formFields} />
      </main>
    </>
  );
}

export default AmplifyAuth;
