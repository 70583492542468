import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header, PageActions, PageHeader, PageTitle, Wrapper, Error } from 'components/layout';
import { Table, TableRowStaff } from 'components/table';
import { NavUser, GlobalNav } from 'components/nav';
import { ROUTE_STAFFMEMBER_CREATE } from 'constants/index';
import { useAdultListContext } from 'context/Adult/AdultListContext';
import { ToggleMenuProvider } from 'context/ToggleMenuContext';
import { TenantDataProvider } from 'context/Tenant/TenantContext';

const headerLabels = [
  {
    label: 'Name',
    className: 'name',
  },
  {
    label: 'Date of Birth',
    className: 'dob',
  },
  {
    label: 'PIN',
    className: 'pin',
  },
  {
    label: 'Locked',
    className: 'locked',
  },
  {
    label: ' ',
    className: 'view',
  },
];

const getPageActions = (referrer, toggleValue, toggleAction) => [
  {
    key: 'toggle-inactive',
    isVisible: true,
    type: 'toggle',
    toggleProps: {
      id: 'toggleInactive',
      isHorizontal: true,
      toggleValue,
      toggleAction,
      label: 'Show Inactive',
    },
  },
  {
    key: 'add-party-staff-member',
    classNames: 'button--action button--icon--add',
    text: 'Add Staff Member',
    isVisible: true,
    isLink: true,
    url: ROUTE_STAFFMEMBER_CREATE,
    referrer,
  },
];

function StaffMembers() {
  const { pathname } = useLocation();
  const { getStaffMembers, isLoading, error, setIsActiveFilter } = useAdultListContext();
  const [toggleValue, setToggleValue] = useState(false);

  const toggleAction = () => {
    if (toggleValue) {
      setToggleValue(false);
      setIsActiveFilter(false);
    } else {
      setToggleValue(true);
      setIsActiveFilter(true);
    }
  };

  const itemRows = getStaffMembers.length ? getStaffMembers.map((it) => <TableRowStaff data={it} key={it.id} />) : [];

  const headerChildren = (
    <TenantDataProvider>
      <ToggleMenuProvider>
        <NavUser />
      </ToggleMenuProvider>
    </TenantDataProvider>
  );
  return (
    <>
      <Header>{headerChildren}</Header>
      <GlobalNav />
      <Wrapper>
        <PageHeader>
          <PageTitle title="Staff Members" />
          <PageActions actions={getPageActions(pathname, toggleValue, toggleAction)} />
        </PageHeader>
        {!!isLoading && <div className="loading" />}
        {!!error && <Error error={error} />}
        {!!getStaffMembers.length && <Table id="staff" headerLabels={headerLabels} rows={itemRows} />}
        {!isLoading && !getStaffMembers.length && (
          <div className="empty">No {toggleValue ? 'inactive' : 'Active'} staff members found</div>
        )}
      </Wrapper>
    </>
  );
}

export default StaffMembers;
