import { useMemo } from 'react';
import { useTenantContext } from 'context/Tenant/TenantContext';
import { useAuthContext } from 'context/AuthContext';
import InputSelect from './InputSelect';

export function CenterSelect(props) {
  const { orgId } = useAuthContext();
  const { getItems } = useTenantContext();

  const options = useMemo(
    () =>
      getItems
        .filter((it) => it.orgId === orgId)
        .map((it) => ({
          label: it.name,
          value: it.id,
        })),
    [getItems, orgId]
  );

  return <InputSelect {...props} options={options} />;
}
