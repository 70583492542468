import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputField from './InputField';

class InputText extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    hintText: PropTypes.string,
    errorText: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    pattern: PropTypes.string,
  };

  static defaultProps = {
    hintText: null,
    placeholder: null,
    value: undefined,
    errorText: null,
    isRequired: false,
    isDisabled: false,
    onChange: null,
    pattern: null,
  };

  render() {
    return <InputField {...this.props} type="text" />;
  }
}

export default InputText;
