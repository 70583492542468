import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTenantContext } from 'context/Tenant/TenantContext';
import { useAdultListContext } from 'context/Adult/AdultListContext';
import { EMPTY_STRING, StringBoolean } from 'constants/index';
import InputSelect from './InputSelect';

// const getFilteredStaffMembers = (keys, items) =>
//   keys.filter((it) => {
//     const r = items[it];
//     return r.isStaffMember;
//   });

// const getFilteredRelated = (keys, relatedIds) => keys.filter((it) => relatedIds.includes(it));

const empty = [{ label: EMPTY_STRING, value: EMPTY_STRING }];

const abbrs = {};

const getAbbr = (tenantId, tenantList) => {
  if (abbrs[tenantId]) return abbrs[tenantId];
  const fullTenantName = (tenantList || []).find((it) => it.id === tenantId)?.name ?? '';
  const suffix =
    fullTenantName
      ?.replace(/[()]/gi, '')
      .split(/\s/)
      .map((it) => it[0])
      .join('') ?? '';
  abbrs[tenantId] = `(${suffix})`;
  return abbrs[tenantId];
};
export function AdultSelect(props) {
  const { getAll, getStaffMembers, getOrgStaffMembers } = useAdultListContext();
  const { getItems: tenants } = useTenantContext();
  const { showInactiveParties, filterStaffMembers, includeOrgStaffMembers } = props;

  const options = useMemo(() => {
    const filtered = filterStaffMembers ? getStaffMembers : getAll;
    const byTenant = includeOrgStaffMembers ? getOrgStaffMembers : filtered;

    const values = showInactiveParties
      ? byTenant
      : byTenant.filter((it) => it.isArchived === StringBoolean.FALSE.value);

    return values
      .map((it) => ({
        label: includeOrgStaffMembers ? `${it.fullName} ${getAbbr(it.tenantId, tenants)}` : it.fullName,
        value: it.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [
    filterStaffMembers,
    getAll,
    getOrgStaffMembers,
    getStaffMembers,
    includeOrgStaffMembers,
    showInactiveParties,
    tenants,
  ]);

  return <InputSelect {...props} options={empty.concat(options)} />;
}

AdultSelect.propTypes = {
  includeOrgStaffMembers: PropTypes.bool,
  filterStaffMembers: PropTypes.bool,
  filterRelated: PropTypes.bool,
  showInactiveParties: PropTypes.bool,
  relatedIds: PropTypes.arrayOf(PropTypes.string),
};

AdultSelect.defaultProps = {
  includeOrgStaffMembers: false,
  filterStaffMembers: false,
  filterRelated: false,
  showInactiveParties: false,
  relatedIds: [],
};
