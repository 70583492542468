import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PageHeader extends PureComponent {
  static propTypes = {
    isSticky: PropTypes.bool,
    children: PropTypes.node.isRequired,
    classNames: PropTypes.string,
  };

  static defaultProps = {
    isSticky: false,
    classNames: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isStuck: false,
    };
  }

  componentDidMount() {
    const { isSticky } = this.props;
    if (isSticky) window.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount() {
    const { isSticky } = this.props;
    if (isSticky) window.removeEventListener('scroll', this.scrollListener);
  }

  scrollListener = () => {
    window.requestAnimationFrame(this.handleScroll);
  };

  handleScroll = () => {
    const { isStuck } = this.state;
    if (window.scrollY >= 278 && !isStuck) {
      this.setState(() => ({
        isStuck: true,
      }));
    } else if (window.scrollY <= 277 && isStuck) {
      this.setState(() => ({
        isStuck: false,
      }));
    }
  };

  render() {
    const { isSticky, children, classNames } = this.props;
    const { isStuck } = this.state;
    const joinedClassNames = ['page-header'];
    if (isSticky) joinedClassNames.push('sticky');
    if (isStuck) joinedClassNames.push('stuck');
    if (classNames) joinedClassNames.push(`${classNames}`);
    return <section className={joinedClassNames.join(' ')}>{children}</section>;
  }
}

export default PageHeader;
