import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableRow from 'components/table/TableRow';
import { Link } from 'react-router-dom';
import { useContactMutateContext } from 'context/Contact/ContactMutateContext';
import { Button } from 'components/buttons';
import { useModal } from 'utils/useModal';
import { ConfirmModal } from 'components/modal';
import { Error } from 'components/layout';
import { ContactType, ROUTE_STAFFMEMBER_VIEW, ROUTE_ADULT_VIEW, TYPE_ADULT, TYPE_STAFF } from 'constants/index';
import { isEmpty, stringBooleanToBool } from 'utils/index';
import { formatNumber } from 'utils/phoneNumbers';

function TableRowEmergencyContact(props) {
  const { data } = props;
  const { isShowing, toggle } = useModal();
  const { archive, doArchive } = useContactMutateContext();
  const { isSuccess, isLoading, isError, error } = archive;

  const { id, contactType, relationship, adult, adHocContact, studentId } = data;

  const isAdHoc = useMemo(() => contactType === ContactType.AdHoc, [contactType]);

  const adultId = useMemo(() => adult?.id ?? null, [adult]);

  const personType = useMemo(
    // eslint-disable-next-line no-nested-ternary
    () => (isAdHoc ? null : stringBooleanToBool(adult.isStaffMember) ? TYPE_STAFF : TYPE_ADULT),
    [adult, isAdHoc]
  );

  const name = useMemo(() => {
    const { firstName, lastName } = isAdHoc ? adHocContact : adult;
    return `${lastName}, ${firstName}`;
  }, [adHocContact, adult, isAdHoc]);

  const mobile = useMemo(() => {
    const { mobile: m } = isAdHoc ? adHocContact : adult;
    return formatNumber(m);
  }, [adHocContact, adult, isAdHoc]);

  const homePhone = useMemo(() => {
    const { homePhone: hP } = isAdHoc ? adHocContact : adult;
    return formatNumber(hP);
  }, [adHocContact, adult, isAdHoc]);

  const workPhone = useMemo(() => {
    const { workPhone: wP } = isAdHoc ? adHocContact : adult;
    return formatNumber(wP);
  }, [adHocContact, adult, isAdHoc]);

  const getLinkUrl = useMemo(() => {
    if (!personType) return null;

    if (personType === TYPE_STAFF) {
      return `${ROUTE_STAFFMEMBER_VIEW}${adultId}/`;
    }
    return `${ROUTE_ADULT_VIEW}${adultId}/`;
  }, [adultId, personType]);

  return (
    <>
      {isError && <Error error={error?.errors.reduce((acc, nx) => `${acc}\n${nx.message}`, '')} />}
      <TableRow>
        <li className="tc--name">{contactType === ContactType.Adult ? <Link to={getLinkUrl}>{name}</Link> : name}</li>
        <li className="tc--relationship">{relationship}</li>
        <li className="tc--contacts">
          <span className="contact">
            <span className="label">Mobile:</span>&nbsp;
            {isEmpty(mobile.formatted) && 'N/A'}
            {!isEmpty(mobile.formatted) && (
              <a className="link" href={mobile.uri}>
                {mobile.formatted}
              </a>
            )}
          </span>
          <span className="contact">
            <span className="label">Home:</span>&nbsp;
            {isEmpty(homePhone.formatted) && 'N/A'}
            {!isEmpty(homePhone.formatted) && (
              <a className="link" href={homePhone.uri}>
                {homePhone.formatted}
              </a>
            )}
          </span>
          <span className="contact">
            <span className="label">Work:</span>&nbsp;
            {isEmpty(workPhone.formatted) && 'N/A'}
            {!isEmpty(workPhone.formatted) && (
              <a className="link" href={workPhone.uri}>
                {workPhone.formatted}
              </a>
            )}
          </span>
        </li>
        <li className="tc--edit">
          {contactType === ContactType.AdHoc && (
            <Link className="button--text" to={`/student/${studentId}/emergency-contact/${id}/update`}>
              Edit
            </Link>
          )}
        </li>
        <li className="tc--remove">
          <Button className="button--text--neg" onClick={toggle}>
            Remove
          </Button>
        </li>
      </TableRow>
      <ConfirmModal
        isShowing={isShowing}
        title="Remove Emergency Contact"
        content={`Are you sure you want to remove ${name} from this child's record?`}
        isRequesting={isLoading}
        doAction={() => {
          doArchive(data);
          if (isSuccess) {
            toggle();
          }
        }}
        hide={toggle}
        confirmLabel="Remove Emergency Contact"
        cancelLabel="Cancel"
      />
    </>
  );
}

TableRowEmergencyContact.propTypes = {
  data: PropTypes.shape(),
};

TableRowEmergencyContact.defaultProps = {
  data: {},
};

export default TableRowEmergencyContact;
