import PropTypes from 'prop-types';
import InputField from './InputField';

function InputNumber(props) {
  return <InputField {...props} type="number" />;
}

InputNumber.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hintText: PropTypes.string,
  errorText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  hasMinMax: PropTypes.bool,
};

InputNumber.defaultProps = {
  hintText: null,
  placeholder: null,
  value: undefined,
  errorText: null,
  isRequired: false,
  isDisabled: false,
  onChange: null,
  hasMinMax: false,
  min: 0,
  max: 0,
  maxLength: 0,
};

export default InputNumber;
