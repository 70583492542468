import React, { Component } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import omit from 'lodash/fp/omit';
import { DateRangePicker } from 'react-dates';
import { DateRangePickerPhrases } from 'react-dates/src/defaultPhrases';
import DateRangePickerShape from 'react-dates/src/shapes/DateRangePickerShape';
import { DATE_PICKER_OPEN_DIRECTION } from 'constants/index';

const START_DATE = 'startDate';
const END_DATE = 'endDate';
const HORIZONTAL_ORIENTATION = 'horizontal';
const ANCHOR_LEFT = 'left';

/* eslint-disable react/default-props-match-prop-types */
class DateRangePickerWrapper extends Component {
  static propTypes = {
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    labelText: PropTypes.string,
    hintText: PropTypes.string,
    openDirection: PropTypes.string,
    appendToBody: PropTypes.bool,
    ...omit(
      [
        'initialStartDate',
        'initialEndDate',
        'required',
        'disabled',
        'onDatesChange',
        'onFocusChange',
        'appendToBody',
        'openDirection',
      ],
      DateRangePickerShape
    ),
  };

  static defaultProps = {
    initialStartDate: null,
    initialEndDate: null,
    isRequired: false,
    isDisabled: false,
    hintText: null,
    labelText: 'Date Range',
    // input related props
    startDateId: START_DATE,
    startDatePlaceholderText: 'Start Date',
    endDateId: END_DATE,
    endDatePlaceholderText: 'End Date',
    screenReaderInputMessage: '',
    showClearDates: true,
    showDefaultInputIcon: true,
    customInputIcon: null,
    inputIconPosition: 'after',
    customArrowIcon: null,
    customCloseIcon: null,
    block: false,
    small: false,
    regular: true,
    noBorder: false,
    // calendar presentation and interaction related props
    renderMonthText: null,
    orientation: HORIZONTAL_ORIENTATION,
    anchorDirection: ANCHOR_LEFT,
    openDirection: DATE_PICKER_OPEN_DIRECTION.Down,
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    appendToBody: true,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDates: true,
    isRTL: false,
    hideKeyboardShortcutsPanel: true,
    // navigation related props
    navPrev: null,
    navNext: null,
    onPrevMonthClick: () => {},
    onNextMonthClick: () => {},
    onClose: () => {},
    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 1,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isOutsideRange: () => false,
    isDayHighlighted: () => false,
    // internationalization
    displayFormat: () => moment.localeData().longDateFormat('L'),
    monthFormat: 'MMMM YYYY',
    phrases: DateRangePickerPhrases,
    stateDateWrapper: (date) => date,
  };

  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
    };
  }

  onDatesChange = ({ startDate, endDate }) => {
    const { stateDateWrapper } = this.props;
    this.setState((prevState) => ({
      ...prevState,
      startDate: startDate && stateDateWrapper(startDate),
      endDate: endDate && stateDateWrapper(endDate),
    }));
  };

  onFocusChange = (focusedInput) => {
    this.setState((prevState) => ({
      ...prevState,
      focusedInput,
    }));
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  reset = () => {
    this.setState(() => ({
      focusedInput: null,
      startDate: null,
      endDate: null,
    }));
  };

  render() {
    const { isRequired, isDisabled, startDateId, labelText, hintText, openDirection } = this.props;
    const { startDate, endDate, focusedInput } = this.state;
    const props = omit(
      [
        'initialStartDate',
        'initialEndDate',
        'stateDateWrapper',
        'isRequired',
        'isDisabled',
        'labelText',
        'hintText',
        'onChange',
      ],
      this.props
    );
    const classNames = ['form-element', `form-element--daterange`];
    if (isRequired) classNames.push('form-element--required');
    if (isDisabled) classNames.push('form-element--disabled');
    if (openDirection === DATE_PICKER_OPEN_DIRECTION.Up) {
      classNames.push('direction--up');
    }

    return (
      <div className={classNames.join(' ')}>
        <label className="label" htmlFor={startDateId}>
          {labelText}
          {isRequired && <span className="required">*</span>}
        </label>
        {hintText && <span className="form-element-hint">{hintText}</span>}
        <DateRangePicker
          {...props}
          focusedInput={focusedInput}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          startDate={startDate}
          endDate={endDate}
          required={isRequired}
          disabled={isDisabled}
        />
      </div>
    );
  }
}
/* eslint-enable */

export default DateRangePickerWrapper;
