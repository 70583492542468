import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { stringToMoment, getFormattedDate } from 'utils/index';
import { ROUTE_STUDENT_VIEW, StringBoolean } from 'constants/index';
import { useStudentListContext } from 'context/Student/StudentListContext';
import TableRow from './TableRow';

const getSubsidyText = (hasSubsidy) => {
  if (hasSubsidy === StringBoolean.TRUE.value) return StringBoolean.TRUE.label;
  return StringBoolean.FALSE.label;
};

function TableRowStudent(props) {
  const { setIsActiveFilter } = useStudentListContext();
  const { data } = props;
  const { id, firstName, lastName, dateOfBirth, hasSubsidy } = data;
  const hasData = Object.keys(data).length;
  return hasData ? (
    <TableRow key={id}>
      <li className="tc--name">
        {lastName}, {firstName}
      </li>
      <li className="tc--dob">{getFormattedDate(stringToMoment(dateOfBirth))}</li>
      <li className="tc--subsidized">{getSubsidyText(hasSubsidy)}</li>
      <li className="tc--view">
        <Link className="button--text" to={`${ROUTE_STUDENT_VIEW}${id}/`} onClick={() => setIsActiveFilter(false)}>
          View
        </Link>
      </li>
    </TableRow>
  ) : null;
}

TableRowStudent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    dateOfBirth: PropTypes.string,
    hasSubsidy: PropTypes.string,
  }),
};

TableRowStudent.defaultProps = {
  data: {},
};

export default TableRowStudent;
