import React, { Component } from 'react';
import PropTypes from 'prop-types';

const BUTTON_TEXT_SHOW = 'Show';
const BUTTON_TEXT_HIDE = 'Hide';

class InputPassword extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    labelText: PropTypes.string,
    hintText: PropTypes.string,
    errorText: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    hasGlobalError: PropTypes.bool,
    showByDefault: PropTypes.bool,
    shouldCheckStrength: PropTypes.bool,
    strengthState: PropTypes.string,
    strengthClass: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    placeholder: null,
    labelText: null,
    hintText: null,
    errorText: null,
    isRequired: false,
    isDisabled: false,
    showByDefault: false,
    shouldCheckStrength: false,
    strengthState: null,
    strengthClass: null,
    onChange: null,
    hasGlobalError: false,
  };

  constructor(props) {
    super(props);
    /* eslint-disable react/destructuring-assignment */
    this.state = {
      isShowingPassword: this.props.showByDefault,
      btnText: BUTTON_TEXT_SHOW,
    };
  }

  onShowButtonClick = () => {
    this.setState((prevState) => {
      const { isShowingPassword } = prevState;
      return {
        isShowingPassword: !isShowingPassword,
        btnText: !isShowingPassword ? BUTTON_TEXT_HIDE : BUTTON_TEXT_SHOW,
      };
    });
  };

  handleChange = (e) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(e);
    }
  };

  /* eslint-disable jsx-a11y/label-has-for */
  render() {
    const {
      id,
      placeholder,
      labelText,
      hintText,
      errorText,
      isRequired,
      isDisabled,
      hasGlobalError,
      shouldCheckStrength,
      strengthState,
      strengthClass,
    } = this.props;
    const { isShowingPassword, btnText } = this.state;
    const classNames = ['form-element', 'form-element--password'];
    if (hasGlobalError) classNames.push('form-element--error');
    if (isRequired) classNames.push('form-element--required');
    if (shouldCheckStrength) classNames.push('strength-bar');
    if (strengthClass) classNames.push(strengthClass);
    const inputProps = {
      id,
      name: id,
      type: isShowingPassword ? 'text' : 'password',
      required: isRequired,
      disabled: isDisabled,
      onChange: this.handleChange,
      placeholder: placeholder || undefined,
      ...(hintText && { 'aria-describedby': `${id}-hint` }),
    };
    return (
      <div className={classNames.join(' ')}>
        {labelText && (
          <label className="label" htmlFor={id} id={`${id}-label`}>
            {labelText}
          </label>
        )}
        {hintText && (
          <span id={`${id}-hint`} className="form-element-hint">
            {hintText}
          </span>
        )}
        {errorText && <span className="form-element-error-msg">{errorText}</span>}
        <button className="show-password" type="button" onClick={this.onShowButtonClick}>
          {btnText}
        </button>
        <input {...inputProps} />
        {shouldCheckStrength && <span className="password-strength-state">{strengthState}</span>}
      </div>
    );
  }
  /* eslint-enable */
}

export default InputPassword;
