import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/fp/uniqBy';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { classroomByTenant, staffDayByClassroom, studentDayByClassroom } from 'graphql/customQueries';
import { createClassroom, updateClassroom } from 'graphql/customMutations';
import { cacheKeys } from 'conf';
import { StringBoolean, ADMIN_CLASSROOM } from 'constants/index';
import { boolToStringBoolean } from 'utils/index';
import { useAuthContext } from '../AuthContext';

const ClassroomContext = React.createContext({
  getItems: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
});

export function ClassroomDataProvider({ children }) {
  const { isAuthenticated, tenantId, orgId } = useAuthContext();
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    isSuccess,
    error,
    data: response,
  } = useQuery(
    [cacheKeys.getClassrooms, tenantId],
    () =>
      API.graphql(
        graphqlOperation(classroomByTenant, {
          tenantId,
          isArchived: { eq: StringBoolean.FALSE.value },
          limit: 10000,
        })
      ),
    {
      enabled: isAuthenticated && !!tenantId,
    }
  );

  const classrooms = useMemo(() => (isSuccess ? response.data?.ClassroomByTenant.items : []), [isSuccess, response]);

  const {
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
    isError: isCreateError,
    error: createError,
    mutateAsync: create,
  } = useMutation((data) => API.graphql(graphqlOperation(createClassroom, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getClassrooms, tenantId]),
  });

  const doCreate = useCallback(
    async (data) => {
      const { state, name, ...rest } = data;
      await create({
        ...rest,
        name,
        tenantId,
        orgId,
        isArchived: boolToStringBoolean(false),
        isAdmin: name === ADMIN_CLASSROOM,
      });
    },
    [create, orgId, tenantId]
  );

  const {
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
    mutateAsync: update,
  } = useMutation((data) => API.graphql(graphqlOperation(updateClassroom, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getClassrooms, tenantId]),
  });

  const doUpdate = useCallback(
    async (data) => {
      const { state, name, ...rest } = data;
      await update({
        ...rest,
        name,
        isAdmin: name === ADMIN_CLASSROOM,
      });
    },
    [update]
  );

  const doArchive = useCallback(
    async (data) => {
      await update({
        ...data,
        isArchived: boolToStringBoolean(true),
      });
    },
    [update]
  );

  const [isCheckingActive, setIsCheckingActive] = useState(false);
  const [isCheckingActiveSuccess, setIsCheckingActiveSuccess] = useState(false);
  const [isCheckingActiveError, setIsCheckingActiveError] = useState(false);
  const [activeCheckError, setActiveCheckError] = useState(null);
  const [activeCheckData, setActiveCheckData] = useState(null);

  const doActiveCheck = useCallback(
    async (classroomId) => {
      setIsCheckingActive(true);
      try {
        const staffResults = await API.graphql(
          graphqlOperation(staffDayByClassroom, {
            tenantId,
            classroomId: { eq: classroomId },
            limit: 10000,
          })
        );
        const studentResults = await API.graphql(
          graphqlOperation(studentDayByClassroom, {
            tenantId,
            classroomId: { eq: classroomId },
            limit: 10000,
          })
        );
        const staffItems = uniqBy(
          'schedule.adultId',
          staffResults.data.StaffDayByClassroom.items.filter(
            (it) => it.schedule.isArchived === StringBoolean.FALSE.value
          )
        );
        const studentItems = uniqBy(
          'schedule.studentId',
          studentResults.data.StudentDayByClassroom.items.filter(
            (it) => it.schedule.isArchived === StringBoolean.FALSE.value
          )
        );
        const resultCount = staffItems.length + studentItems.length;
        setActiveCheckData({
          isAssigned: !!resultCount,
          staffResults: staffItems,
          studentResults: studentItems,
        });
        setIsCheckingActiveSuccess(true);
        setIsCheckingActive(false);
      } catch (e) {
        setIsCheckingActiveError(true);
        setActiveCheckError(e);
        setIsCheckingActive(false);
      }
    },
    [tenantId]
  );

  const value = useMemo(
    () => ({
      isLoading,
      isError,
      isSuccess,
      error,
      getItems: classrooms,
      doCreate,
      doUpdate,
      doArchive,
      create: {
        isSuccess: isCreateSuccess,
        isLoading: isCreateLoading,
        isError: isCreateError,
        error: createError,
      },
      update: {
        isSuccess: isUpdateSuccess,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
      },
      doActiveCheck,
      activeCheck: {
        data: activeCheckData,
        isSuccess: isCheckingActiveSuccess,
        isLoading: isCheckingActive,
        isError: isCheckingActiveError,
        error: activeCheckError,
      },
    }),
    [
      activeCheckData,
      activeCheckError,
      classrooms,
      createError,
      doActiveCheck,
      doArchive,
      doCreate,
      doUpdate,
      error,
      isCheckingActive,
      isCheckingActiveError,
      isCheckingActiveSuccess,
      isCreateError,
      isCreateLoading,
      isCreateSuccess,
      isError,
      isLoading,
      isSuccess,
      isUpdateError,
      isUpdateLoading,
      isUpdateSuccess,
      updateError,
    ]
  );

  return <ClassroomContext.Provider value={value}>{children}</ClassroomContext.Provider>;
}

ClassroomDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useClassroomContext = () => React.useContext(ClassroomContext);
