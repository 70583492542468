import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Button, ActingSubmitButton } from 'components/buttons';
import { Modal } from 'components/modal';
import { Error } from 'components/layout';

export function ConfirmModal(props) {
  const {
    doAction,
    onClose,
    isShowing,
    hide,
    isRequesting,
    error,
    title,
    content,
    disableConfirmation,
    confirmLabel,
    confirmActingLabel,
    cancelLabel,
    confirmBtnClassName,
  } = props;

  if (!isShowing) {
    document.body.classList.remove('has-modal');
  }

  const submitProps = {
    btnClassName: confirmBtnClassName,
    actionBtnClassName: `${confirmBtnClassName} button--icon--loading`,
    btnText: confirmLabel,
    actionText: confirmActingLabel,
    isActing: isRequesting,
    isDisabled: disableConfirmation || isRequesting,
  };

  return isShowing
    ? ReactDOM.createPortal(
        <Modal isShowing={isShowing} modalTitle={title} onClose={onClose}>
          {!!error && <Error error={error} />}
          <div>{content}</div>
          <div className="modal-actions">
            <Button
              className="button--text--cancel"
              onClick={() => {
                hide();
              }}
              isDisabled={isRequesting}
            >
              {cancelLabel}
            </Button>

            <ActingSubmitButton
              {...submitProps}
              onClick={(e) => {
                e.preventDefault();
                doAction();
              }}
            >
              {confirmLabel}
            </ActingSubmitButton>
          </div>
        </Modal>,
        document.body
      )
    : null;
}

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  doAction: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  disableConfirmation: PropTypes.bool,
  error: PropTypes.string,
  onClose: PropTypes.func,
  confirmLabel: PropTypes.string,
  confirmActingLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmBtnClassName: PropTypes.string,
};

ConfirmModal.defaultProps = {
  error: null,
  onClose: null,
  disableConfirmation: false,
  confirmLabel: 'Yes',
  cancelLabel: 'No',
  confirmActingLabel: 'Saving...',
  confirmBtnClassName: 'button--action--neg',
};
