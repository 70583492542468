import { QueryClientProvider } from 'react-query';
import { Authenticator } from '@aws-amplify/ui-react';
import { AuthProvider } from 'context/AuthContext';
import { AppProvider } from 'context/AppContext';
import { TenantDataProvider } from 'context/Tenant/TenantContext';
import { PartyListProviders } from 'context/PartyListProviders';
import { client } from 'clients/defaultClient';
import { AppLauncher } from './AppLauncher';

function Root() {
  return (
    <QueryClientProvider client={client}>
      <Authenticator.Provider>
        <AppProvider>
          <AuthProvider>
            <TenantDataProvider>
              <PartyListProviders>
                <AppLauncher />
              </PartyListProviders>
            </TenantDataProvider>
          </AuthProvider>
        </AppProvider>
      </Authenticator.Provider>
    </QueryClientProvider>
  );
}

export default Root;
