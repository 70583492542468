export const createStudent = /* GraphQL */ `
  mutation UpdateStudent($input: CreateStudentInput!, $condition: ModelStudentConditionInput) {
    createStudent(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent($input: UpdateStudentInput!, $condition: ModelStudentConditionInput) {
    updateStudent(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createAdult = /* GraphQL */ `
  mutation CreateAdult($input: CreateAdultInput!, $condition: ModelAdultConditionInput) {
    createAdult(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateAdult = /* GraphQL */ `
  mutation UpdateAdult($input: UpdateAdultInput!, $condition: ModelAdultConditionInput) {
    updateAdult(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant($input: UpdateTenantInput!, $condition: ModelTenantConditionInput) {
    updateTenant(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createClassroom = /* GraphQL */ `
  mutation CreateClassroom($input: CreateClassroomInput!, $condition: ModelClassroomConditionInput) {
    createClassroom(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateClassroom = /* GraphQL */ `
  mutation UpdateClassroom($input: UpdateClassroomInput!, $condition: ModelClassroomConditionInput) {
    updateClassroom(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createOperatingHourDay = /* GraphQL */ `
  mutation CreateOperatingHourDay(
    $input: CreateOperatingHourDayInput!
    $condition: ModelOperatingHourDayConditionInput
  ) {
    createOperatingHourDay(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateOperatingHourDay = /* GraphQL */ `
  mutation UpdateOperatingHourDay(
    $input: UpdateOperatingHourDayInput!
    $condition: ModelOperatingHourDayConditionInput
  ) {
    updateOperatingHourDay(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createRelative = /* GraphQL */ `
  mutation CreateRelative($input: CreateRelativeInput!, $condition: ModelRelativeConditionInput) {
    createRelative(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateRelative = /* GraphQL */ `
  mutation UpdateRelative($input: UpdateRelativeInput!, $condition: ModelRelativeConditionInput) {
    updateRelative(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createSibling = /* GraphQL */ `
  mutation CreateSibling($input: CreateSiblingInput!, $condition: ModelSiblingConditionInput) {
    createSibling(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateSibling = /* GraphQL */ `
  mutation UpdateSibling($input: UpdateSiblingInput!, $condition: ModelSiblingConditionInput) {
    updateSibling(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createRelationship = /* GraphQL */ `
  mutation CreateRelationship($input: CreateRelationshipInput!, $condition: ModelRelationshipConditionInput) {
    createRelationship(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateRelationship = /* GraphQL */ `
  mutation UpdateRelationship($input: UpdateRelationshipInput!, $condition: ModelRelationshipConditionInput) {
    updateRelationship(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createAttendanceTimesheet = /* GraphQL */ `
  mutation CreateAttendanceTimesheet(
    $input: CreateAttendanceTimesheetInput!
    $condition: ModelAttendanceTimesheetConditionInput
  ) {
    createAttendanceTimesheet(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateAttendanceTimesheet = /* GraphQL */ `
  mutation UpdateAttendanceTimesheet(
    $input: UpdateAttendanceTimesheetInput!
    $condition: ModelAttendanceTimesheetConditionInput
  ) {
    updateAttendanceTimesheet(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createPayrollTimesheet = /* GraphQL */ `
  mutation CreatePayrollTimesheet(
    $input: CreatePayrollTimesheetInput!
    $condition: ModelPayrollTimesheetConditionInput
  ) {
    createPayrollTimesheet(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updatePayrollTimesheet = /* GraphQL */ `
  mutation UpdatePayrollTimesheet(
    $input: UpdatePayrollTimesheetInput!
    $condition: ModelPayrollTimesheetConditionInput
  ) {
    updatePayrollTimesheet(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStudentDay = /* GraphQL */ `
  mutation CreateStudentDay($input: CreateStudentDayInput!, $condition: ModelStudentDayConditionInput) {
    createStudentDay(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateStudentDay = /* GraphQL */ `
  mutation UpdateStudentDay($input: UpdateStudentDayInput!, $condition: ModelStudentDayConditionInput) {
    updateStudentDay(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStaffDay = /* GraphQL */ `
  mutation CreateStaffDay($input: CreateStaffDayInput!, $condition: ModelStaffDayConditionInput) {
    createStaffDay(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateStaffDay = /* GraphQL */ `
  mutation UpdateStaffDay($input: UpdateStaffDayInput!, $condition: ModelStaffDayConditionInput) {
    updateStaffDay(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStudentSchedule = /* GraphQL */ `
  mutation CreateStudentSchedule($input: CreateStudentScheduleInput!, $condition: ModelStudentScheduleConditionInput) {
    createStudentSchedule(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateStudentSchedule = /* GraphQL */ `
  mutation UpdateStudentSchedule($input: UpdateStudentScheduleInput!, $condition: ModelStudentScheduleConditionInput) {
    updateStudentSchedule(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStaffSchedule = /* GraphQL */ `
  mutation CreateStaffSchedule($input: CreateStaffScheduleInput!, $condition: ModelStaffScheduleConditionInput) {
    createStaffSchedule(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateStaffSchedule = /* GraphQL */ `
  mutation UpdateStaffSchedule($input: UpdateStaffScheduleInput!, $condition: ModelStaffScheduleConditionInput) {
    updateStaffSchedule(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStudentPlannedAbsence = /* GraphQL */ `
  mutation CreateStudentPlannedAbsence(
    $input: CreateStudentPlannedAbsenceInput!
    $condition: ModelStudentPlannedAbsenceConditionInput
  ) {
    createStudentPlannedAbsence(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateStudentPlannedAbsence = /* GraphQL */ `
  mutation UpdateStudentPlannedAbsence(
    $input: UpdateStudentPlannedAbsenceInput!
    $condition: ModelStudentPlannedAbsenceConditionInput
  ) {
    updateStudentPlannedAbsence(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStaffPlannedAbsence = /* GraphQL */ `
  mutation CreateStaffPlannedAbsence(
    $input: CreateStaffPlannedAbsenceInput!
    $condition: ModelStaffPlannedAbsenceConditionInput
  ) {
    createStaffPlannedAbsence(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateStaffPlannedAbsence = /* GraphQL */ `
  mutation UpdateStaffPlannedAbsence(
    $input: UpdateStaffPlannedAbsenceInput!
    $condition: ModelStaffPlannedAbsenceConditionInput
  ) {
    updateStaffPlannedAbsence(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createContact = /* GraphQL */ `
  mutation CreateContact($input: CreateContactInput!, $condition: ModelContactConditionInput) {
    createContact(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateContact = /* GraphQL */ `
  mutation UpdateContact($input: UpdateContactInput!, $condition: ModelContactConditionInput) {
    updateContact(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createAdHocContact = /* GraphQL */ `
  mutation CreateAddHocContact($input: CreateAddHocContactInput!, $condition: ModelAddHocContactConditionInput) {
    createAddHocContact(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateAdHocContact = /* GraphQL */ `
  mutation UpdateAddHocContact($input: UpdateAddHocContactInput!, $condition: ModelAddHocContactConditionInput) {
    updateAddHocContact(input: $input, condition: $condition) {
      id
    }
  }
`;
