import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

class ActingSubmitButton extends PureComponent {
  static propTypes = {
    isActing: PropTypes.bool.isRequired,
    btnText: PropTypes.string,
    actionText: PropTypes.string,
    className: PropTypes.string,
    actionClassName: PropTypes.string,
    btnClassName: PropTypes.string,
    actionBtnClassName: PropTypes.string,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    btnText: 'Submit',
    actionText: 'Submitting...',
    className: 'form-element',
    actionClassName: 'form-element',
    btnClassName: 'btn btn-action',
    actionBtnClassName: 'button button--icon--loading',
    isDisabled: false,
  };

  render() {
    const {
      isActing,
      isDisabled,
      btnText,
      actionText,
      className,
      actionClassName,
      btnClassName,
      actionBtnClassName,
      ...props
    } = this.props;
    return (
      <div className={!isActing ? className : actionClassName}>
        <Button
          {...props}
          btnType="submit"
          isDisabled={isDisabled}
          className={!isActing ? btnClassName : actionBtnClassName}
        >
          {!isActing ? btnText : actionText}
        </Button>
      </div>
    );
  }
}

export default ActingSubmitButton;
