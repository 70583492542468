import { DateTime } from 'luxon';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import groupBy from 'lodash/fp/groupBy';
import { round2Precision, isEmpty, getInterimId, timesheetActionLabel } from 'utils/index';
import { OTHER_START, OTHER_END, REPORT_TYPE_ATTENDANCE, StringBoolean, TimesheetAction } from 'constants/index';
import config from 'conf/index';

const moment = extendMoment(Moment);

const sortByTimeStamp = (a, b) => a.timeStamp - b.timeStamp;

// const sortByFullname = (a, b) => a.fullName - b.fullName;

const getActionLabel = (action, hasDetails) => {
  if (hasDetails) {
    return action === TimesheetAction.CHECKED_IN.value ? OTHER_START : OTHER_END;
  }
  return timesheetActionLabel(action);
};

const mapEntries = (e, i, list, type) => {
  const { action, actionDetails, dateTime, isIgnoredReason, isBlank } = e;
  const previous = list[i - 1];
  const dtObj = DateTime.fromISO(dateTime).setZone(config.timeZone);
  const dateFmt = dtObj.toFormat('ccc LL/dd/yyyy');
  const timeFmt = dtObj.toFormat('h:mm a');
  if (
    previous &&
    previous.action === TimesheetAction.CHECKED_IN.value &&
    action === TimesheetAction.CHECKED_OUT.value
  ) {
    const { dateTime: currentDateTime } = e;
    const { dateTime: previousDateTime } = previous;
    const cO = moment(currentDateTime);
    const cI = moment(previousDateTime);
    const duration = moment.duration(cO.diff(cI));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const totalHours = round2Precision(days * 24 + hours + (minutes === 0 ? 0 : minutes / 60), 1);
    const m = moment(DateTime.fromISO(dateTime).setZone(config.timeZone).toISO({ includeOffset: false }));
    return {
      ...e,
      isIgnoredReason: isIgnoredReason || null,
      actionLabel: getActionLabel(action, !isEmpty(actionDetails)),
      totalHours,
      m,
      isBlank: isBlank || false,
      type,
      dateFmt,
      timeFmt,
      isRoaming: e.tenantId !== e.billTo,
    };
  }
  return {
    ...e,
    actionLabel: getActionLabel(action, !isEmpty(actionDetails)),
    totalHours: '0.0',
    m: moment(DateTime.fromISO(dateTime).setZone(config.timeZone).toISO({ includeOffset: false })),
    isBlank: isBlank || false,
    type,
    dateFmt,
    timeFmt: isBlank ? null : timeFmt,
    isRoaming: e.tenantId !== e.billTo,
  };
};

const getTimesheets = (items, { startDate, endDate, type }) => {
  if (!items.length) return [];
  // Moment has shitty timezone support so we convert the moment date range
  // to Luxon date objects set to the correct timezone and then convert those back
  // to moment objects :face_with_rolling_eyes:
  const sDate = DateTime.fromObject(
    {
      year: startDate.year(),
      month: startDate.month() + 1,
      day: startDate.date(),
      hour: 0,
      minute: 0,
      second: 0,
    },
    {
      zone: config.timeZone,
    }
  );
  const eDate = DateTime.fromObject(
    {
      year: endDate.year(),
      month: endDate.month() + 1,
      day: endDate.date(),
      hour: 23,
      minute: 59,
      second: 59,
    },
    {
      zone: config.timeZone,
    }
  );
  const s = moment(sDate.toISO());
  const e = moment(eDate.toISO());
  // console.log(s);
  // console.log(e);
  const mRange = moment.range(s, e);
  const range = Array.from(mRange.by('day'));
  // console.log(range.map((it) => it.format('DD')));
  // only show non-ignored entries
  const filtered = items.filter((item) => item.isIgnored === StringBoolean.FALSE.value);
  const people = groupBy('fullName', filtered);
  // for each person in the results
  const result = Object.keys(people)
    .sort()
    .map((it) => {
      let isFlagged = false;
      const person = people[it];
      const partyId = type === REPORT_TYPE_ATTENDANCE ? person[0].studentId : person[0].adultId;
      const { fullName, tenantId } = person[0];
      const { hasSubsidy } = person[0][type === REPORT_TYPE_ATTENDANCE ? 'student' : 'adult'];
      const days = range.map((r) => {
        let isDayFlagged = false;
        // for each day in the range
        const today = r.format('Y-MM-DD');
        // get the person's entries for today
        const todayEntries = person.sort(sortByTimeStamp).filter((p) => {
          const dtObj = DateTime.fromISO(p.dateTime).setZone(config.timeZone);
          const dtFmt = dtObj.toFormat('yyyy-MM-dd');
          return dtFmt.substr(0, 10) === today;
        });

        const entryCount = todayEntries.length;

        // even entries should be sign in, odd entries should be sign out
        const oddCheckIns = todayEntries
          .filter((_, i) => i % 2 !== 0)
          .filter((el) => el.action === TimesheetAction.CHECKED_IN.value);
        const evenCheckOuts = todayEntries
          .filter((_, i) => i % 2 === 0)
          .filter((el) => el.action === TimesheetAction.CHECKED_OUT.value);

        // add a flag if uneven number of entries
        // or there exists odd-indexed entries whose status is CHECKED_IN
        // or there exists even-indexed entries whose status is CHECKED_OUT
        if (entryCount % 2 !== 0 || oddCheckIns.length || evenCheckOuts.length) {
          isFlagged = true;
          isDayFlagged = true;
        }
        let entries = todayEntries;

        // if there are no entries today,
        // or an uneven number entries today
        // add a blank entry for quick record insertion
        if (!todayEntries.length || entryCount % 2 !== 0) {
          entries = entries.concat({
            id: getInterimId(),
            tenantId,
            billTo: tenantId,
            partyId,
            action: null,
            actionDetails: null,
            date: r.format('Y-MM-DD'),
            dateTime: r.toISOString(),
            time: null,
            timeStamp: 0,
            isBestGuess: false,
            isManualEntry: false,
            manualEntryReason: null,
            isIgnored: StringBoolean.FALSE.value,
            isIgnoredReason: null,
            ...(type === REPORT_TYPE_ATTENDANCE ? { hasSubsidy } : {}),
            type,
            fullName,
            totalHours: '0.0',
            m: r.clone(),
            isBlank: true,
            isRoaming: false,
          });
        }

        entries = entries.map((entry, i, list) => mapEntries(entry, i, list, type));

        // if even number of entries, but entries have no action selected
        // flag it
        if (!isFlagged && !isDayFlagged) {
          const shouldFlag = !!entries.filter((entry) => entry.action === TimesheetAction.NO_ACTION_SELECTED.value)
            .length;
          isDayFlagged = shouldFlag;
          isFlagged = shouldFlag;
        }

        return {
          date: r.format('ddd MM/DD/Y'),
          entries,
          totalHours: entries.reduce((acc, next) => acc + parseFloat(next.totalHours), 0.0),
          isFlagged: isDayFlagged,
        };
      });

      return {
        id: `TS-${partyId}`,
        fullName: it,
        days,
        totalHours: days.reduce((acc, next) => acc + parseFloat(next.totalHours), 0.0),
        isFlagged,
      };
    });
  return result;
};

const getExceptions = (items, { type }) => {
  if (!items.length) return [];
  const filtered = items.filter((item) => item.isIgnored === StringBoolean.TRUE.value);
  const people = groupBy('fullName', filtered);
  const result = Object.keys(people)
    .sort()
    .map((it) => {
      const person = people[it];
      const partyId = type === REPORT_TYPE_ATTENDANCE ? person[0].studentId : person[0].adultId;
      const days = person.map((d) => {
        const { dateTime } = d;
        const dtObj = DateTime.fromISO(dateTime).setZone(config.timeZone);
        const dateFmt = dtObj.toFormat('ccc LL/dd/yyyy');
        const timeFmt = dtObj.toFormat('h:mm a');
        return {
          ...d,
          dateFmt,
          timeFmt,
        };
      });
      return {
        id: `EX-${partyId}`,
        fullName: it,
        days,
        count: days.length,
      };
    });
  return result;
};

export const getAttendanceOutput = (items, params) =>
  getTimesheets(
    items.map((it) => {
      const { firstName, lastName } = it.student;
      return {
        ...it,
        fullName: `${lastName}, ${firstName}`,
      };
    }),
    params
  );

export const getAttendanceExceptionOutput = (items, params) =>
  getExceptions(
    items.map((it) => {
      const { firstName, lastName } = it.student;
      return {
        ...it,
        fullName: `${lastName}, ${firstName}`,
      };
    }),
    params
  );

export const getPayrollOutput = (items, params) =>
  getTimesheets(
    items.map((it) => {
      const { firstName, lastName } = it.adult;
      return {
        ...it,
        fullName: `${lastName}, ${firstName}`,
      };
    }),
    params
  );

export const getPayrollExceptionOutput = (items, params) =>
  getExceptions(
    items.map((it) => {
      const { firstName, lastName } = it.adult;
      return {
        ...it,
        fullName: `${lastName}, ${firstName}`,
      };
    }),
    params
  );
