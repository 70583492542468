import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ActionLinkIcon extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    type: PropTypes.string,
    linkState: PropTypes.shape(),
  };

  static defaultProps = {
    type: 'add',
    linkState: null,
  };

  render() {
    const { type, linkText, linkState, url } = this.props;
    const toProps = {
      pathname: url,
      ...(linkState ? { state: linkState } : {}),
    };
    return (
      <div className={`action--${type}`}>
        <Link to={toProps} className="button--inline-action">
          {linkText}
        </Link>
      </div>
    );
  }
}

export default ActionLinkIcon;
