import parsePhoneNumber from 'libphonenumber-js';

export const formatNumber = (num) => {
  const def = {
    formatted: '',
    uri: '',
  };
  if (!num) return def;
  const parsedNumber = parsePhoneNumber(num, 'US');
  return parsedNumber
    ? {
        formatted: parsedNumber.formatNational(),
        uri: parsedNumber.getURI(),
      }
    : def;
};
