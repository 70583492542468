import { Redirect } from 'react-router-dom';
import { useAppContext } from 'context/AppContext';
import { useAuthContext } from 'context/AuthContext';
import { useTenantContext } from 'context/Tenant/TenantContext';
import { PageFocusview } from 'components/layout';
import { ActionLink } from 'components/links';
import { ROUTE_HOME } from 'constants/index';
import 'assets/css/styles.css';

function SelectTenant() {
  const { isAppLoading } = useAppContext();
  const { tenantId, chooseTenant } = useAuthContext();
  const { getItems, isLoading } = useTenantContext();

  if (isAppLoading || isLoading) {
    return <div className="loading" />;
  }

  const tenantList = getItems.map((it) => (
    <ActionLink
      key={it.id}
      linkText={it.name}
      onClick={() => {
        chooseTenant(it.id, it.orgId);
      }}
    />
  ));

  return tenantId ? (
    <Redirect to={ROUTE_HOME} />
  ) : (
    <PageFocusview
      pageTitle="Select Center"
      pageClassNames={['page--form']}
      wrapperClassNames={['width--s']}
      showNav={false}
    >
      <div className={isLoading ? 'list--actions loading' : 'list--actions'}>{tenantList}</div>
    </PageFocusview>
  );
}

export default SelectTenant;
