/* eslint-disable max-len */
import React from 'react';

export function LauncherGraphic() {
  return (
    <svg x="0" y="0" version="1.1" viewBox="0 0 200 200" xmlSpace="preserve">
      <path
        fill="#00b59c"
        d="M190.6 77.3c12.5 12.5 12.5 32.8 0 45.3l-68 68c-12.5 12.5-32.8 12.5-45.3 0l-68-68c-12.5-12.5-12.5-32.8 0-45.3l68-68c12.5-12.5 32.8-12.5 45.3 0l68 68z"
      />
      <path
        fill="#ffffff"
        d="M124 124H84v-16h10V92H84V76h40c2.2 0 4.1-.8 5.6-2.4 1.6-1.6 2.3-3.5 2.3-5.6 0-2.2-.8-4-2.4-5.6-1.6-1.6-3.5-2.4-5.6-2.4H76c-2.2 0-4 .8-5.6 2.4C68.8 64 68 65.8 68 68v64c0 2.2.8 4 2.4 5.6 1.6 1.6 3.5 2.4 5.6 2.4h48c2.2 0 4.1-.8 5.6-2.4 1.6-1.6 2.3-3.5 2.3-5.6 0-2.2-.8-4-2.4-5.6-1.5-1.6-3.4-2.4-5.5-2.4z"
      />
      <path
        fill="#ffffff"
        d="M125.6 105.6c1.6-1.6 2.4-3.5 2.4-5.6 0-2.2-.8-4.1-2.4-5.6-1.6-1.6-3.5-2.3-5.6-2.3h-10v16h10c2.2-.1 4-.9 5.6-2.5z"
      />
    </svg>
  );
}
