function IconChild() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {/* eslint-disable-next-line max-len */}
      <path d="M21.174 8.205c-.427-4.943-4.082-7.205-9.174-7.205-5.143 0-8.751 2.311-9.174 7.205-2.026.142-2.826 2.079-2.826 4.221 0 1.888.861 4.627 3.176 5.159 1.193 3.546 4.229 5.415 8.824 5.415s7.631-1.869 8.824-5.415c2.315-.532 3.176-3.271 3.176-5.159 0-2.128-.794-4.079-2.826-4.221zm-1.141 7.471c-.449.009-.836.315-.949.749-.787 3.036-3.17 4.575-7.084 4.575s-6.297-1.539-7.083-4.575c-.113-.434-.5-.74-.949-.749-2.135-.041-2.438-4.65-1.336-5.367.272-.177.614-.104.756-.041.671.3 1.427-.207 1.406-.941-.071-2.526.931-4.647 3.23-5.706-.663 2.823 2.205 5.638 6.672 4.598-4.012-1.098-1.056-6.221 2.352-3.954 1.608 1.217 2.214 3.072 2.16 5.063-.021.739.743 1.239 1.406.941.142-.064.483-.137.756.041 1.101.716.798 5.325-1.337 5.366zm-3.533-4.176c0 .828-.56 1.5-1.25 1.5s-1.25-.672-1.25-1.5.56-1.5 1.25-1.5 1.25.672 1.25 1.5zm-7.75 1.5c-.69 0-1.25-.672-1.25-1.5s.56-1.5 1.25-1.5 1.25.672 1.25 1.5-.56 1.5-1.25 1.5zm-.75 3h8s-.844 2.875-4 2.875c-3.25 0-4-2.875-4-2.875z" />
    </svg>
  );
}

export default IconChild;
