import { useMemo } from 'react';
import { useClassroomContext } from 'context/Classroom/ClassroomContext';
import { EMPTY_STRING } from 'constants/index';
import InputSelect from './InputSelect';

export function ClassroomSelect(props) {
  const { isLoading, isError, getItems } = useClassroomContext();

  const options = useMemo(() => {
    const empty = [{ label: EMPTY_STRING, value: EMPTY_STRING }];
    if (isLoading || isError) return empty;
    return empty.concat(
      getItems
        .filter((it) => !it.isAdmin)
        .sort((a, b) => a.maxMonths - b.maxMonths)
        .map((it) => ({
          label: it.nickName,
          value: it.id,
        }))
    );
  }, [getItems, isError, isLoading]);

  return <InputSelect {...props} options={options} />;
}
