import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Form extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    autoComplete: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    autoComplete: true,
    className: '',
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit } = this.props;
    if (this.form.reportValidity()) {
      onSubmit();
    }
  };

  render() {
    const { children, onSubmit, autoComplete, ...props } = this.props;
    const formProps = {
      ...props,
      onSubmit: this.onSubmit,
      ref: (ref) => {
        this.form = ref;
      },
      ...(!autoComplete ? { autoComplete: 'off' } : {}),
    };
    return <form {...formProps}>{children}</form>;
  }
}

export default Form;
