import { createContext, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import {
  createPayrollTimesheet,
  createAttendanceTimesheet,
  updatePayrollTimesheet,
  updateAttendanceTimesheet,
} from 'graphql/customMutations';
import { REPORT_TYPE_ATTENDANCE } from 'constants/index';
import { useAuthContext } from '../AuthContext';
import { useTimesheetContext } from './TimesheetContext';

const initialState = {
  doCreate: null,
  payrollCreate: {
    id: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  attendanceCreate: {
    id: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  doUpdate: null,
  payrollUpdate: {
    id: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  attendanceUpdate: {
    id: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
};

const TimesheetMutateContext = createContext(initialState);

export function TimesheetMutateProvider({ children }) {
  const queryClient = useQueryClient();
  const { orgId } = useAuthContext();
  const { attendanceKey, payrollKey, refreshSearch } = useTimesheetContext();

  const {
    // data: createPayrollResult,
    isSuccess: isPayrollSuccess,
    isLoading: isPayrollLoading,
    isError: isPayrollError,
    error: payrollError,
    mutateAsync: createPayroll,
    reset: createPayrollReset,
  } = useMutation((data) => API.graphql(graphqlOperation(createPayrollTimesheet, { input: { ...data, orgId } })), {
    onSuccess: () => {
      queryClient.invalidateQueries(payrollKey);
      refreshSearch(true);
    },
  });

  const {
    // data: createAttendanceResult,
    isSuccess: isAttendanceSuccess,
    isLoading: isAttendanceLoading,
    isError: isAttendanceError,
    error: attendanceError,
    mutateAsync: createAttendance,
    reset: createAttendanceReset,
  } = useMutation((data) => API.graphql(graphqlOperation(createAttendanceTimesheet, { input: { ...data, orgId } })), {
    onSuccess: () => {
      queryClient.invalidateQueries(attendanceKey);
      refreshSearch(true);
    },
  });

  const doCreate = useCallback(
    async (type, data) => {
      if (type === REPORT_TYPE_ATTENDANCE) {
        createAttendanceReset();
      } else {
        createPayrollReset();
      }
      if (type === REPORT_TYPE_ATTENDANCE) {
        await Promise.all(data.map((d) => createAttendance(d)));
      } else {
        await Promise.all(data.map((d) => createPayroll(d)));
      }
      // console.log(type === REPORT_TYPE_ATTENDANCE ? createAttendanceResult : createPayrollResult);
    },
    [createAttendance, createAttendanceReset, createPayroll, createPayrollReset]
  );

  // const payrollCreateId = useMemo(
  //   () => (isPayrollSuccess ? createPayrollResult.data.createPayrollTimesheet.id : null),
  //   [createPayrollResult, isPayrollSuccess]
  // );

  // const attendanceCreateId = useMemo(
  //   () => (isAttendanceSuccess ? createAttendanceResult.data.createAttendanceTimesheet.id : null),
  //   [createAttendanceResult, isAttendanceSuccess]
  // );

  const {
    // data: updatePayrollResult,
    isSuccess: isPayrollUpdateSuccess,
    isLoading: isPayrollUpdateLoading,
    isError: isPayrollUpdateError,
    error: payrollUpdateError,
    mutateAsync: updatePayroll,
    reset: updatePayrollReset,
  } = useMutation((data) => API.graphql(graphqlOperation(updatePayrollTimesheet, { input: { ...data } })), {
    onSuccess: () => {
      queryClient.invalidateQueries(payrollKey);
      refreshSearch(true);
    },
  });

  const {
    // data: updateAttendanceResult,
    isSuccess: isAttendanceUpdateSuccess,
    isLoading: isAttendanceUpdateLoading,
    isError: isAttendanceUpdateError,
    error: attendanceUpdateError,
    mutateAsync: updateAttendance,
    reset: updateAttendanceReset,
  } = useMutation((data) => API.graphql(graphqlOperation(updateAttendanceTimesheet, { input: { ...data } })), {
    onSuccess: () => {
      queryClient.invalidateQueries(attendanceKey);
      refreshSearch(true);
    },
  });

  const doUpdate = useCallback(
    async (type, data) => {
      if (type === REPORT_TYPE_ATTENDANCE) {
        updateAttendanceReset();
      } else {
        updatePayrollReset();
      }
      await (type === REPORT_TYPE_ATTENDANCE ? updateAttendance(data) : updatePayroll(data));
      // console.log(type === REPORT_TYPE_ATTENDANCE ? updateAttendanceResult : updatePayrollResult);
    },
    [updateAttendance, updateAttendanceReset, updatePayroll, updatePayrollReset]
  );

  // const payrollUpdateId = useMemo(
  //   () => (isPayrollSuccess ? updatePayrollResult.data.updatePayrollTimesheet.id : null),
  //   [updatePayrollResult, isPayrollSuccess]
  // );

  // const attendanceUpdateId = useMemo(
  //   () => (isAttendanceSuccess ? updateAttendanceResult.data.updateAttendanceTimesheet.id : null),
  //   [updateAttendanceResult, isAttendanceSuccess]
  // );

  const value = useMemo(
    () => ({
      doCreate,
      payrollCreate: {
        // id: payrollCreateId,
        isLoading: isPayrollLoading,
        isSuccess: isPayrollSuccess,
        isError: isPayrollError,
        error: payrollError,
      },
      attendanceCreate: {
        // id: attendanceCreateId,
        isLoading: isAttendanceLoading,
        isSuccess: isAttendanceSuccess,
        isError: isAttendanceError,
        attendanceError,
      },
      doUpdate,
      payrollUpdate: {
        // id: payrollUpdateId,
        isLoading: isPayrollUpdateLoading,
        isSuccess: isPayrollUpdateSuccess,
        isError: isPayrollUpdateError,
        error: payrollUpdateError,
      },
      attendanceUpdate: {
        // id: attendanceUpdateId,
        isLoading: isAttendanceUpdateLoading,
        isSuccess: isAttendanceUpdateSuccess,
        isError: isAttendanceUpdateError,
        error: attendanceUpdateError,
      },
    }),
    [
      attendanceError,
      attendanceUpdateError,
      doCreate,
      doUpdate,
      isAttendanceError,
      isAttendanceLoading,
      isAttendanceSuccess,
      isAttendanceUpdateError,
      isAttendanceUpdateLoading,
      isAttendanceUpdateSuccess,
      isPayrollError,
      isPayrollLoading,
      isPayrollSuccess,
      isPayrollUpdateError,
      isPayrollUpdateLoading,
      isPayrollUpdateSuccess,
      payrollError,
      payrollUpdateError,
    ]
  );

  return <TimesheetMutateContext.Provider value={value}>{children}</TimesheetMutateContext.Provider>;
}

TimesheetMutateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTimesheetMutateContext = () => useContext(TimesheetMutateContext);
