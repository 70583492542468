function IconStaff() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      {/* eslint-disable max-len */}
      <path
        d="M16.2,12.5c0,0.8-0.5,1.4-1.2,1.4s-1.2-0.6-1.2-1.4s0.5-1.4,1.2-1.4C15.6,11.1,16.2,11.7,16.2,12.5z M9,11.1
          c-0.6,0-1.2,0.6-1.2,1.4s0.5,1.4,1.2,1.4s1.2-0.6,1.2-1.4S9.6,11.1,9,11.1z M12,18.8c2.1,0,2.8-2.2,2.8-2.2H9.2
           C9.2,16.6,10,18.8,12,18.8z M24,12.6c0,2.6-0.7,5.3-1.8,7.6c-0.5,1.1-1.1,2.1-1.7,2.9L17,21.3c-1.4,1.1-3.1,1.8-5,1.8
           c-1.8,0-3.5-0.7-5-1.8l-3.5,1.8c-0.6-0.8-1.2-1.8-1.7-2.9c-1.1-2.3-1.8-5-1.8-7.6c0-7,4.9-11.6,12-11.6S24,5.6,24,12.6z M18.8,16.5
           c0.1-0.2,0.3-0.3,0.5-0.4c1.9-0.7,2.4-3.3,1.7-4.4c-6.1-0.4-10.1-3.1-11.7-5.8c-2.1,4.7-4.7,6.1-6.3,6.3c-0.4,1.6,0.3,3.4,1.8,3.9
           c0.2,0.1,0.4,0.2,0.5,0.4c1.6,2.6,3.7,4.7,6.8,4.7C15.1,21.2,17.2,19.1,18.8,16.5z"
      />
      {/* eslint-enable max-len */}
    </svg>
  );
}

export default IconStaff;
