import { createContext, useContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from 'react-query';
import { payrollBillingQuery, attendanceBillingQuery } from 'graphql/customQueries';
import { cacheKeys } from 'conf';
import { REPORT_TYPE_ATTENDANCE, REPORT_TYPE_PAYROLL } from 'constants/index';
import conf from 'conf/index';
import { useAuthContext } from '../AuthContext';
import {
  getAttendanceOutput,
  getAttendanceExceptionOutput,
  getPayrollOutput,
  getPayrollExceptionOutput,
} from './TimesheetHelpers';

const getTimeStampFromDate = (momentDate, isStart) => {
  const d = DateTime.fromObject(
    {
      year: momentDate.year(),
      month: momentDate.month() + 1,
      day: momentDate.date(),
      hour: isStart ? 0 : 23,
      minute: isStart ? 0 : 59,
      second: isStart ? 0 : 59,
    },
    {
      zone: conf.timeZone,
    }
  ).toUTC();
  return Math[isStart ? 'floor' : 'ceil'](d.toSeconds());
};

const initialState = {
  payrollItems: [],
  attendanceItems: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
  shouldSearch: false,
  doSearch: () => {},
  searchType: null,
  searchTypeLabel: null,
  attendanceKey: null,
  payrollKey: null,
};

const TimesheetContext = createContext(initialState);

export function TimesheetDataProvider({ children }) {
  const { tenantId, isAuthenticated } = useAuthContext();
  // const queryClient = useQueryClient();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState('');
  const [partyId, setMemberId] = useState('');
  const [shouldSearch, setShouldSearch] = useState(false);
  const [hasSubsidy, setHasSubsidy] = useState(false);
  const [didSearch, setDidSearch] = useState(false);

  const { getPayrollTimesheets, getAttendanceTimesheets } = cacheKeys;

  const attendanceKey = useMemo(
    () => [
      getAttendanceTimesheets,
      {
        tenantId,
        startDate,
        endDate,
        ...(partyId ? { partyId } : {}),
        ...(hasSubsidy ? { hasSubsidy } : {}),
      },
    ],
    [getAttendanceTimesheets, tenantId, startDate, endDate, partyId, hasSubsidy]
  );

  const payrollKey = useMemo(
    () => [getPayrollTimesheets, { tenantId, startDate, endDate, ...(partyId ? { partyId } : {}) }],
    [getPayrollTimesheets, tenantId, startDate, endDate, partyId]
  );

  const {
    isLoading: isAttendanceLoading,
    isError: isAttendanceError,
    isSuccess: isAttendanceSuccess,
    error: attendanceError,
    data: attendanceData,
    isIdle: isAttendanceIdle,
  } = useQuery(
    attendanceKey,
    () =>
      API.graphql(
        graphqlOperation(attendanceBillingQuery, {
          billTo: tenantId,
          timeStamp: {
            between: [getTimeStampFromDate(startDate, true), getTimeStampFromDate(endDate, false)],
          },
          ...(partyId || hasSubsidy
            ? {
                filter: { ...(partyId ? { studentId: { eq: partyId } } : {}), ...(hasSubsidy ? { hasSubsidy } : {}) },
              }
            : {}),
          limit: 10000,
        })
      ),
    {
      enabled: !!type && type === REPORT_TYPE_ATTENDANCE && shouldSearch && !!tenantId && isAuthenticated,
      onSuccess: () => setShouldSearch(false),
    }
  );

  const {
    isLoading: isPayrollLoading,
    isError: isPayrollError,
    isSuccess: isPayrollSuccess,
    error: payrollError,
    data: payrollData,
    isIdle: isPayrollIdle,
  } = useQuery(
    payrollKey,
    () =>
      API.graphql(
        graphqlOperation(payrollBillingQuery, {
          billTo: tenantId,
          timeStamp: {
            between: [getTimeStampFromDate(startDate, true), getTimeStampFromDate(endDate, false)],
          },
          ...(partyId ? { filter: { adultId: { eq: partyId } } } : {}),
          limit: 10000,
        })
      ),
    {
      enabled: !!type && type === REPORT_TYPE_PAYROLL && shouldSearch && !!tenantId && isAuthenticated,
      onSuccess: () => setShouldSearch(false),
    }
  );

  // const onNewPrTS = (newRec) => {
  //   queryClient.setQueryData(payrollKey, (oldData) => [newRec, ...oldData]);
  // };

  // const onNewAttTS = (newRec) => {
  //   queryClient.setQueryData(attendanceKey, (oldData) => [newRec, ...oldData]);
  // };

  const payrollItems = useMemo(() => {
    const items = isPayrollSuccess ? payrollData.data.PayrollBillingQuery.items : [];
    return {
      entries: type === REPORT_TYPE_PAYROLL ? getPayrollOutput(items, { startDate, endDate, partyId, type }) : [],
      exceptions:
        type === REPORT_TYPE_PAYROLL ? getPayrollExceptionOutput(items, { startDate, endDate, partyId, type }) : [],
    };
  }, [isPayrollSuccess, payrollData, type, startDate, endDate, partyId]);

  const attendanceItems = useMemo(() => {
    const items = isAttendanceSuccess ? attendanceData.data.AttendanceBillingQuery.items : [];
    return {
      entries:
        type === REPORT_TYPE_ATTENDANCE
          ? getAttendanceOutput(items, { startDate, endDate, partyId, hasSubsidy, type })
          : [],
      exceptions:
        type === REPORT_TYPE_ATTENDANCE
          ? getAttendanceExceptionOutput(items, { startDate, endDate, partyId, hasSubsidy, type })
          : [],
    };
  }, [isAttendanceSuccess, attendanceData, startDate, endDate, partyId, hasSubsidy, type]);

  const searchTypeLabel = useMemo(() => {
    if (type === REPORT_TYPE_ATTENDANCE) return 'Attendance';
    if (type === REPORT_TYPE_PAYROLL) return 'Payroll';
    return null;
  }, [type]);

  const reset = () => {
    setStartDate(null);
    setEndDate(null);
    setType(null);
    setMemberId(null);
    setShouldSearch(false);
    setHasSubsidy(false);
  };

  const doSearch = useCallback((sD, eD, t, mId, hS) => {
    reset();
    setDidSearch(true);
    setStartDate(sD);
    setEndDate(eD);
    setType(t);
    if (mId) setMemberId(mId);
    if (hS) setHasSubsidy(hS);
    setShouldSearch(true);
  }, []);

  const value = useMemo(
    () => ({
      didSearch,
      payrollItems,
      attendanceItems,
      isLoading: isAttendanceLoading || isPayrollLoading,
      isError: isAttendanceError || isPayrollError,
      isSuccess: isAttendanceSuccess || isPayrollSuccess,
      isIdle: isAttendanceIdle || isPayrollIdle,
      error: attendanceError || payrollError || null,
      doSearch,
      startDate,
      endDate,
      partyId,
      hasSubsidy,
      searchType: type,
      searchTypeLabel,
      attendanceKey,
      payrollKey,
      refreshSearch: setShouldSearch,
    }),
    [
      didSearch,
      attendanceError,
      attendanceItems,
      attendanceKey,
      doSearch,
      endDate,
      hasSubsidy,
      isAttendanceError,
      isAttendanceIdle,
      isAttendanceLoading,
      isAttendanceSuccess,
      isPayrollError,
      isPayrollIdle,
      isPayrollLoading,
      isPayrollSuccess,
      partyId,
      payrollError,
      payrollItems,
      payrollKey,
      searchTypeLabel,
      startDate,
      type,
      setShouldSearch,
    ]
  );

  return <TimesheetContext.Provider value={value}>{children}</TimesheetContext.Provider>;
}

TimesheetDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTimesheetContext = () => useContext(TimesheetContext);
