export const orgList = /* GraphQL */ `
  query ListOrganisations($filter: ModelOrganisationFilterInput, $limit: Int, $nextToken: String) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        _version
      }
      nextToken
      startedAt
    }
  }
`;

export const ratioList = /* GraphQL */ `
  query RatioByState(
    $state: String
    $sortDirection: ModelSortDirection
    $filter: ModelStateRatioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RatioByState(state: $state, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ratio
        minMonths
        maxMonths
        maxSize
        state
        _version
      }
      nextToken
      startedAt
    }
  }
`;

export const tenantList = /* GraphQL */ `
  query ListTenants($filter: ModelTenantFilterInput, $limit: Int, $nextToken: String) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        name
        address
        address2
        city
        zip
        state
        mobile
        phone
        email
        notifyOnTimesheetAdd
        billPayrollToHomeCenter
        billAttendanceToHomeCenter
        timeZone
        isArchived
        _version
        operatingHours {
          items {
            id
            order
            label
            timeStart
            timeEnd
            isSelected
            _version
          }
        }
      }
      nextToken
    }
  }
`;

export const classroomByTenant = /* GraphQL */ `
  query ClassroomByTenant(
    $tenantId: ID
    $isArchived: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ClassroomByTenant(
      tenantId: $tenantId
      isArchived: $isArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        name
        nickName
        ratio
        minMonths
        maxMonths
        maxSize
        isAdmin
        isArchived
        _version
      }
      nextToken
      startedAt
    }
  }
`;

export const studentByTenant = /* GraphQL */ `
  query StudentByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        hasSubsidy
        isArchived
        _version
      }
      nextToken
    }
  }
`;

export const studentByOrg = /* GraphQL */ `
  query StudentByOrg(
    $orgId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentByOrg(orgId: $orgId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        tenantId
        firstName
        lastName
        dateOfBirth
        isDateOfBirthEstimated
        startDate
        isStartDateEstimated
        endDate
        hasSubsidy
        isArchived
        _version
      }
      nextToken
      startedAt
    }
  }
`;

export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent($input: UpdateStudentInput!, $condition: ModelStudentConditionInput) {
    updateStudent(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createStudent = /* GraphQL */ `
  mutation UpdateStudent($input: CreateStudentInput!, $condition: ModelStudentConditionInput) {
    createStudent(input: $input, condition: $condition) {
      id
    }
  }
`;

export const getRelationship = /* GraphQL */ `
  query GetRelationship($id: ID!) {
    getRelationship(id: $id) {
      id
      tenantId
      adultId
      studentId
      rel
      relType
      attendance
      billing
      billingAmount
      billingType
      isPrimary
      relDetails
      isArchived
      _version
      adult {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
      }
    }
  }
`;

export const relByStudent = /* GraphQL */ `
  query RelByStudent(
    $studentId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelByStudent(
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adultId
        studentId
        rel
        relType
        attendance
        billing
        billingAmount
        billingType
        isPrimary
        relDetails
        isArchived
        adult {
          id
          pin
          firstName
          lastName
          isStaffMember
          isArchived
        }
        _version
        __typename
      }
      nextToken
    }
  }
`;

export const siblings = /* GraphQL */ `
  query SiblingQuery(
    $tenantId: ID
    $ownerIdSiblingIdIsArchived: ModelSiblingSiblingQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSiblingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SiblingQuery(
      tenantId: $tenantId
      ownerIdSiblingIdIsArchived: $ownerIdSiblingIdIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        _version
        __typename
        owner {
          id
          firstName
          lastName
          isArchived
        }
        sibling {
          id
          firstName
          lastName
          isArchived
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const adultByTenant = /* GraphQL */ `
  query AdultByTenant(
    $tenantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAdultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AdultByTenant(
      tenantId: $tenantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
      }
      nextToken
    }
  }
`;

export const adultByOrg = /* GraphQL */ `
  query AdultByOrg(
    $orgId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAdultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AdultByOrg(orgId: $orgId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        orgId
        userId
        startDate
        isStartDateEstimated
        endDate
        isEndDateEstimated
        isLocked
        lockReason
        pin
        firstName
        lastName
        email
        mobile
        dateOfBirth
        homePhone
        workPhone
        address
        address2
        city
        state
        zip
        didSendInvite
        isStaffMember
        isArchived
        _version
      }
      nextToken
      startedAt
    }
  }
`;

export const relByAdult = /* GraphQL */ `
  query RelByAdult(
    $adultId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRelationshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelByAdult(
      adultId: $adultId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adultId
        studentId
        rel
        relType
        attendance
        billing
        billingAmount
        billingType
        isPrimary
        relDetails
        isArchived
        student {
          id
          firstName
          lastName
          dateOfBirth
        }
        _version
        __typename
      }
      nextToken
    }
  }
`;

export const relatives = /* GraphQL */ `
  query RelativeQuery(
    $tenantId: ID
    $ownerIdRelativeIdIsArchived: ModelRelativeRelativeQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRelativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    RelativeQuery(
      tenantId: $tenantId
      ownerIdRelativeIdIsArchived: $ownerIdRelativeIdIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        relativeId
        isArchived
        _version
        __typename
        owner {
          id
          pin
          firstName
          lastName
          email
          mobile
          isStaffMember
          isArchived
        }
        relative {
          id
          pin
          firstName
          lastName
          email
          mobile
          isStaffMember
          isArchived
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const payrollByTenantByDate = /* GraphQL */ `
  query PayrollByTenantByDate(
    $tenantId: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayrollTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PayrollByTenantByDate(
      tenantId: $tenantId
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        date
        dateTime
        time
        timeStamp
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        adult {
          firstName
          lastName
        }
        _version
      }
      nextToken
    }
  }
`;

export const attendanceByTenantByDate = /* GraphQL */ `
  query AttendanceByTenantByDate(
    $tenantId: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceByTenantByDate(
      tenantId: $tenantId
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        action
        actionDetails
        date
        dateTime
        time
        timeStamp
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        student {
          firstName
          lastName
          hasSubsidy
        }
        _version
      }
      nextToken
    }
  }
`;

export const conByStudent = /* GraphQL */ `
  query ConByStudent(
    $tenantId: ID
    $studentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ConByStudent(
      tenantId: $tenantId
      studentId: $studentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        contactType
        adultId
        adHocContactId
        relationship
        isArchived
        _version
        adult {
          id
          firstName
          lastName
          isStaffMember
          mobile
          homePhone
          workPhone
        }
        adHocContact {
          id
          firstName
          lastName
          mobile
          homePhone
          workPhone
          isArchived
          _version
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      tenantId
      studentId
      contactType
      adultId
      adHocContactId
      relationship
      isArchived
      _version
      adHocContact {
        id
        tenantId
        contactId
        firstName
        lastName
        mobile
        homePhone
        workPhone
        isArchived
        _version
      }
    }
  }
`;

export const studentScheduleQ = /* GraphQL */ `
  query StudentScheduleQ(
    $tenantId: ID
    $studentIdIsArchived: ModelStudentScheduleStudentScheduleQCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentScheduleQ(
      tenantId: $tenantId
      studentIdIsArchived: $studentIdIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        date
        isArchived
        isTemporary
        _version
        schedule {
          items {
            id
            label
            order
            timeEnd
            timeStart
            isSelected
            classroomId
            _version
            classroom {
              nickName
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const studentScheduleQuery = /* GraphQL */ `
  query StudentScheduleQuery(
    $tenantId: ID
    $studentIdDateIsArchived: ModelStudentScheduleStudentScheduleQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentScheduleQuery(
      tenantId: $tenantId
      studentIdDateIsArchived: $studentIdDateIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        date
        isArchived
        isTemporary
        student {
          id
        }
        schedule {
          items {
            id
            label
            order
            timeEnd
            timeStart
            isSelected
            classroomId
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const staffScheduleQ = /* GraphQL */ `
  query StaffScheduleQ(
    $tenantId: ID
    $adultIdIsArchived: ModelStaffScheduleStaffScheduleQCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffScheduleQ(
      tenantId: $tenantId
      adultIdIsArchived: $adultIdIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        date
        isArchived
        _version
        schedule {
          items {
            id
            label
            order
            timeEnd
            timeStart
            isSelected
            classroomId
            _version
            classroom {
              nickName
            }
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const staffScheduleQuery = /* GraphQL */ `
  query StaffScheduleQuery(
    $tenantId: ID
    $adultIdDateIsArchived: ModelStaffScheduleStaffScheduleQueryCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffScheduleQuery(
      tenantId: $tenantId
      adultIdDateIsArchived: $adultIdDateIsArchived
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        date
        isArchived
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        adult {
          id
        }
        schedule {
          items {
            id
            label
            order
            timeEnd
            timeStart
            isSelected
            classroomId
            _version
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const studentScheduleByDate = /* GraphQL */ `
  query StudentScheduleByDate(
    $tenantId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentScheduleByDate(
      tenantId: $tenantId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        studentId
        date
        isArchived
        isTemporary
        _version
        student {
          id
          firstName
          lastName
          dateOfBirth
        }
        schedule {
          items {
            id
            label
            order
            timeEnd
            timeStart
            isSelected
            classroomId
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const staffScheduleByDate = /* GraphQL */ `
  query StaffScheduleByDate(
    $tenantId: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffScheduleByDate(
      tenantId: $tenantId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        adultId
        date
        isArchived
        _version
        adult {
          id
          firstName
          lastName
        }
        schedule {
          items {
            id
            label
            order
            timeEnd
            timeStart
            isSelected
            classroomId
            _version
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const staffDayByClassroom = /* GraphQL */ `
  query StaffDayByClassroom(
    $tenantId: ID
    $classroomId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStaffDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StaffDayByClassroom(
      tenantId: $tenantId
      classroomId: $classroomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        classroomId
        scheduleId
        schedule {
          isArchived
          adult {
            id
            firstName
            lastName
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const studentDayByClassroom = /* GraphQL */ `
  query StudentDayByClassroom(
    $tenantId: ID
    $classroomId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudentDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    StudentDayByClassroom(
      tenantId: $tenantId
      classroomId: $classroomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        classroomId
        scheduleId
        schedule {
          isArchived
          student {
            id
            firstName
            lastName
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const attendanceBillingQuery = /* GraphQL */ `
  query AttendanceBillingQuery(
    $billTo: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceBillingQuery(
      billTo: $billTo
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        _version
        student {
          firstName
          lastName
          hasSubsidy
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const attendanceStudentQuery = /* GraphQL */ `
  query AttendanceStudentQuery(
    $studentId: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceStudentQuery(
      studentId: $studentId
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        studentId
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        hasSubsidy
        _version
        student {
          firstName
          lastName
          hasSubsidy
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const payrollBillingQuery = /* GraphQL */ `
  query PayrollBillingQuery(
    $billTo: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayrollTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PayrollBillingQuery(
      billTo: $billTo
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        _version
        adult {
          firstName
          lastName
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const payrollStaffQuery = /* GraphQL */ `
  query PayrollStaffQuery(
    $adultId: ID
    $timeStamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPayrollTimesheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PayrollStaffQuery(
      adultId: $adultId
      timeStamp: $timeStamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        tenantId
        billTo
        adultId
        action
        actionDetails
        dateTime
        timeStamp
        date
        time
        isBestGuess
        isManualEntry
        manualEntryReason
        isIgnored
        isIgnoredReason
        _version
        adult {
          firstName
          lastName
        }
        tags {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
