import { createContext, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useMutation, useQueryClient } from 'react-query';
import { createContact, updateContact, createAdHocContact, updateAdHocContact } from 'graphql/customMutations';
import { cacheKeys } from 'conf';
import { StringBoolean, ContactType } from 'constants/index';
import { getInterimId } from 'utils/index';
import { useAuthContext } from '../AuthContext';

const initialState = {
  create: {},
  update: {},
  archive: {},
};

const ContactMutateContext = createContext(initialState);

export function ContactMutateProvider({ children, ownerId }) {
  const { tenantId } = useAuthContext();
  const queryClient = useQueryClient();

  const {
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
    isError: isCreateError,
    error: createError,
    data: createResult,
    mutateAsync: create,
  } = useMutation((data) => API.graphql(graphqlOperation(createContact, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getEmergencyContacts, ownerId, tenantId]),
  });

  const {
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
    error: updateError,
    // data: updateResult,
    mutateAsync: update,
  } = useMutation((data) => API.graphql(graphqlOperation(updateContact, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getEmergencyContacts, ownerId, tenantId]),
  });

  const {
    isSuccess: isAdHocCreateSuccess,
    isLoading: isAdHocCreateLoading,
    isError: isAdHocCreateError,
    error: adHocCreateError,
    // data: adHocCreateResult,
    mutateAsync: createAdHoc,
  } = useMutation((data) => API.graphql(graphqlOperation(createAdHocContact, { input: { ...data } })), {
    onSuccess: () => queryClient.invalidateQueries([cacheKeys.getEmergencyContacts, ownerId, tenantId]),
  });

  const {
    isSuccess: isAdHocUpdateSuccess,
    isLoading: isAdHocUpdateLoading,
    isError: isAdHocUpdateError,
    error: adHocUpdateError,
    // data: updateResult,
    mutateAsync: updateAdHoc,
  } = useMutation((data) => API.graphql(graphqlOperation(updateAdHocContact, { input: { ...data } })));

  const newId = useMemo(() => {
    if (isCreateSuccess) return createResult.data?.createContact.id;
    return null;
  }, [createResult, isCreateSuccess]);

  const doCreate = useCallback(
    async (data) => {
      const { contactType, relationship, firstName, lastName, mobile, homePhone, workPhone, adultId } = data;
      const contactId = getInterimId();
      const adHocId = getInterimId();
      const isAdHoc = contactType === ContactType.AdHoc;
      if (isAdHoc) {
        await createAdHoc({
          id: adHocId,
          tenantId,
          contactId,
          firstName,
          lastName,
          mobile,
          homePhone: homePhone || null,
          workPhone: workPhone || null,
          isArchived: StringBoolean.FALSE.value,
        });
      }
      await create({
        id: contactId,
        tenantId,
        studentId: ownerId,
        contactType,
        adHocContactId: isAdHoc ? adHocId : null,
        adultId: !isAdHoc ? adultId : null,
        relationship,
        isArchived: StringBoolean.FALSE.value,
      });
    },
    [create, createAdHoc, ownerId, tenantId]
  );

  const doUpdate = useCallback(
    async (data) => {
      const { id, adHocContactId, adHocContact, contactType, relationship, _version } = data;
      const isAdHoc = contactType === ContactType.AdHoc;
      if (isAdHoc) {
        await updateAdHoc({
          id: adHocContactId,
          ...adHocContact,
        });
      }
      await update({
        id,
        relationship,
        _version,
      });
    },
    [update, updateAdHoc]
  );

  const doArchive = useCallback(
    async (data) => {
      const { id, contactType, adult, adHocContact, ...rest } = data;
      const isAdHoc = contactType === ContactType.AdHoc;
      if (isAdHoc) {
        await updateAdHoc({
          ...adHocContact,
          contactId: id,
          isArchived: StringBoolean.TRUE.value,
        });
      }
      await update({
        ...rest,
        id,
        contactType,
        isArchived: StringBoolean.TRUE.value,
      });
    },
    [update, updateAdHoc]
  );

  const value = useMemo(
    () => ({
      doCreate,
      doArchive,
      doUpdate,
      create: {
        id: newId,
        isSuccess: isCreateSuccess || isAdHocCreateSuccess,
        isLoading: isCreateLoading || isAdHocCreateLoading,
        isError: isCreateError || isAdHocCreateError,
        error: createError || adHocCreateError,
      },
      update: {
        isSuccess: isAdHocUpdateSuccess,
        isLoading: isAdHocUpdateLoading,
        isError: isAdHocUpdateError,
        error: adHocUpdateError,
      },
      archive: {
        isSuccess: isUpdateSuccess || isAdHocUpdateSuccess,
        isLoading: isUpdateLoading || isAdHocUpdateLoading,
        isError: isUpdateError || isAdHocUpdateError,
        error: updateError || adHocUpdateError,
      },
    }),
    [
      adHocCreateError,
      adHocUpdateError,
      createError,
      doArchive,
      doCreate,
      doUpdate,
      isAdHocCreateError,
      isAdHocCreateLoading,
      isAdHocCreateSuccess,
      isAdHocUpdateError,
      isAdHocUpdateLoading,
      isAdHocUpdateSuccess,
      isCreateError,
      isCreateLoading,
      isCreateSuccess,
      isUpdateError,
      isUpdateLoading,
      isUpdateSuccess,
      newId,
      updateError,
    ]
  );

  return <ContactMutateContext.Provider value={value}>{children}</ContactMutateContext.Provider>;
}

ContactMutateProvider.propTypes = {
  children: PropTypes.node.isRequired,
  ownerId: PropTypes.string.isRequired,
};

export const useContactMutateContext = () => useContext(ContactMutateContext);
