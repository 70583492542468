import { useMemo } from 'react';
import { useTenantContext } from 'context/Tenant/TenantContext';
import { useToggleMenuContext } from 'context/ToggleMenuContext';
import { useAuthContext } from 'context/AuthContext';
import DropMenu from 'components/dropmenu/DropMenu';
import { Button } from 'components/buttons';

const signOut = async (logOutFn, toggleMenu) => {
  toggleMenu(false);
  await logOutFn();
};

const selectTenant = (tenantId, orgId, setTenant, toggleMenu) => {
  toggleMenu(false);
  setTenant(tenantId, orgId);
};

const getTenantList = (
  isSystemAdmin,
  orgId,
  tenantList,
  selectedTenant,
  setTenant,
  userTenants,
  toggleMenu,
  logOutFn
) => {
  const logOutItem = [
    <li key="logout">
      <Button className="btn--logout" onClick={() => signOut(logOutFn, toggleMenu)}>
        Log Out
      </Button>
    </li>,
  ];
  if (!userTenants.length && !isSystemAdmin) return logOutItem;
  const filtered = isSystemAdmin ? tenantList : tenantList.filter((it) => it.orgId === orgId);
  return filtered
    .map((it) => {
      const isActive = it.id === selectedTenant;
      return (
        <li key={it.id}>
          <Button
            className={`btn--center-select${isActive ? ' active' : ''}`}
            onClick={isActive ? null : () => selectTenant(it.id, it.orgId, setTenant, toggleMenu)}
          >
            {it.name}
          </Button>
        </li>
      );
    })
    .concat(logOutItem);
};

function NavUser() {
  const { orgId, tenantId, changeTenant, userData, logOut } = useAuthContext();
  const { getItems: tenants, isLoading } = useTenantContext();
  const { setIsShowing } = useToggleMenuContext();
  const currentTenant = !tenants.length ? tenantId : tenants.find((it) => it.id === tenantId).name;
  const { fullName, tenants: userTenants, isSystemAdmin } = userData;
  const btnContent = (
    <>
      <span className="name--user">{fullName || userData?.username?.toUpperCase()}</span>
      <span className="name--center">{currentTenant}</span>
    </>
  );
  const menuItems = useMemo(
    () =>
      isLoading
        ? []
        : getTenantList(isSystemAdmin, orgId, tenants, tenantId, changeTenant, userTenants || [], setIsShowing, logOut),
    [changeTenant, isLoading, isSystemAdmin, logOut, orgId, setIsShowing, tenantId, tenants, userTenants]
  );
  return (
    <div className="nav--user">
      <DropMenu
        btnClassName="nav--user-info"
        btnContent={btnContent}
        menuChildren={<ul>{menuItems}</ul>}
        menuClassName="nav--user--secondary"
      />
    </div>
  );
}

export default NavUser;
