import { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from 'react-query';
import { relByAdult, relByStudent, siblings, relatives } from 'graphql/customQueries';
import { cacheKeys } from 'conf';
import { TYPE_ADULT, TYPE_STUDENT, StringBoolean } from 'constants/index';
import { useAuthContext } from '../AuthContext';

const initialState = {
  isLoading: false,
  error: null,
  adultItems: [],
  studentItems: [],
};

const RelationshipContext = createContext(initialState);

export function RelationshipDataProvider({ children, ownerId, ownerType }) {
  const { tenantId, isAuthenticated } = useAuthContext();

  const {
    isLoading: isAdultLoading,
    error: adultError,
    data: adultItems,
  } = useQuery(
    [cacheKeys.getRelationships, ownerId, tenantId],
    () =>
      API.graphql(
        graphqlOperation(relByAdult, {
          adultId: ownerId,
          filter: { tenantId: { eq: tenantId }, isArchived: { eq: StringBoolean.FALSE.value } },
        })
      ),
    {
      enabled: isAuthenticated && !!tenantId && ownerType !== TYPE_STUDENT && !!ownerId,
    }
  );

  const {
    isLoading: isRelativesLoading,
    error: relativesError,
    data: relativesItems,
  } = useQuery(
    [cacheKeys.getRelatives, ownerId, tenantId],
    () =>
      API.graphql(
        graphqlOperation(relatives, {
          tenantId,
          filter: {
            isArchived: { eq: StringBoolean.FALSE.value },
            or: [{ ownerId: { eq: ownerId } }, { relativeId: { eq: ownerId } }],
          },
        })
      ),
    {
      enabled: isAuthenticated && !!tenantId && ownerType !== TYPE_STUDENT && !!ownerId,
    }
  );

  const {
    isLoading: isStudentLoading,
    error: studentError,
    data: studentItems,
  } = useQuery(
    [cacheKeys.getRelationships, ownerId, tenantId],
    () =>
      API.graphql(
        graphqlOperation(relByStudent, {
          studentId: ownerId,
          filter: { tenantId: { eq: tenantId }, isArchived: { eq: StringBoolean.FALSE.value } },
        })
      ),
    {
      enabled: isAuthenticated && !!tenantId && ownerType === TYPE_STUDENT && !!ownerId,
    }
  );

  const {
    isLoading: isSiblingsLoading,
    error: siblingsError,
    data: siblingsItems,
  } = useQuery(
    [cacheKeys.getRelationships, ownerId, tenantId],
    () =>
      API.graphql(
        graphqlOperation(siblings, {
          tenantId,
          filter: {
            isArchived: { eq: StringBoolean.FALSE.value },
            or: [{ ownerId: { eq: ownerId } }, { siblingId: { eq: ownerId } }],
          },
        })
      ),
    {
      enabled: isAuthenticated && !!tenantId && ownerType === TYPE_STUDENT && !!ownerId,
    }
  );

  const value = useMemo(
    () => ({
      isLoading: isAdultLoading || isStudentLoading || isRelativesLoading || isSiblingsLoading,
      error: adultError || studentError || relativesError || siblingsError,
      adultItems,
      studentItems,
      relativesItems,
      siblingsItems,
    }),
    [
      adultError,
      adultItems,
      isAdultLoading,
      isRelativesLoading,
      isSiblingsLoading,
      isStudentLoading,
      relativesError,
      relativesItems,
      siblingsError,
      siblingsItems,
      studentError,
      studentItems,
    ]
  );

  return <RelationshipContext.Provider value={value}>{children}</RelationshipContext.Provider>;
}

RelationshipDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  ownerId: PropTypes.string.isRequired,
  ownerType: PropTypes.oneOf([TYPE_STUDENT, TYPE_ADULT]).isRequired,
};

export const useRelationshipContext = () => useContext(RelationshipContext);
