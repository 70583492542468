import { createContext, useContext, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from 'react-query';
import { studentByOrg } from 'graphql/customQueries';
import { cacheKeys } from 'conf';
import { boolToStringBoolean } from 'utils';
import { useAuthContext } from '../AuthContext';

const initialState = {
  isLoading: false,
  error: null,
  getStudent: () => {},
  getStudents: [],
  isActiveFilter: false,
  setIsActiveFilter: () => {},
};

const StudentListContext = createContext(initialState);

export function StudentListDataProvider({ children }) {
  const { orgId, tenantId, isAuthenticated } = useAuthContext();

  const [isActiveFilter, setIsActiveFilter] = useState(false);

  const {
    isLoading,
    isError,
    isSuccess,
    error: queryError,
    data: response,
  } = useQuery(
    [cacheKeys.getStudents, orgId],
    () =>
      API.graphql(
        graphqlOperation(studentByOrg, {
          orgId,
          limit: 10000,
          // filter: { isArchived: { eq: boolToStringBoolean(isActiveFilter) } },
        })
      ),
    {
      enabled: isAuthenticated && !!orgId && !!tenantId,
    }
  );

  const getStudent = useCallback(
    (id) => {
      const rec = isSuccess ? response.data.StudentByOrg.items.find((it) => it.id === id) : {};
      if (isSuccess) {
        return { ...rec, fullName: `${rec.lastName}, ${rec.firstName}` };
      }
      return rec;
    },
    [isSuccess, response]
  );

  const getStudents = useMemo(
    () =>
      isSuccess
        ? response.data.StudentByOrg.items
            .filter((it) => it.tenantId === tenantId)
            .filter((it) => it.isArchived === boolToStringBoolean(isActiveFilter))
            .map((it) => ({ ...it, fullName: `${it.lastName}, ${it.firstName}` }))
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
        : [],
    [isActiveFilter, isSuccess, response, tenantId]
  );

  const getOrgStudents = useMemo(
    () =>
      isSuccess
        ? response.data.StudentByOrg.items
            .map((it) => ({ ...it, fullName: `${it.lastName}, ${it.firstName}` }))
            .sort((a, b) => a.lastName.localeCompare(b.lastName))
        : [],
    [isSuccess, response]
  );

  const value = useMemo(
    () => ({
      isLoading,
      error: isError ? queryError : null,
      getStudent,
      getStudents,
      getOrgStudents,
      isActiveFilter,
      setIsActiveFilter,
    }),
    [getStudent, getStudents, getOrgStudents, isActiveFilter, isError, isLoading, queryError]
  );

  return <StudentListContext.Provider value={value}>{children}</StudentListContext.Provider>;
}

StudentListDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useStudentListContext = () => useContext(StudentListContext);
