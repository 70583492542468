import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TableRow extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    className: 'table-row',
  };

  render() {
    const { className, children } = this.props;
    return <ul className={className}>{!!children && children}</ul>;
  }
}

export default TableRow;
